/* eslint-disable no-duplicate-imports */
import React, { FC } from 'react';
import { Box, Stack, Divider, Typography } from '@mui/material';
import { IArticleData } from '../LibraryTab';
import { LibraryLeftBarLoader } from './LibraryLoader';
import { FilterIcon } from '../../../../../utils/Icons';
import MySkeleton from '../../../../../components/Skeleton';
import { formatNumberWithCommas } from '../../../components/common';

interface articleId {
  articleId: string;
}
interface Props {
  onClick: (data: articleId) => void;
  allArticleData: IArticleData[];
  selectedArticleId: string;
  handleClickOpenFilter: any;
  allArticleLoading: boolean;
  activeItemRef: React.MutableRefObject<any>;
}

const LibrarySideBar: FC<Props> = ({
  onClick,
  handleClickOpenFilter,
  allArticleData,
  selectedArticleId,
  activeItemRef,
  allArticleLoading
}: Props) => {

  const formattedLength = formatNumberWithCommas(allArticleData.length);

  return (
    <Box height={'100%'}>
      <Box px={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="h5" py={2}>
          {allArticleLoading ? (<MySkeleton variant='text' height={20} width={70}/>) : 'Library'}
        </Typography>
        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
          <Typography>
            {allArticleLoading ? (<MySkeleton variant='text' height={20} width={55}/>) : `All (${formattedLength})`}
          </Typography>
          <FilterIcon size={34} onClick={handleClickOpenFilter} />
        </Box>
      </Box>
      <Box
        height={'calc(100% - 74px)'}
        sx={{ overflowY: 'auto' }}
       >
        { allArticleLoading ? (
          <LibraryLeftBarLoader />
        ) : 
        allArticleData.length === 0 ? (
          <Stack direction="row" spacing={2} p={2}>
            <Typography textAlign="center" p={2}>
              No Article Found
            </Typography>
          </Stack>
        ) : allArticleData ? (allArticleData.map((ele) => (
          <Box key={ele.articleId} ref={ele.articleId === selectedArticleId ? activeItemRef : null}>
            <Stack
              direction="row"
              spacing={2}
              p={2}
              onClick={() => onClick(ele)}
              sx={{
                cursor: 'pointer',
                background: ele.articleId === selectedArticleId ? '#0071A9' : '' 
              }}
            >
              <Box>
                {allArticleLoading ? (
                  <MySkeleton variant="rectangular" height={79} width={118} />
                ) : (
                  <img
                    src={ele?.coverImageUrl}
                    alt="resumeimg"
                    width="118"
                    height="79"
                    style={{
                      objectFit: 'cover',
                      borderRadius: '8px',
                      minHeight:'79px',
                      minWidth:"118px"
                    }}
                  />
                )}
              </Box>
              <Box width={'calc(100% - 138px)'}>
                <Typography
                  variant="h5"
                  className="lineClamp-2"
                  sx={{
                    color: ele.articleId === selectedArticleId ? '#FFFFFF' : '', // Conditional color,
                    ...(ele?.articleName && !ele?.articleName.includes(' ') ? { wordBreak: 'break-all' } : {}), // Conditional word break
                  }}
                >
                  {allArticleLoading ? <MySkeleton height={24} width="80%" /> : ele?.articleName}
                </Typography>
                {ele?.createdAt && (
                  <Typography sx={{ color: ele.articleId === selectedArticleId ? ' #FFFFFF' : '' }}>
                    {allArticleLoading ? <MySkeleton height={16} width="40%" /> : new Date(ele.createdAt * 1000).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </Typography>
                )}
              </Box>
            </Stack>
            <Divider />
          </Box>
        ))
        ) : ('')}
      </Box>
    </Box>
  );
};

export default LibrarySideBar;
