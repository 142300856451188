import React, { useRef, useEffect } from "react";
import { IconButton } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import Skeleton from "@mui/material/Skeleton";
import { Swiper as SwiperType } from "swiper";

interface SwiperNavigationProps {
    loading: boolean;
    minLoadingPassed?: boolean;
    swiper: SwiperType | null;
}

const SwiperNavigation: React.FC<SwiperNavigationProps> = ({ loading, minLoadingPassed = false, swiper }) => {
    const swiperRef = useRef<SwiperType | null>(null);

    useEffect(() => {
        if (swiper) {
            swiperRef.current = swiper;
        }
    }, [swiper]);

    return (
        <>
            {loading && !minLoadingPassed ? (
                <>
                    <Skeleton variant="circular" className="custom-swiper-button-prev" />
                    <Skeleton variant="circular" className="custom-swiper-button-next" />
                </>
            ) : (
                <>
                    <IconButton
                        className="custom-swiper-button-prev"
                        onClick={() => swiperRef.current?.slidePrev()}
                    >
                        <ArrowBackIosNewOutlinedIcon />
                    </IconButton>
                    <IconButton
                        className="custom-swiper-button-next"
                        onClick={() => swiperRef.current?.slideNext()}
                    >
                        <ArrowForwardIosOutlinedIcon />
                    </IconButton>
                </>
            )}
        </>
    );
};

export default SwiperNavigation;
