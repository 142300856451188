import React, {
  useState,
  useEffect
} from 'react';
import {
  Box,
  Grid,
  Dialog,
  Select,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  DialogContent,
  DialogActions
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { API } from '../../../../api';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import ObjectiveMentors from './ObjectiveMentors';
import RecruitNewMentor from './RecruitNewMentor';
import { navigateToChat } from '../NavigateToChat';
import CallBookingModal from '../CallBookingModal';
import { appColors } from '../../../../utils/theme';
import { useForm, Controller } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../../queries/queryKeys';
import { getUserDetails } from '../../../../utils/orgName';
import useLearningObjs from '../../../../queries/useLearningObjs';
import { lockIcon, getStatusColor } from '../../components/common';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';

interface ObjectiveModalProps {
  open: boolean;
  title?: string;
  onClose: () => void;
  onSubmit?: (data: any) => Promise<void>;
  description?: string;
  status: string;
  objId: string;
  notes: { createdAt: number; note: string }[];
  mentorsList: any;
  allObjectives?: any;
  onSave?: (data: any) => void;
  myMenteesList?: any;
  isNewObjective?: boolean;
}

const statusOptions = [
  { value: 'to-do', label: 'To Do' },
  { value: 'inprogress', label: 'In Progress' },
  { value: 'completed', label: 'Completed' }
];

const ObjectiveModal: React.FC<ObjectiveModalProps> = ({
  open,
  onClose,
  onSubmit,
  title = '',
  description = '',
  status,
  objId,
  notes = [],
  mentorsList = [],
  allObjectives = [],
  onSave,
  myMenteesList,
  isNewObjective = false
}) => {
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const [submitLoader, setSubmitLoader] = useState(false);

  const [recruitModalOpen, setRecruitModalOpen] = useState(false);
  const isMentor = selectedGroupId?.role === 'mentor';
  const isMentee = selectedGroupId?.role === 'mentee';

  const handleRecruitModalOpen = () => setRecruitModalOpen(true);
  const handleRecruitModalClose = () => setRecruitModalOpen(false);
  const { control } = useForm({
    defaultValues: {
      objectiveTitle: '',
      objectiveStatus: 'todo', // Default value maps to 'To Do'
      objectiveDescription: ''
    }
  });

  const [objDescription, setObjDescription] = useState(description);
  const [localMentorsList, setLocalMentorsList] = useState(mentorsList);
  const [objTitle, setObjTitle] = useState(title);
  const [objStatus, setObjStatus] = useState(status);
  const [isDescriptionVisible, setDescriptionVisible] = useState(false);
  const [note, setNote] = useState(''); // To manage the note text
  const [selectedMember, setSelectedMember] = useState<any>(null);
  const [displayName, setDisplayName] = useState('');
  const [participantUser, setParticipantUser] = useState<any>([]);
  const [objectiveTitleOptions, setObjectiveTitleOptions] = useState<{ value: string; label: string }[]>([]);

  const navigateMember = (member: any) => {
    setSelectedMember(member);
  };

  const [bookedCallDetails, setBookedCallDetails] = useState<any>('');
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const handleCalendarOpen = (each: any) => {
    setDisplayName(each?.name);
    setParticipantUser([each]);
    setBookedCallDetails('');
    setOpenCalendarModal(true);
  };

  const handleCalendarClose = () => {
    setDisplayName('');
    setParticipantUser([]);
    setBookedCallDetails('');
    setOpenCalendarModal(false);
  };

  const communityMembers = useAppSelector((state) => state.acsCommunityUsers.data);
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  const navigate = useNavigate();
  const { orgId } = getUserDetails();
  const grpId = selectedGroupId?.id;
  const queryClient = useQueryClient();
  useEffect(() => {
    setObjDescription(description);
    setObjTitle(title);
    setObjStatus(status);
    setNote('');
    setLocalMentorsList(mentorsList);
  }, [open, description, title, status, notes, mentorsList]);

  const { data: objectives } = useLearningObjs({ orgId, grpId });
  const learningObjectives = objectives?.data?.data;

  const findCurrentObjectiveIndex = () => {
    return allObjectives.findIndex((obj: any) => obj.id === objId);
  };

  const udpdateObjQuery = async () => {
    queryClient.setQueryData([queryKeys.GET_LEARNING_OBJS, grpId], (oldData: any) => {
      const newData = oldData.data.data.map((objective: any) => {
        if (objective.id === objId) {
          const newNotes =
            note?.trim() !== '' ? [...objective.notes, { note, createdAt: Math.floor(Date.now() / 1000) }] : notes;
          return {
            ...objective,
            objective: objTitle,
            description: objDescription,
            status: objStatus,
            notes: newNotes,
            mentors: localMentorsList
          };
        }
        return objective;
      });
      return {
        ...oldData,
        data: {
          data: newData
        }
      };
    });
  };

  const updateQueryWithNewMeeting = (meetingDetails: any) => {
    queryClient.setQueryData([queryKeys.GET_LEARNING_OBJS, grpId], (oldData: any) => {
      if (!oldData) return oldData;
      const newData = oldData.data.data.map((objective: any) => {
        if (objective.id === objId) {
          const mentorId = participantUser[0]?.id;

          const updatedMentors = objective.mentors.map((mentor: any) => {
            if (mentor.id === mentorId) {
              return {
                ...mentor,
                meetings: [...(mentor.meetings || []), meetingDetails]
              };
            }
            return mentor;
          });

          return {
            ...objective,
            mentors: updatedMentors
          };
        }
        return objective;
      });

      return {
        ...oldData,
        data: {
          data: newData
        }
      };
    });
  };

  const onMeetingScheduled = (meetingDetails: any) => {
    updateQueryWithNewMeeting(meetingDetails);
    setLocalMentorsList((current: any) => {
      return current.map((mentor: any) => {
        if (mentor.id === participantUser[0]?.id) {
          return {
            ...mentor,
            meetings: [...(mentor.meetings || []), meetingDetails]
          };
        }
        return mentor;
      });
    });
  };

  // Handler to toggle the visibility of the description box
  const handleAddNewNoteClick = () => {
    setDescriptionVisible(true);
  };

  // Handler to update the note
  const handleNoteChange = (event: any) => {
    setNote(event.target.value);
  };

  // Handler to close the description box and display the note
  const handleCloseDescriptionBox = () => {
    setDescriptionVisible(false);
  };

  const dispatch = useAppDispatch();
  const updateObjective = async () => {
    const newNotes = note?.trim() !== '' ? [...notes, { note, createdAt: Math.floor(Date.now() / 1000) }] : notes;
    const data = {
      id: objId,
      objective: objTitle,
      description: objDescription,
      status: objStatus,
      notes: newNotes
    };
    const response = await API.updateMenteeLearningObjectives({ objId, data });
  };

  const handleSubmit = async () => {
    setSubmitLoader(true);

    try {
      const newNotes = note?.trim() !== '' ? [...notes, { note, createdAt: Math.floor(Date.now() / 1000) }] : notes;

      const objectiveData = {
        objective: objTitle,
        description: objDescription,
        status: objStatus,
        notes: newNotes,
        mentors: localMentorsList
      };

      if (isNewObjective) {
        const currentObjectives = learningObjectives || [];
        const emptyObjectiveIndex = currentObjectives.findIndex((obj: any) => !obj.objective?.trim());

        if (emptyObjectiveIndex !== -1) {
          const updatedObjectives = [...currentObjectives];
          updatedObjectives[emptyObjectiveIndex] = objectiveData;

          const response = await API.saveLearningObjectives({
            learningObjectives: updatedObjectives
          });
        } else {
          const response = await API.saveLearningObjectives({
            learningObjectives: [...currentObjectives, objectiveData]
          });
        }

        queryClient.invalidateQueries({
          queryKey: [queryKeys.GET_LEARNING_OBJS, grpId]
        });
        onClose();
      } else {
        const updatedData = {
          ...objectiveData,
          id: objId
        };

        onSave && onSave(updatedData);
        await updateObjective();
        udpdateObjQuery();
        onClose();
      }
    } catch (error) {
      console.error('Error saving objective:', error);
    } finally {
      setSubmitLoader(false);
    }
  };

  useEffect(() => {
    const fetchObjectiveOptions = async () => {
      try {
        const { orgId } = getUserDetails();
        const response = await API.getUserLearningObjectives({ orgId });
        // console.log(response, 'ressss');
        const options = response.data?.data.map((objective: any) => ({
          value: objective.objective,
          label: objective.objective
        }));
        setObjectiveTitleOptions(options);
      } catch (error) {
        console.error('Error fetching objective options:', error);
        //setObjectiveTitleOptions([{ value: 'other', label: 'Other' }]);
      }
    };

    if (isNewObjective) {
      fetchObjectiveOptions();
    }
  }, [isNewObjective]);
  const handleNavigateToChat = () => {
    let communicationId = _.get(selectedMember, 'communicationId', '');
    const listedInCommunity = communityMembers?.find((each) => each?.id?.communicationId === communicationId);
    let isInCommunity = false;
    if (listedInCommunity) {
      isInCommunity = true;
    }
    navigateToChat(communicationId, isInCommunity, personalThreadsArr, navigate, dispatch);
  };

  useEffect(() => {
    if (selectedMember) {
      handleNavigateToChat();
    }
  }, [selectedMember]);

  /** Previous arrow click */
  const handlePrevClick = () => {
    const currentIndex = findCurrentObjectiveIndex();
    if (currentIndex > 0) {
      const prevObjective = allObjectives[currentIndex - 1];
      onSubmit && onSubmit({
        action: 'navigate',
        direction: 'prev',
        objectiveId: prevObjective.id
      });
    }
  };

  /** Next arrow click  */
  const handleNextClick = () => {
    const currentIndex = findCurrentObjectiveIndex();
    if (currentIndex < allObjectives.length - 1) {
      const nextObjective = allObjectives[currentIndex + 1];
      onSubmit && onSubmit({
        action: 'navigate',
        direction: 'next',
        objectiveId: nextObjective.id
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        onClose={onClose}
        PaperProps={{ sx: { height: '100%' } }}
      >
        {/* Modal Header */}
        <CommonModalHeader
          title={title ? title : 'Add Objective'}
          handleCloseModal={onClose}
          nextPrev={title ?
            {
              onPrevClick: handlePrevClick,
              onNextClick: handleNextClick,
              isPrevDisabled: findCurrentObjectiveIndex() === 0,
              isNextDisabled: findCurrentObjectiveIndex() === allObjectives.length - 1,
            } : undefined
          }
        />

        {/* Modal Content */}
        <DialogContent className="p-0">
          <Grid container spacing={2} p={2} pb={isMentor ? 0 : 2}>
            {/* Objective Title */}
            {!title && isNewObjective ? (
              <Grid item xs={12}>
                <Box display={'flex'} gap={1}>
                  <InputLabel htmlFor="objective-title">Objective Title {isMentor && lockIcon({})}</InputLabel>
                </Box>
                <Controller
                  name="objectiveTitle"
                  control={control}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      {...field}
                      id="objective-title"
                      variant="outlined"
                      displayEmpty
                      placeholder="Enter Objective Title"
                      value={objTitle}
                      onChange={(e) => setObjTitle(e.target.value)}
                      renderValue={(selected) => selected || 'Select Objective'}
                    >
                      {objectiveTitleOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Box display={'flex'} gap={1}>
                  <InputLabel htmlFor="objective-title">Objective Title {isMentor && lockIcon({})}</InputLabel>
                </Box>
                <TextField fullWidth id="objective-title" variant="outlined" value={objTitle} disabled />
              </Grid>
            )}

            {/* For Mentor */}
            {isMentor &&
              <Box textAlign={'center'} width={"100%"} mt={2}>
                <Typography variant='subtitle2' color={appColors.blue1}>You have been matched to help with this objective</Typography>
              </Box>
            }

            {/* Objective Description */}
            <Grid item xs={12}>
              <Box display={'flex'} gap={0.5}>
                <InputLabel htmlFor="objective-description">
                  Objective Description {isMentor && lockIcon({})}
                </InputLabel>
              </Box>
              <Controller
                name="objectiveDescription"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    {...field}
                    variant="outlined"
                    disabled={isMentor}
                    id="objective-description"
                    placeholder="Enter Objective Description"
                    value={objDescription}
                    onChange={(e) => setObjDescription(e.target.value)}
                  />
                )}
              />
            </Grid>

            {/* Objective Status */}
            <Grid item xs={12} className={isMentor ? 'pointerEvents-none' : ''}>
              <Box display={'flex'}>
                <InputLabel htmlFor="objective-status">Objective Status {isMentor && lockIcon({})}</InputLabel>
              </Box>
              <Controller
                name="objectiveStatus"
                control={control}
                render={({ field }) => (
                  <Select
                    fullWidth
                    {...field}
                    id="objective-status"
                    sx={{
                      backgroundColor: getStatusColor(objStatus),
                      color: '#FFFFFF',
                      '& .MuiSelect-icon': {
                        color: '#FFFFFF', // Change the icon color to white
                        display: isMentor ? 'none' : 'unset' // Hide icon if isMentor is true
                      }
                    }}
                    value={objStatus}
                    onChange={(e) => {
                      console.log('e.target.value', e.target.value);
                      setObjStatus(e.target.value);
                    }}
                  >
                    {statusOptions?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>

            {/* Objective Mentors */}
            <Grid item xs={12}>
              <Typography>Objective Mentors</Typography>
              {/* If no mentors, show placeholder text */}
              {mentorsList?.length === 0 ? (
                <Typography textAlign={'center'} mb={2}>No mentors available</Typography>
              ) : (
                <ObjectiveMentors
                  mentorsList={mentorsList}
                  onChatClick={(each) => navigateMember(each)}
                  onCalendarClick={(each) => handleCalendarOpen(each)}
                  myMenteesList={myMenteesList}
                />
              )}
              {isMentee && (
                <Grid container justifyContent="center" alignItems="center">
                  <Button
                    color="info"
                    variant="contained"
                    className="w-50p fs-14"
                    disabled={isNewObjective}
                    onClick={handleRecruitModalOpen}
                  >
                    Recruit New Mentor
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2} p={2}>
            {/* Objective Notes */}
            <Grid item xs={12}>
              <Typography>Objective Notes</Typography>
              {/* If description box is visible, show TextField for entering the note */}
              {isDescriptionVisible ? (
                <Box mt={2}>
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Objective Description"
                    value={note}
                    onChange={handleNoteChange}
                    onBlur={handleCloseDescriptionBox}
                  />
                </Box>
              ) : notes.length > 0 ? (
                notes.map((note, index: number) => {
                  return (
                    <Box mt={1} key={index}>
                      <Typography variant="h6">{moment.unix(note.createdAt).format('MMM DD, YYYY')}</Typography>
                      <Typography>{note.note}</Typography>
                    </Box>
                  );
                })
              ) : (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mt={2} width={'100%'}>
                  <Typography>No notes available</Typography>
                </Box>
              )}

              {!isDescriptionVisible && isMentee && (
                <Grid container justifyContent="center" alignItems="center">
                  <Button
                    color="inherit"
                    variant="contained"
                    className="w-50p fs-14 mt-20 hover-inherit"
                    onClick={handleAddNewNoteClick}
                  >
                    Add New Note
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        {/* Modal Actions */}
        {isMentee && (
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button onClick={onClose} variant="outlined" fullWidth>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton onClick={handleSubmit} variant="contained" loading={submitLoader} fullWidth>
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </Dialog>

      {/* Recruit New Mentor Modal */}
      {recruitModalOpen && (
        <RecruitNewMentor
          title={objTitle}
          open={recruitModalOpen}
          onClose={handleRecruitModalClose}
          mentorsList={mentorsList}
          onProfileClick={(mid) => console.log(`Profile clicked: ${mid}`)}
          onChatClick={(each) => navigateMember(each)}
          onCalendarClick={(each) => handleCalendarOpen(each)}
          objId={objId}
          grpId={grpId}
        />
      )}

      <CallBookingModal
        title={title}
        handleCloseModal={handleCalendarClose}
        openFlag={openCalendarModal}
        chatProfileName={displayName}
        threadParticipantsUsers={participantUser}
        chatType="Chats"
        pageName="Mentor"
        bookedCallDetails={bookedCallDetails}
        onMeetingScheduled={onMeetingScheduled}
      />
    </>
  );
};

export default ObjectiveModal;
