import MenteeTask from './MenteeTask';
import { Dialog, DialogContent } from '@mui/material';
import MentorViewBadge from '../../components/MentorViewBadge';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';

interface TaskModalProps {
  open: boolean;
  onClose: () => void;
  selectedMenteeData: {
    levelId: string;
    taskId: string;
    menteeDetails?: any;
  } | null;
}

function TaskModal({ open, onClose, selectedMenteeData }: TaskModalProps) {
  const userName = selectedMenteeData?.menteeDetails.displayName || ''

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'xl'}>
      {/* Dialog Header */}
      <CommonModalHeader title={`${userName} view`} handleCloseModal={onClose} />
      <MentorViewBadge userName={userName} place="Popup" />
      <DialogContent>
        <MenteeTask selectedMenteeData={selectedMenteeData} place={"Popup"} />
      </DialogContent>
    </Dialog>
  );
}

export default TaskModal;
