import { useAppSelector } from '../redux/hooks';

export const useModule = () => {
  const groupDetails = useAppSelector((state) => state.groupDetails);
  const currentModule = groupDetails.data?.model; // Fetching current module from Redux state
  // const currentModule: string = 'masterclass'; // For testing
  // console.log('currentModule', currentModule);

  return {
    isMasterclass: currentModule === 'masterclass',
    isMentorship: currentModule === 'mentorship',
    isHybrid: currentModule === 'masterclass&mentorship'
  };
};
