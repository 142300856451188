import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import greenCheckImg from '../assets/images/greencheckimg.svg';
import greyCheckImg from '../assets/images/greycheckimg.svg';

interface CheckableImageProps {
  checked?: boolean;
  size?: number;
  onChange?: (newState: boolean) => void;
}

const CheckableTickIcon: React.FC<CheckableImageProps> = ({ checked = false, size = 20, onChange }) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  // Sync local state with prop if controlled
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleToggle = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    if (onChange) {
      onChange(newState);
    }
  };

  return (
    <Box
      width={size}
      height={size}
      component="img"
      src={isChecked ? greenCheckImg : greyCheckImg}
      alt={isChecked ? 'Checked' : 'Unchecked'}
      //onClick={handleToggle}
      sx={{
        cursor: 'pointer',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)'
        },
        '&:active': {
          transform: 'scale(0.9)'
        }
      }}
    />
  );
};

export default CheckableTickIcon;
