import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Switch,
  Button,
  TextField,
  Typography,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { API } from '../../../../../../../api';
import { lockIcon } from '../../../../../components/common';
import { useAppSelector } from '../../../../../../../redux/hooks';

interface LearningObjective {
  _id: string;
  title: string;
  content: string;
  isNew?: boolean;
}
interface LearningObjectsSettingProps {
  locked?: boolean;
  control: any;
  setLearningObjectivesData: (data: { number: number; objectives: string[] }) => void;
  learningObjectivesData: any;
  setLearningObjectiveUpdateFlag: any;
  model: any;
  errors?:any;
}

const LearningObjectsSetting: React.FC<LearningObjectsSettingProps> = ({
  locked = false,
  control,
  setLearningObjectivesData,
  learningObjectivesData,
  setLearningObjectiveUpdateFlag,
  model = '',
  errors
}) => {

  const lockOpacity = locked ? 'disabled-box-opacity-25' : '';
  //const [allowCustomLearningObjects, setAllowCustomLearningObjects] = useState(false);
  const [learningObjectives, setLearningObjectives] = useState<LearningObjective[]>([]);
  const [customLearningObjectiveCount, setCustomLearningObjectiveCount] = useState<number>(1);
  const grpId = useParams().id || '';
  const orgId = useParams().orgId || '';
  const isMentorFieldLocked = model === 'masterclass' || model === 'masterclass&mentorship';
  const groupData = useAppSelector((state) => state.groupDetails);

  const fetchLearningObjectives = async () => {
    try {
      const response = await API.getLearningObjectives({ grpId, orgId });
      if (response.status === 200) {
        const {  number, objectives } = response.data.data.learningObjectives || {};
        //setAllowCustomLearningObjects(display ?? false);
        setCustomLearningObjectiveCount(number ?? 1);
        setLearningObjectives(
          (objectives ?? []).map((obj: { objective: string; id: string }, index: number) => ({
            _id: `id-${index + 1}`,
            title: `Learning Objective Option ${index + 1}`,
            content: obj.objective,
            objectiveId: obj.id,
            isNew: false
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching learning objectives:', error);
    }
  };

  useEffect(() => {
    if (Array.isArray(learningObjectivesData)) {
      setLearningObjectives(learningObjectivesData);
    }
  }, [learningObjectivesData]);

  useEffect(() => {
    const validObjectives = learningObjectives
      .map(obj => obj.content)
      .filter(content => content && content.trim() !== '');
  
    const newData = {
      number: customLearningObjectiveCount,
      objectives: validObjectives
    };
  
    setLearningObjectivesData(newData);
  }, [customLearningObjectiveCount, learningObjectives, setLearningObjectivesData]);

  useEffect(() => {
    fetchLearningObjectives();
  }, []);
  const addLearningObjective = () => {

      const newIndex = learningObjectives.length + 1;
      const newObjective = {
        _id: `id-${newIndex}`,
        title: `Learning Objective ${newIndex}`,
        content: '',
        isNew: true
      };
      setLearningObjectives((prev) => [...prev, newObjective]);
    
  };
  // Handle removing a learning objective by id
  const handleRemoveRow = (idToRemove?: string) => {
    const updatedObjectives = learningObjectives.filter(({ _id }) => _id !== idToRemove);
    // Renumber the remaining learning objectives after deletion
    const renumberedObjectives = updatedObjectives.map((obj, index) => ({
      ...obj,
      _id: `id-${index + 1}`,
      title: `Learning Objective ${index + 1}`
    }));

    setLearningObjectives(renumberedObjectives);
    setLearningObjectiveUpdateFlag(true);
  };

  return (
    <Box>
      {/* Title */}
      <Box display="flex" gap={0.5} alignItems="center" mb={2}>
        <Typography variant="h5">
          <span className={lockOpacity}>Learning Objectives</span>{' '}
          {isMentorFieldLocked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
        </Typography>
      </Box>

      <Grid container className={lockOpacity} mb={2}>
        {/* Radio buttons */}
        {/* <Grid item xs={12} display="flex" alignItems="center">
          <FormControl component="fieldset" disabled={isMentorFieldLocked}>
            <RadioGroup
              row
              name="learningObjects"
              value={allowCustomLearningObjects ? 'true' : 'false'}
              onChange={(e) => {
                const selectedValue = e.target.value === 'true';
                setAllowCustomLearningObjects(selectedValue);
                setLearningObjectiveUpdateFlag(true);
                if (!selectedValue) {
                  setLearningObjectives([]);
                  setLearningObjectivesData({
                    display: false,
                    number: customLearningObjectiveCount,
                    objectives: []
                  });
                } else {
                  setLearningObjectivesData({
                    display: true,
                    number: customLearningObjectiveCount,
                    objectives: learningObjectives.map((obj) => obj.content)
                  });
                }
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Require members to select their Learning Objectives from a list of options that you create"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Allow members to create their own Learning Objectives from scratch"
              />
            </RadioGroup>
          </FormControl>
        </Grid> */}

        {/* Render Controller for custom learning objective */}
        <Grid item xs={12} container spacing={1} alignItems={'center'}>
          <Grid item xs={9} md={6} lg={5} xl={6}>
            <Typography>How many Learning Objectives must mentees achieve as part of this program?</Typography>
          </Grid>
          {/* Custom learning objective input field */}
          <Grid item xs={3} md={2} lg={1}>
            <Controller
              control={control}
              name="learningObjectives.number"
              defaultValue={customLearningObjectiveCount.toString()}
              render={({ field: { onChange, value },fieldState: { error }  }) => (
                <TextField
                  fullWidth
                  type="number"
                  placeholder="4"
                  value={customLearningObjectiveCount}
                  onChange={(e) => {
                    const count = parseInt(e.target.value, 10);
                    setCustomLearningObjectiveCount(count);
                    setLearningObjectiveUpdateFlag(true);
                    onChange(count);
                  }}
                  inputProps={{ min: 1 }}
                  disabled={isMentorFieldLocked}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
     
      {/* Learning objectives */}
      <Grid container spacing={2}>
        <Grid item xs={9} md={6} lg={5} xl={6}>
          <Typography>If you like, you may add suggested Learning Objectives for members to select from</Typography>
        </Grid>
        {learningObjectives.map(({ _id, title, content, isNew }, index) => (
          <Grid item xs={12} key={_id}>
            <Grid container spacing={1}>
              {/* Objective Level Label */}
              <Grid item xs={12}>
                <Typography>{title}</Typography>
              </Grid>
              {/* Input Field */}
              <Grid item xs={10} md={6} lg={5}>
                <TextField
                  fullWidth
                  placeholder={`Enter ${title}`}
                  value={content}
                  onChange={(e) => {
                    const updatedObjectives = [...learningObjectives];
                    updatedObjectives[index].content = e.target.value;
                    setLearningObjectives(updatedObjectives);
                    setLearningObjectiveUpdateFlag(true);
                  }}
                  disabled={isMentorFieldLocked || (groupData.data?.settingsUpdate && !isNew)}
                />
              </Grid>
              {/* Remove Link */}
              <Grid item xs={2}>
                <Button
                  className="fs-12"
                  color="error"
                  onClick={() => handleRemoveRow(_id)}
                  disabled={isMentorFieldLocked || (groupData.data?.settingsUpdate && !isNew)}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* Add New Learning Objective Button */}
      <Box mt={1}>
        {(() => {
          const isDisabled = isMentorFieldLocked;
          return (
            <IconButton disabled={isDisabled} size="small" onClick={isDisabled ? undefined : addLearningObjective}>
              <AddCircleIcon fontSize={'large'} color={isDisabled ? 'disabled' : 'info'} />
            </IconButton>
          );
        })()}
      </Box>
       {errors?.learningObjectives?.objectives && (
      <Typography color="error" variant="body2">
        {errors.learningObjectives.objectives.message}
      </Typography>
    )}
    </Box>
  );
};

export default LearningObjectsSetting;
