const slidesPerViewValue: 'auto' | number = 'auto';

export const swiperSettings = {
  className: 'mySwiper',
  slidesPerView: slidesPerViewValue,
  spaceBetween: 20,
  centeredSlides: true,
  effect: 'cards',
  coverflowEffect: {
    rotate: -10,
    stretch: -45,
    depth: 100,
    modifier: 1,
    slideShadows: false
  }
};
