/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useMemo,
  useState,
  useEffect
} from 'react';
import {
  Box,
  Divider,
  Typography
} from '@mui/material';
import {
  checkCallType,
  timeZonWiseDateTime
} from '../../components/common';
import { makeStyles } from '@mui/styles';

interface Props {
  callData: calldataProps;
  authUserId: string;
  timezone: string;
  place?: string;
}
interface calldataProps {
  callStatus: string;
  callDate: string;
  callTime: { toString: () => string };
  callTimeInterval: number;
  participantsUser: { callRejectStatus: boolean; mid: string; isCallJoined: boolean }[];
}

const CalendarIcon: React.FC<Props> = ({ callData, authUserId, timezone, place }) => {
  const [day, setDay] = useState<string | null>(null);
  const [weekday, setWeekday] = useState<string | null>(null);
  const [month, setMonth] = useState<string | null>(null);
  const matchingParticipant = callData?.participantsUser?.find((user) => user.mid === authUserId);
  const isCallRejectedByAuthUser = matchingParticipant?.callRejectStatus;

  const getJoinedParticipantsCount = (
    participants: { callRejectStatus: boolean; mid: string; isCallJoined: boolean }[]
  ) => {
    return participants?.filter((participant) => participant.isCallJoined === true).length;
  };
  const joinedParticipantsCount = getJoinedParticipantsCount(callData.participantsUser);
  const isBookerJoined = matchingParticipant?.isCallJoined || false;

  let monthBoxColour = '#000000';
  const callTypeData = checkCallType(
    callData.callStatus,
    callData.callDate,
    callData.callTime,
    callData.callTimeInterval,
    isCallRejectedByAuthUser,
    joinedParticipantsCount,
    isBookerJoined
  );

  monthBoxColour = callTypeData.color_code;

  const useStyles = makeStyles({
    CalendarIcon: {
      //maxWidth: '68px',
      display: 'none',
      '@media (min-width: 900px)': {
        display: 'block'
      }
    },
    monthBox: {
      background: monthBoxColour,
      borderRadius: '8px 8px 0 0',
      '& p': {
        color: '#FFFFFF',
        textAlign: 'center',
        // fontSize: '12px',
        lineHeight: '16px',
        padding: '3px 0'
      }
    },
    dayweekdayBox: {
      background: '#FFFFFF',
      borderRadius: '0 0 8px 8px',
      padding: '0 10px'
    },
    dayBox: {
      background: '#FFFFFF',
      '& p': {
        color: '#152536',
        textAlign: 'center',
        // fontSize: '28px',
        fontWeight: 600,
        // lineHeight: '28px',
        padding: '3px 0'
      }
    },
    weekdayBox: {
      background: '#FFFFFF',
      '& p': {
        textAlign: 'center',
        fontSize: '14px',
        lineHeight: '16px',
        padding: '3px 0'
      }
    },
    CalendarIconMobile: {
      display: 'flex',
      background: '#FFFFFF',
      borderRadius: '8px',
      inlineSize: 'fit-content',
      height: '16px',
      '@media (min-width: 900px)': {
        display: 'none'
      }
    },
    MobileDayMonth: {
      background: monthBoxColour,
      color: '#FFFFFF !important',
      borderRadius: '8px',
      padding: '2px 8px',
      fontSize: '10px !important',
      fontWeight: '700',
      lineHeight: '1.2 !important'
    },
    MobileWeekday: {
      color: '#152536',
      fontSize: '10px !important',
      padding: '2px 8px',
      fontWeight: '700',
      lineHeight: '1.2 !important'
    }
  });

  const homePageStyles = useMemo(() =>
    place === "HomePage"
      ? {
        boxShadow: "0px 1.365px 5.459px 0px rgba(0, 0, 0, 0.10)",
        borderRadius: "10px",
        width: "90px",
        maxWidth: "90px",
        fontSize: "16px",
        dayFontSize: "38px",
        dayLineHeight: "39px",
      }
      : {
        borderRadius: "",
        width: "68px",
        maxWidth: "68px",
        fontSize: "12px",
        dayFontSize: "28px",
        dayLineHeight: "28px",
      },
    [place]
  );

  const classes = useStyles();

  useEffect(() => {
    const init = () => {
      const time = timeZonWiseDateTime(callData?.callDate?.toString(), callData?.callTime?.toString(), timezone);
      const locale = 'en-gb';
      const DD = time.getDate();
      const DDD = time.toLocaleString(locale, { weekday: 'short' });
      const MMM = time.toLocaleString(locale, { month: 'short' });

      setDay(String(DD));
      setWeekday(DDD);
      setMonth(MMM);

      // var DDDD = time.toLocaleString(locale, { weekday: "long" });
      // var MM   = time.getMonth() + 1;
      // var MMMM = time.toLocaleString(locale, {month: "long"});
      // var YYYY = time.getFullYear();
    };

    init();
  }, [callData, timezone]);

  return (
    <>
      {/* Desktop */}
      <Box
        className={classes.CalendarIcon}
        boxShadow={homePageStyles?.boxShadow}
        borderRadius={homePageStyles?.borderRadius}
        width={homePageStyles?.width}
        maxWidth={homePageStyles?.maxWidth}
      >
        <Box className={classes.monthBox}>
          <Typography fontSize={homePageStyles?.fontSize}>{month}</Typography>
        </Box>
        <Box className={classes.dayweekdayBox}>
          <Box className={classes.dayBox}>
            <Typography
              fontSize={homePageStyles?.dayFontSize}
              lineHeight={homePageStyles?.dayLineHeight}
            >
              {day}
            </Typography>
          </Box>
          <Divider />
          <Box className={classes.weekdayBox}>
            <Typography>{weekday}</Typography>
          </Box>
        </Box>
      </Box>

      {/* Small screen */}
      <Box className={`${classes.CalendarIconMobile}`}>
        <Typography className={classes.MobileDayMonth}>
          {day} {month}
        </Typography>
        <Typography className={classes.MobileWeekday}>{weekday}</Typography>
      </Box>
    </>
  );

};

export default CalendarIcon;
