import React, { useState } from 'react';
import {
    Button,
    Dialog,
    TextField,
    Typography,
    DialogActions,
    DialogContent,
} from '@mui/material';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';

interface ChatWithMeDialogProps {
    openChatWithMe: boolean;
    handleClickChatWithMe: (flag: boolean) => void;
    onSendMessage?: (content: string) => void;
    data?:any;
}
  
const ChatWithMeDialog: React.FC<ChatWithMeDialogProps> = ({
    openChatWithMe,
    handleClickChatWithMe,
    onSendMessage,
    data
}) => {
    const [message, setMessage] = useState(''); 
    const handleSendMessage = () => {
        if (message.trim()) {
            if (onSendMessage) {
                onSendMessage(message);
              }
          setMessage('');
        }
      };
    return(
        <Dialog
            open={openChatWithMe}
            onClose={() => handleClickChatWithMe(false)}
        >
            <CommonModalHeader 
                title={`Message`}
                handleCloseModal={() => handleClickChatWithMe(false)}
            />
            <DialogContent>
                <Typography variant='h5' mb={1}>Message</Typography>               
                {data?.map((mentor: any) => (
                <TextField key={mentor.mid} multiline rows={4} placeholder={`Write something to ${mentor.name}`} value={message}
                onChange={(e)=>setMessage(e.target.value)} />
                ))}
            </DialogContent>
            <DialogActions className='pt-0'>
                <Button fullWidth variant='outlined' color='secondary' onClick={() => handleClickChatWithMe(false)}>
                    Cancel
                </Button>
                <Button fullWidth variant='contained' onClick={handleSendMessage}>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChatWithMeDialog;
