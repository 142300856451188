import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Table,
  Stack,
  Button,
  Popover,
  Divider,
  Tooltip,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  IconButton,
  TableContainer
} from '@mui/material';
import _ from 'lodash';
import {
  // bioFormatter,
  // IsStarVenture,
  getStatusColor,
  getDisplayName,
  IsAgileExecutive,
  unixToHumanReadable,
  formatNumberWithCommas,
  truncateTextWithTooltip,
  GroupBasedRoleFormatter,
  getGoalProgressShowOrNot,
} from '../../components/common';
import {
  DeleteIcon,
  BlueBackgroundNotesIcon,
  GreenBackgroundChatIcon,
  YellowBackgroundVideoIcon
} from '../../../../utils/Icons';
import AvatarRow from './Avatars';
import TaskModal from './TaskModal';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import TagBox from '../../components/TagBox';
import ObjectiveModal from './ObjectiveModal';
import { useAuth0 } from '@auth0/auth0-react';
import UserPeersPopup from './UserPeersPopup';
import HelpfulTips from './cards/HelpfulTips';
import { useNavigate } from 'react-router-dom';
import YourNextMeeting from './YourNextMetting';
import { useQuery } from '@tanstack/react-query';
// import UserNotesPopup from './UserNotesPopup';
import MatchCancelModal from './MatchCancelModal';
import MyTooltip from '../../components/MyTooltip';
import { navigateToChat } from '../NavigateToChat';
import CallBookingModal from '../CallBookingModal';
import { useLoader } from '../../components/useLoader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { FilterIcon } from '../../../../utils/Icons';
import { useModule } from '../../../../utils/useModule';
import MySkeleton from '../../../../components/Skeleton';
import AppLoader from '../../../../components/AppLoader';
import { getUserDetails } from '../../../../utils/orgName';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import frequency from '../../../../assets/images/frequency.svg';
import { MyCardBox1 } from '../../style-components/CardStyling';
import useGroupDetails from '../../../../queries/useGroupDetails';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import StudentTableLoader from '../../../../Loader/StudentTableLoader';
import { OrgBasedCurrencySymbol } from './cards/OrgBasedCurrencySymbol';
import { getAcsUserByCommunicationId } from '../../../../routes/helpers';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import connectionschart from '../../../../assets/images/connectionschart.svg';
// import MatchRequestTableLoader from '../../../../Loader/MatchRequestTableLoader';
// import { updateSelectedId } from '../../../../redux/slices/selectedGroupSlice';
import { taskActions } from '../../../../redux/slices/goals-tasks/getTasksSlice';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import LinearProgressWithLabel from '../Matches/components/LinearProgressWithLabel';
import { fetchGroupDetails } from '../../../../redux/slices/group-details/groupDetails';
import { RequestMatch, RequestMatchReject } from '../Matches/components/RequestAndRejectMatch';

const Mentor = () => {
  /** The Agile Executive checking */
  const isAgileExec = IsAgileExecutive();
  // const isStarVentureExec = IsStarVenture();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { location, orgId } = getUserDetails();
  const { isMasterclass, isMentorship, isHybrid } = useModule(); // Fetching current module from Redux state
  // const orgDetails = useAppSelector((state) => state.orgDetails);
  // const allGroupsTasks = useAllGroupTasks();
  // const taskData = allGroupsTasks.data ? _.last(allGroupsTasks.data) : [];
  // const groupIds = _.get(taskData, '_id');
  // const levels = _.get(taskData, 'levels');
  // const levelId = _.get(_.head(levels), '_id');
  // const tasks = _.get(_.head(levels), 'tasks');
  // const taskId = _.get(_.head(tasks), '_id');
  const [loadingMentor, setLoadingMentor] = useState('');
  const [peersList, setPeersList] = useState<any>();
  const [connectionCount, setConnectionCount] = useState('');
  const [myMenteesList, setMyMenteesList] = useState<any>([]);
  const [myRequestingMenteesList, setRequestingMenteesList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedMember, setSelectedMember] = useState<any>(null);
  const avatars = peersList?.map((each: any) => ({ avatar: each?.headshot, firstName: each?.displayName }));
  const [displayName, setDisplayName] = useState('');
  const [participantUser, setParticipantUser] = useState<any>([]);
  const [mentorshipHoursValue, setMentorshipHours] = useState('00:00');
  const [mentorRoi, setMentorRoi] = useState<any>(0);
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const communityMembers = useAppSelector((state) => state.acsCommunityUsers.data);
  const commList = useAppSelector((state) => state.getCommunityMembers.data);
  /** Show goal progress */
  const [hasShowGoalProgressValue, setHasShowGoalProgressValue] = useState<any>();
  const showGoalProgress = getGoalProgressShowOrNot(true);
  const groupId = selectedGroupId.id || '';
  const groupList = useAppSelector((state) => state.groupRoleList);
  const [isPeersPopupOpen, setPeersPopupOpen] = React.useState(false);
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const menteeRoleText = GroupBasedRoleFormatter('mentee', 'Mentee');
  const menteeRoleTextUpperCase = menteeRoleText.toUpperCase();
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [bookedCallDetails, setBookedCallDetails] = useState<any>('');

  const [openTask, setOpenTask] = useState(false);
  const [selectedTaskDetails, setSelectedTaskDetails] = useState<{
    levelId: string;
    taskId: string;
    displayName?: string;
    menteeDetails: any;
  } | null>(null);

  const {
    data: menteeList,
    isLoading: myMenteeLoading,
    error,
    isFetching,
    refetch
  } = useQuery({
    queryKey: ['getMentorsPeersHomePage'],
    queryFn: () => {
      return API.getMyMentees({ orgId, groupId });
    },
    staleTime: 10 * 10 * 1000
  });

  const showLoader = useLoader(loading || myMenteeLoading || groupList.loading, 1000);
  const loader = showLoader || loadingMentor;
  /** For request and reject match dialog */
  const [openRequestMatch, setOpenRequestMatch] = useState(false);
  const [openRequestMatchReject, setOpenRequestMatchReject] = useState(false);
  const [isObjectiveModalOpen, setObjectiveModalOpen] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [mentorId, setMentorId] = useState('');
  const [menteeId, setMenteeId] = useState('');
  const [commId, setCommId] = useState('');
  const [actionId, setActionId] = useState('');
  const [reloadFlag, setReloadFlag] = useState(false);
  const handleRequestMatch = (flag: boolean) => setOpenRequestMatch(flag);
  const handleRequestMatchReject = (flag: boolean) => setOpenRequestMatchReject(flag);
  const [objective, setObjective] = useState<any>({});
  const [selectedMentee, setSelectedMentee] = useState();
  const handleCloseObjectiveModal = () => {
    setObjectiveModalOpen(false);
  };
  const objUserId = useProfileDetails().userId;
  /** Popover */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMatchCancel, setOpenMatchCancel] = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState<string>('');
  const handleOpenMatchCancel = (matchId: string) => {
    setSelectedMatchId(matchId)
    setOpenMatchCancel(true);
    setAnchorEl(null); // Close popover when modal opens
  };
  const handleCloseMatchCancel = () => {
    setOpenMatchCancel(false);
  };
  const handleMatchesTablePopoverClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openMatchesTablePopover = Boolean(anchorEl);
  const idMatchesTablePopover = openMatchesTablePopover ? 'matches-popover' : undefined;
  /** Handle User hotes popup */
  // const [openUserNotes, setOpenUserNotes] = useState(false);
  // const handleOpenUserNotes = (flag: boolean) => setOpenUserNotes(flag);

  // const [groupId, setGroupId] = useState('');
  // const userLastseen = localStorage.getItem('lastseen');
  // const lastSeenRole = userLastseen ? JSON.parse(userLastseen) : null;

  //   useEffect(() => {
  //     dispatch(updateSelectedId(lastSeenRole));
  //   }, []);

  //   useEffect(() => {
  //     if (selectedGroupId) {
  //       setGroupId(selectedGroupId?.id);
  //     }
  //   }, [selectedGroupId]);

  const rejectMatchRequest = (requestId: string, mentorId: string, menteeId: string, commId: string) => {
    setRequestId(requestId);
    setMentorId(mentorId);
    setMenteeId(menteeId);
    setCommId(commId);
    handleRequestMatchReject(true);
  };

  const doReload = () => {
    setLoading(false);
    setReloadFlag(true);
  };

  useEffect(() => {
    if (reloadFlag) {
      setReloadFlag(false);
    }
  }, [reloadFlag]);
  const [requestLoading, setRequestLoading] = useState(false);

  const acceptMatchRequest = async (requestId: string, mentorId: string, menteeId: string) => {
    const grpId = groupId;
    setActionId(requestId);
    setLoading(true);
    setRequestLoading(true);
    try {
      const response = await API.acceptMatchRequest({ grpId, mentorId, menteeId, requestId });
      if (response.status === 200) {
        await refetch();
      }
    } catch (e) {
    } finally {
      setActionId('');
      setLoading(false);
      setRequestLoading(true);
    }
  };

  const removeMatchRequest = async (requestId: string) => {
    const grpId = groupId;
    setLoading(true);
    setRequestLoading(true);
    try {
      const response = await API.removeMatchRequest({ grpId, requestId });
      if (response.status === 200) {
        await refetch();
      }
    } catch (e) {
    } finally {
      setRequestLoading(false);
      setLoading(false);
    }
  };

  const handlePeersPopup = (open: boolean) => {
    setPeersPopupOpen(open);
  };

  const navigateMember = (member: any) => {
    setSelectedMember(member);
  };

  const handleCalendarOpen = (member: any) => {
    setDisplayName(member.displayName);
    setParticipantUser([member]);
    if (isMentorship) {
      setObjective(member.matchedObjectives[0]);
    }
    setBookedCallDetails('');
    setOpenCalendarModal(true);
  };

  const handleCalendarClose = () => {
    setDisplayName('');
    setParticipantUser([]);
    setBookedCallDetails('');
    setOpenCalendarModal(false);
  };

  const moveToUsercommunityProfile = (userId: string) => {
    const mem = communityMembers.find((item: { userId: string }) => item.userId === userId);
    const modifiedMem = { ...mem, id: _.get(mem, 'id.coId', '') };
    const allCommunityMembersList = communityMembers;

    if (mem) {
      navigate('/app/communitymember', {
        state: { mem: modifiedMem, allCommunityMembersList: commList }
      });
    }
  };

  const getMentorsPeersHomePage = async (orgId: string, location: string, groupId: string) => {
    try {
      const response = await API.getMentorsPeersHomePage(orgId, location, groupId);
      if (response.status === 200) {
        setPeersList(response?.data?.data);
        setConnectionCount(response?.data?.connections);
        setLoading(false);
      } else {
        setLoading(true);
      }
    } catch (e) {
      setLoading(true);
    }
  };

  useEffect(() => {
    if ((menteeList?.data?.data?.length > 0 || menteeList) && !isMentorship) {
      //setMyMenteesList(menteeList?.data?.data);
      setRequestingMenteesList(menteeList?.data?.reqestedMentees);
      setHasShowGoalProgressValue(menteeList?.data?.showGoalProgess);
    }
  }, [menteeList]);

  const getMyMenteesList = async ({ orgId, groupId }: { orgId: string; groupId: string }) => {
    try {
      const response = await API.getMyMentees({ orgId, groupId });
      if (response.status === 200) {
        setMyMenteesList(response?.data?.data);
        setRequestingMenteesList(response?.data?.reqestedMentees);
        setHasShowGoalProgressValue(response?.data?.showGoalProgess);
      } else {
        // toast.error('Mantors list failed to load');
      }
    } catch (e) {
      // toast.error('Mantors list failed to load');
    }
  };

  const grpDetails = useAppSelector((state) => state.groupDetails.data);
  const sessionsPerMatch = grpDetails?.matchesSettings?.sessionsPerMatch || 1;
  const getLiveSessions = (length: number) => {
    const value = Math.round((length / sessionsPerMatch) * 100);
    return isNaN(value) ? 0 : value > 100 ? 100 : value;
  };
  const getObjectiveMentees = async ({
    orgId,
    location,
    groupId
  }: {
    orgId: string;
    location: string;
    groupId: string;
  }) => {
    try {
      const response = await API.getMenteeListForMentor(orgId, location, groupId);
      if (response.status === 200 && response.statusText === 'OK') {
        const transformedData = response?.data?.data.map((item: any) => ({
          ...item,
          displayName: item.menteeName
        }));
        setMyMenteesList(transformedData);
      }
    } catch (e) {
      console.error('Failed to fetch mentees:', e);
    }
  };

  const getMatchId = (mentee: any): string | null => {
    return mentee.matchId || null; // Directly access matchId from the mentee object
  };

  const handleNavigateToChat = () => {
    let communicationId = _.get(selectedMember, 'communicationId', '');
    const listedInCommunity = communityMembers?.find((each) => each?.id?.communicationId === communicationId);
    let isInCommunity = false;
    if (listedInCommunity) {
      isInCommunity = true;
    }
    navigateToChat(communicationId, isInCommunity, personalThreadsArr, navigate, dispatch);
  };

  const { refetch: fetchGroupDetails } = useGroupDetails(groupId, getUserDetails().location);

  useEffect(() => {
    try {
      if (groupId) {
        fetchGroupDetails();
      }
    } catch (error) {
      console.log(error);
    }
  }, [groupId]);

  useEffect(() => {
    if (selectedMember) {
      handleNavigateToChat();
    }
  }, [selectedMember]);

  useEffect(() => {
    if (typeof groupId === 'string' && groupId !== '') {
      getMentorsPeersHomePage(orgId, location, groupId);
      if (isMentorship) {
        getObjectiveMentees({ orgId, location, groupId });
      } else {
        getMyMenteesList({ orgId, groupId });
      }
    }
  }, [groupId, reloadFlag, isMentorship, isHybrid]);

  const handleUserClick = async (each: any) => {
    if (!isAgileExec) {
      try {
        const acsUser = getAcsUserByCommunicationId(communityMembers, _.get(each, 'communicationId'));
        const coid = _.get(acsUser, 'id.coId', '');
        setLoadingMentor(_.get(each, 'communicationId'));
        dispatch(taskActions.setUserId({ userId: coid }));
        dispatch(taskActions.setGroupId({ groupId: groupId }));
        const result = await API.getAllTasks({ grpId: groupId, userId: coid });
        const data = _.get(result.data, 'data', []);
        const levels = _.get(_.head(data), 'levels');
        const levelId = _.get(_.head(levels), '_id');
        const tasks = _.get(_.head(levels), 'tasks');
        const taskId = _.get(_.head(tasks), '_id');
        const displayName = encodeURIComponent(getDisplayName(each));
          if (levelId && taskId) {
            setSelectedTaskDetails({
              levelId,
              taskId,
              displayName,
              menteeDetails: each,
            });
            setOpenTask(true);
          }
         
      } catch (err) {
        toast.error(_.get(err, 'response.data.message', ''));
      } finally {
        setLoadingMentor('');
      }
    } else {
      navigateToChat(each?.communicationId, true, personalThreadsArr, navigate, dispatch);
    }
  };

  // const getBio = (mem: any) => {
  //   const { major = '', university = '' } = _.get(mem, 'bio.education', {});
  //   const { role = '', companyName = '' } = _.get(mem, 'bio.workHistory', {});
  //   return mem?.category === 'Student' ? bioFormatter(major, university) : bioFormatter(role, companyName);
  // };

  // const handleArticleClick = (article_id: any) => {
  //   navigate('/app/library', { state: { navigatedArticleId: article_id } });
  // };

  if (groupList.error) {
    return <Box>{groupList.errorText}</Box>;
  }

  if (groupList.data?.length === 0) {
    return <Box>No groups found</Box>;
  }
  // if (groupList.data) {
  //   const index = groupList.data?.findIndex(
  //     (each) => each.id === selectedGroupId.id && each.role === selectedGroupId.role
  //   );
  //   if (index && index === -1) {
  //     window.location.replace('/');
  //     return null;
  //   }
  // }

  // const profileDetails = useProfileDetails();
  // const userName = profileDetails.firstName + ' ' + profileDetails.lastName;

  /** Advice Given card */
  const AdviceGiven = () => {
    return loader ? (
      <MySkeleton variant="rounded" height={forMobile ? 150 : 240} />
    ) : (
      <MyCardBox1>
        <Stack mb={1} direction="row">
          <Typography variant="h6">Advice Given</Typography>
          <MyTooltip
            gapleft={0.5}
            iconColor="#152536"
            title="The amount of time that you have provided giving advice to members in this program"
          />
        </Stack>
        <Box
          height={{ xs: 'auto', sm: '155px' }}
          textAlign="center"
          display="flex"
          flexDirection="column"
          gap="8px"
          justifyContent="center"
        >
          <Typography variant="h1" fontSize={forMobile ? '16px' : '60px'} noWrap>
            {mentorshipHoursValue} <span className={`fontSize-${forMobile ? '10' : '30'}`}>hrs</span>
          </Typography>
          <Typography textAlign="center" fontSize={forMobile ? '10px' : '14px'}>
            Contributing to{' '}
            <span className="color-info fw-600">
              <OrgBasedCurrencySymbol />
              {mentorRoi}{' '}
            </span>{' '}
            of advice provided in the program
          </Typography>
        </Box>
      </MyCardBox1>
    );
  };

  /** Number of Connections card */
  const NumberOfConnections = () => {
    return loader ? (
      <MySkeleton variant="rounded" height={forMobile ? 150 : 240} />
    ) : (
      <MyCardBox1 sx={{ background: forMobile ? '#1AA179' : '' }}>
        <Stack mb={1} direction="row">
          <Typography variant="h6" sx={{ color: forMobile ? '#FFFFFF' : '' }}>
            {forMobile ? 'No. of Connections' : 'Number of Connections'}
          </Typography>
          <MyTooltip
            gapleft={0.5}
            iconColor="#152536"
            title="The number of relationships that you have created in this program"
          />
        </Stack>
        <Box height={{ xs: 'auto', sm: '155px' }}>
          <Typography
            variant="h1"
            fontSize={forMobile ? '16px' : '60px'}
            noWrap
            color={forMobile ? '#FFFFFF' : ''}
            textAlign="center"
          >
            {formatNumberWithCommas(myMenteesList.length)}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <img src={forMobile ? connectionschart : frequency} alt="uparrow" width="100%" />
          </Box>
        </Box>
      </MyCardBox1>
    );
  };

  if (loadingMentor) {
    return <AppLoader />;
  }

  return (
    <>
      <Box className="bodyBox">
        <Grid container spacing={{ xs: 2, sm: 2 }}>
          {/* Next Mentorship Meeting */}
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <YourNextMeeting />
          </Grid>
          {/* Number of connections for mobile only */}
          {forMobile && (
            <Grid item xs={6} sm={6} lg={3} xl={3}>
              <NumberOfConnections />
            </Grid>
          )}

          {/* Advice given card */}
          <Grid item xs={6} sm={6} lg={3} xl={3}>
            <AdviceGiven />
          </Grid>

          {/* Number of connections not for mobile only */}
          {!forMobile && (
            <Grid item xs={6} sm={6} lg={3} xl={3}>
              <NumberOfConnections />
            </Grid>
          )}

          {/* Peers card */}
          {/* <Grid item xs={12} sm={6} lg={isMasterclass ? 3 : 4} xl={3}>
            {loader ? (
              <MySkeleton variant="rounded" height={forMobile ? 110 : 240} />
            ) : (
              <MyCardBox1>
                <Stack mb={1} direction="row" justifyContent="space-between">
                  <Typography variant="h6">Peers in your programs</Typography>
                </Stack>
                <Box
                  height={{ sm: '155px' }}
                  display="flex"
                  flexDirection={{ xs: 'row', sm: 'column' }}
                  justifyContent={{ xs: 'space-between', sm: 'center' }}
                  gap={{ xs: '8px', sm: 1.5 }}
                >
                  <Typography variant="h1" fontSize={forMobile ? '30px' : '60px'} noWrap textAlign="center">
                    {formatNumberWithCommas(peersList?.length)}
                  </Typography>
                  <Box display="flex" alignItems="center" gap="10px" justifyContent="center">
                    {peersList?.length > 0 ? (
                      <>
                        <AvatarRow avatars={avatars} />
                        <Typography
                          color="#0071A9"
                          fontSize={forMobile ? '10px' : '14px'}
                          className="cursor-pointer"
                          onClick={() => handlePeersPopup(true)}
                        >
                          View all peers
                        </Typography>
                      </>
                    ) : (
                      <Typography color="#0071A9" fontStyle="italic">
                        You have no peers in the program yet
                      </Typography>
                    )}
                  </Box>
                </Box>
              </MyCardBox1>
            )}
          </Grid> */}
          {/* Getting Started not For mobile */}

          {!forMobile &&
            <Grid item xs={12} sm={6} lg={3}>
              <HelpfulTips loader={loader} />
            </Grid>
          }
        </Grid>

        {/* Match Request Table */}
        {/* {!isMentorship && (
        <Box>
          {loader ? (
            <MatchRequestTableLoader />
          ) : (
            <Box mt={2} bgcolor="#FFFFFF" border="1px solid #EFF0F4" borderRadius="8px">
              <Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
                <Typography variant="h5" py={2}>
                  Match Requests
                </Typography> */}
        {/* <FilterIcon size={34} /> */}
        {/* </Box>
              <Divider />
              <TableContainer className="b-none">
                <Table className="tableBody" width="100%" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="40%">{isStarVentureExec ? 'MENTEE' : menteeRoleTextUpperCase} NAME</TableCell>
                      <TableCell width="45%">MESSAGE</TableCell>
                      <TableCell width="20%">ACTION</TableCell>
                      <TableCell width="0%" sx={{ p: '0' }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myRequestingMenteesList.length > 0 ? (
                      myRequestingMenteesList.map((each: any, index: any) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell width="40%">
                              <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                <ChatAvatarComponent
                                  image={each?.headshot}
                                  firstLetter={each?.displayName.slice(0, 1)}
                                  width="34px"
                                  height="34px"
                                  type="noStatus"
                                  border="1px solid #E3DFDC"
                                  cursor="pointer"
                                  onClickOnUserIcon={() => {}}
                                />
                                {!each?.rejected && <Typography variant="h5">{getDisplayName(each)}</Typography>}
                                {each?.rejected && (
                                  <Box display={'flex'} flexDirection={'column'} gap="5px">
                                    <Typography variant="h5">{getDisplayName(each)}</Typography>
                                    <Typography
                                      px="9px"
                                      py="2px"
                                      variant="caption"
                                      lineHeight="1"
                                      color="error"
                                      borderColor="error"
                                      border="1px solid"
                                      borderRadius="5px"
                                      textAlign="center"
                                      textTransform="capitalize"
                                    >
                                      Request Rejected
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell width="45%">
                              <Tooltip title={!each?.rejected ? each?.requestMessage : each?.rejectMessage}>
                                <Typography width={'350px'} textAlign="left" noWrap variant="subtitle1" color="primary">
                                  {!each?.rejected ? each?.requestMessage : each?.rejectMessage}
                                </Typography>
                              </Tooltip>
                            </TableCell>

                            <TableCell width="20%">
                              {!each?.rejected ? (
                                <Box display="flex" gap="8px">
                                  <Button
                                    color="success"
                                    variant="contained"
                                    className="acceptBtn"
                                    onClick={() => {
                                      if (!requestLoading) {
                                        acceptMatchRequest(each?.requestId, each?.mentorId, each?.menteeId);
                                      }
                                    }}
                                    disabled={myMenteeLoading || isFetching}
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    color="error"
                                    variant="contained"
                                    className="acceptBtn"
                                    onClick={() => {
                                      if (!requestLoading) {
                                        rejectMatchRequest(
                                          each?.requestId,
                                          each?.mentorId,
                                          each?.menteeId,
                                          each?.requestedcomId
                                        );
                                      }
                                    }}
                                    disabled={myMenteeLoading || isFetching}
                                  >
                                    Reject
                                  </Button>
                                </Box>
                              ) : (
                                <Button
                                  color="error"
                                  variant="contained"
                                  className="acceptBtn d-none"
                                  onClick={() => removeMatchRequest(each?.requestId)}
                                >
                                  Remove
                                </Button>
                              )}
                            </TableCell> */}
        {/* Extra */}
        {/* <TableCell width="0%" sx={{ p: '0' }} /></TableRow>
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography textAlign="center">You have no match requests</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
        )} */}

        {/*Student Table */}
        {loader ? (
          <StudentTableLoader />
        ) : (
          <Box mt={2} bgcolor="#FFF" border="1px solid #EFF0F4" borderRadius="8px">
            <Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
              <Box>
                <Typography variant="h5" py={2}>
                  Matches
                  {/* {isStarVentureExec ? "Mentee" : "Student"} */}
                </Typography>
              </Box>
              {/* <Box
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
                gap={'10px'}
              >
                <Box flex="flex" justifyContent="flex-end">
                  <FilterIcon size={32} />
                </Box>
              </Box> */}
            </Box>
            <Divider />
            <TableContainer className="b-none">
              <Table width="100%">
                <TableHead>
                  <TableRow>
                    <TableCell width="20%" colSpan={isAgileExec ? 3 : 0}>
                      {menteeRoleTextUpperCase.toLowerCase() !== 'client' ? 'NAME' : 'CONNECTION NAME'}
                    </TableCell>
                    <TableCell colSpan={2}>{isHybrid ? 'TITLE' : 'OBJECTIVE'}</TableCell>
                    <TableCell colSpan={2}>{isHybrid ? 'PROGRESS' : 'STATUS'}</TableCell>
                    <TableCell colSpan={2}>{isHybrid ? 'ASSESSMENT SCORE' : 'LIVE SESSIONS'}</TableCell>
                    <TableCell colSpan={2}>{isHybrid ? 'LAST CONNECTION' : 'LAST SESSION'}</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myMenteesList.length > 0 ? (
                    [...myMenteesList].map((each: any, index: any) => (
                      <TableRow key={index}>
                        {/* Name */}
                        <TableCell width="20%" colSpan={isAgileExec ? 3 : 0}>
                          <Box
                            gap="8px"
                            display="flex"
                            alignItems="center"
                            className={`cursor-${isAgileExec || (showGoalProgress && !isAgileExec) ? 'pointer' : 'default'}`}
                            // onClick={() => {
                            //   if (!isMentorship && (isAgileExec || (showGoalProgress && !isAgileExec))) {
                            //     handleUserClick(each);
                            //   }
                            // }}
                          >
                            <ChatAvatarComponent
                              image={each.headshot}
                              firstLetter={getDisplayName(each).slice(0, 1)}
                              width="34px"
                              height="34px"
                              type="noStatus"
                              border="1px solid #E3DFDC"
                              cursor={isAgileExec || (showGoalProgress && !isAgileExec) ? 'pointer' : 'default'}
                            />
                            <Box>
                              <Typography variant="h5">{getDisplayName(each)}</Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell colSpan={2}>
                          <Box display="flex" alignItems="center">
                            <Typography color="primary">
                              {(() => {
                                const objectives = each.matchedObjectives || [];
                                const firstObjective = objectives[0]?.objective ? `Learning Objective 1` : 'Objective';
                                return truncateTextWithTooltip(isHybrid ? each?.bio?.role : firstObjective, 30);
                              })()}
                            </Typography>

                            {(() => {
                              const objectives = each.matchedObjectives || [];
                              const additionalCount = objectives.length > 1 ? `+${objectives.length - 1}` : '';

                              return additionalCount ? (
                                <Box bgcolor={'info.main'} fontSize={'12px'} color={'#FFFFFF'} fontWeight={700} borderRadius={'30px'} px={.7} ml={1}>
                                  {additionalCount}
                                </Box>
                              ) : null;
                            })()}
                          </Box>
                        </TableCell>
                        <TableCell colSpan={2}>
                          {isHybrid ? (
                            <Box sx={{ width: '150px' }}>
                              <LinearProgressWithLabel
                                value={each?.goalProgress !== undefined ? each.goalProgress : 0}
                              />
                            </Box>
                          ) : (
                            <TagBox
                              text={each.matchedObjectives?.[0]?.status || 'To Do'}
                              color={getStatusColor(each.matchedObjectives?.[0]?.status || 'to-do')}
                            />
                          )}
                        </TableCell>
                        <TableCell colSpan={2}>
                          {isHybrid ? (
                            <Typography color="primary" fontStyle="italic">
                              Coming Soon
                            </Typography>
                          ) : (
                            <Box width="150px">
                              <LinearProgressWithLabel
                                value={
                                  isMentorship
                                    ? getLiveSessions(
                                      each?.matchedObjectives?.[0]?.mentors
                                        ?.filter((each: any) => each.userId === objUserId)?.[0]
                                        ?.meetings?.length || 0
                                    )
                                    : each?.goalProgress ?? 0
                                }
                              />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Typography color="primary">
                            {isHybrid
                              ? each.lastConnection
                                ? unixToHumanReadable(each.lastConnection)
                                : '-'
                              : each.matchedObjectives?.[0]?.lastSession
                                ? unixToHumanReadable(each.matchedObjectives[0].lastSession)
                                : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box display={'inline-flex'} alignItems={'center'}>
                            <IconButton size="small" onClick={() => navigateMember(each)}>
                              <GreenBackgroundChatIcon size={34} />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleCalendarOpen(each);
                              }}
                            >
                              <YellowBackgroundVideoIcon size={34} />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                if (isHybrid) {
                                  handleUserClick(each);
                                } else if (isMentorship) {
                                  setObjective(each?.matchedObjectives[0]);
                                  setObjectiveModalOpen(true);
                                  setSelectedMentee(each);
                                }
                              }}
                            >
                              <BlueBackgroundNotesIcon size={34} />
                            </IconButton>
                            {/* Popover */}
                            <IconButton size="small" onClick={handleMatchesTablePopoverClick}>
                              <MoreVertIcon color="primary" />
                            </IconButton>
                            <Popover
                              id={idMatchesTablePopover}
                              open={openMatchesTablePopover}
                              anchorEl={anchorEl}
                              onClose={handlePopoverClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <Box className="PopoverListStyle"
                                onClick={() => {
                                  const matchId = getMatchId(each);
                                  if (matchId) {
                                    handleOpenMatchCancel(matchId);
                                  } else {
                                    console.error('No matchId found for mentee:', each); // Debugging: Log if no matchId is found
                                  }
                                }}
                              >
                                <DeleteIcon />
                                <Typography color="primary">Cancel match</Typography>
                              </Box>
                            </Popover>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} height={"300px"}>
                        <Box textAlign={'center'}>
                          <Typography variant='h5' mb={2}>You've arrived!</Typography>
                          <Typography mb={2} variant='h5' fontWeight={'400'}>You will be connected with a mentee very shortly.</Typography>
                          <Typography mb={2} variant='h5' fontWeight={'400'}>In the meantime, take a look at your 'Getting Started' materials.</Typography>
                          <Typography mb={2} variant='h5' fontWeight={'400'}>Furthermore, if you just can't wait, feel free to begin reaching out to mentees yourself via your 'Network'.</Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {/* Getting Started For mobile */}
        {forMobile && isMasterclass && (
          <Grid container spacing={2} pt={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <HelpfulTips loader={loader} />
            </Grid>
          </Grid>
        )}

        {/* Recommended Articles */}
        {/* <Box mt={2}>
          <RecommendedArticle
            direction="row"
            place="mentorHome"
            // data={[]}
            handleArticleClick={(id) => console.log('Clicked on article with ID:', id)}
          />
        </Box> */}
      </Box>

      {/* Group of Peers Modal */}
      {isPeersPopupOpen && (
        <UserPeersPopup
          open={isPeersPopupOpen}
          onClose={() => handlePeersPopup(false)}
          peersList={peersList}
          onProfileClick={(mid) => moveToUsercommunityProfile(mid)}
          onChatClick={(each) => navigateMember(each)}
          onCalendarClick={(each) => handleCalendarOpen(each)}
          communityMembers={communityMembers} // Pass the communityMembers prop here
          navigate={navigate} // Pass the navigate prop here
        />
      )}

      {/* Request Match dialog */}
      <RequestMatch openRequestMatch={openRequestMatch} handleRequestMatch={handleRequestMatch} />

      {/* Request Match Reject dialog */}
      <RequestMatchReject
        openRequestMatchReject={openRequestMatchReject}
        handleRequestMatchReject={handleRequestMatchReject}
        requestId={requestId}
        groupId={groupId}
        mentorId={mentorId}
        menteeId={menteeId}
        doReload={doReload}
        commId={commId}
      />

      {/* Notes for Mallory Capoferri */}
      {/* <UserNotesPopup
        open={openUserNotes}
        handleOpen={handleOpenUserNotes}
      /> */}

      {/* Objective Modal */}
      <ObjectiveModal
        open={isObjectiveModalOpen}
        title={objective.objective}
        onClose={handleCloseObjectiveModal}
        onSubmit={(data: { name: string; progress: number }) => {
          return Promise.resolve();
        }}
        description={objective.description}
        status={objective.status}
        objId={objective._id}
        notes={objective.notes}
        mentorsList={objective?.mentors?.filter((each: any) => each.userId === objUserId)}
        myMenteesList={selectedMentee}
      />

      {/* Cancel Match Modal */}
      <MatchCancelModal
        openMatchCancel={openMatchCancel}
        handleMatchCancel={handleCloseMatchCancel} // Close modal on cancel
        confirmMatchId={selectedMatchId}
      />

      {/* Calendar Modal */}
      <CallBookingModal
        title={objective.objective}
        handleCloseModal={handleCalendarClose}
        openFlag={openCalendarModal}
        chatProfileName={displayName}
        threadParticipantsUsers={participantUser}
        chatType="Chats"
        pageName="Mentor"
        bookedCallDetails={bookedCallDetails}
      />

      {/* Task Modal */}
      <TaskModal
        open={openTask}
        onClose={() => setOpenTask(false)}
        selectedMenteeData={selectedTaskDetails}
      />
    </>
  );
};

export default Mentor;
