/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Dialog,
  Divider,
  Typography,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import MentorDetails from './MentorDetails';
import { ExpandMore } from '@mui/icons-material';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import { ChatIcon, VideoCallIcon } from '../../../../utils/Icons';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';
import { API } from '../../../../api';
import { getUserDetails } from '../../../../utils/orgName';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../../queries/queryKeys';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import CallBookingModal from '../CallBookingModal';
import ChatWithMeDialog from '../chat/ChatWithMeDialog';
import { useAppSelector } from '../../../../redux/hooks';
import { getAcsUserByCommunicationId } from '../../../../routes/helpers'; 
import _ from 'lodash';

interface Mentor {
  mid: string;
  displayName: string;
  headshot: string;
  position?: string;
}

interface RecruitNewMentorModalProps {
  title?: string;
  open: boolean;
  onClose: () => void;
  mentorsList: Mentor[];
  onProfileClick: (mid: string) => void;
  onChatClick: (mentor: Mentor) => void;
  onCalendarClick: (mentor: Mentor) => void;
  objId: string;
  grpId: string;
}

const RecruitNewMentor: React.FC<RecruitNewMentorModalProps> = ({
  title,
  open,
  onClose,
  mentorsList,
  onProfileClick,
  onChatClick,
  onCalendarClick,
  objId,
  grpId
}) => {
  const [expandedIndex, setExpandedIndex] = useState(-1); // Default to the first accordion
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>({});
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [participantUser, setParticipantUser] = useState<any[]>([]); // Specify the type as an array of any
  const [bookedCallDetails, setBookedCallDetails] = useState<any>('');
  const [openChatDialog, setOpenChatDialog] = useState(false);
  const [selectedMentor, setSelectedMentor] = useState<any>(null); 
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  const acsCommunityUsers = useAppSelector((state) => state.acsCommunityUsers.data);
  const acsToken = useAppSelector((state) => state.acsToken.data.token);
  const handleAccordionChange = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedIndex(isExpanded ? index : -1); // Collapse all if not expanded
  };
  const { orgId } = getUserDetails();
  const { userId } = useProfileDetails();
  const queryClient = useQueryClient();
  const handleCalendarOpen = (each: any) => {
    setDisplayName(each?.name); // Set the display name
    setParticipantUser([each]); // Set participant user(s)
    setBookedCallDetails(''); // Set booked call details (if needed)
    setOpenCalendarModal(true); // Open the calendar modal
  };

  const handleCalendarClose = () => {
    setDisplayName('');
    setParticipantUser([]); // Reset the participantUser state to an empty array
    setBookedCallDetails('');
    setOpenCalendarModal(false);
  };

  const handleChatIconClick = (mentor: any) => {
    setSelectedMentor(mentor);
    setOpenChatDialog(true);
  };

  const handleSendMessage = (content: string) => {
    if (selectedMentor && selectedMentor.communicationId) {
      sendMessage(content, selectedMentor.communicationId);
    } else {
      toast.error('Message failed to send');
    }
  };

  const updateMatch = async (mentor: any) => {
    try {
      const response1 = await API.assignAMatchByObjective({
        orgId: orgId,
        grpId: grpId,
        data: {
          mentorId: mentor.userId,
          menteeId: userId,
          // searchQuery
          assigneeRole: 'Mentor',
          objectiveId: objId
        }
      });
      // const response = await API.updateObjectiveMentors({
      //   objId,
      //   grpId,
      //   orgId,
      //   mentors: [
      //     {
      //       userId: mentor.userId,
      //       bio: mentor.bio,
      //       mid: mentor.mid,
      //       image: mentor.image,
      //       name: mentor.name,
      //       communicationId: mentor.communicationId
      //     }
      //   ]
      // });
      if (response1.status === 200) {
        queryClient.fetchQuery({
          queryKey: [queryKeys.GET_LEARNING_OBJS, grpId]
        });
        onClose();
      }
    } catch (error) {
      toast.error('Failed to update mentor');
    }
  };

  const getMentors = async () => {
    setLoading(true);
    try {
      const response = await API.getObjectiveMentors({ objId, grpId, orgId });
      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      toast.error('Failed to fetch recommended mentors ');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getMentors();
  }, [open]);
  const sendMessage = async (content: string, communicationUserId: string) => {
    const contentMessage = '<p>' + content + '</p>';
    if (!communicationUserId) {
      return;
    }
    const file = '';
    let acsChannelParticipants;
    const contentId = new Date().getTime();
    // Extract the communicationUserId values using map
    const channel = personalThreadsArr?.find((each: { displayNames: string | any[] }) =>
      each?.displayNames?.includes(communicationUserId)
    );
    let threadId = '';
    if (channel) {
      // thread exists
      threadId = _.get(channel, 'id');
    } else {
      const participant = getAcsUserByCommunicationId(acsCommunityUsers, communicationUserId);
      // create thread
      const { orgId } = getUserDetails();
      const modifiedValues = {
        participants: [
          {
            ...participant,
            displayName: _.get(participant, 'id.communicationUserId', ''),
            id: {
              communicationUserId: _.get(participant, 'id.communicationUserId', '')
            }
          }
        ],
        topic: '#personal',
        azureToken: acsToken
      };
      try {
        const { status, data } = await API.createACSThread(acsToken, modifiedValues, orgId);
        if (status === 200) {
          threadId = data?.threadId;
        }
      } catch (e) {
        console.error('Error creating thread:', e);
        return;
      }
    }
    try {
      const { status, data } = await API.getACSThreadParticipants(acsToken, threadId);
      if (status === 200) {
        acsChannelParticipants = data;
      }
    } catch (e) {
      console.error('Error fetching thread participants:', e);
    }
    const displayNames = acsChannelParticipants.map((participant: any) => participant.id.communicationUserId);

    try {
      const topic = '#personal';
      const { status } = await API.sendMessageToACSThread(
        acsToken,
        threadId,
        contentMessage,
        file,
        contentId,
        topic,
        displayNames
      );
      if (status === 200) {
        console.log('Message sent successfully');
        setOpenChatDialog(false); // Close the dialog after sending the message
      }
    } catch (e) {
      console.error('Error sending message:', e);
    }
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose} PaperProps={{ sx: { height: '100%' } }}>
        {/* Modal Header */}
        <CommonModalHeader title={'Recruit New Mentor'} handleCloseModal={onClose} />
        {/* Modal Content */}
        <DialogContent>
          <Typography variant="h6">Your Suggested Mentors</Typography>
          {/* <Typography fontStyle={'italic'} color={'primary'} fontSize={'12px'}>Mentors will appear as an your Objective Mentor once you schedule a meeting with them</Typography> */}
          {data?.map((mentor: any, index: number) => (
            <React.Fragment key={mentor.mid}>
              {/* Divider */}
              {index > 0 && <Divider />}
              <Accordion
                sx={{
                  '&.Mui-expanded': {
                    margin: '0'
                  }
                }}
                expanded={expandedIndex === index}
                onChange={handleAccordionChange(index)}
              >
                <AccordionSummary
                  sx={{ padding: 0, margin: 0 }}
                  id={`mentor-header-${mentor.mid}`}
                  aria-controls={`mentor-details-${mentor.mid}`}
                  expandIcon={<ExpandMore sx={{ color: '#0071A9' }} />}
                >
                  <Box gap="8px" width="100%" display="flex" alignItems="center" justifyContent="space-between">
                    {/* Mentor Info */}
                    <Box>
                      <Box
                        flex={1}
                        gap="10px"
                        display="flex"
                        overflow="hidden"
                        alignItems="center"
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          updateMatch(mentor);
                        }}
                      >
                        <ChatAvatarComponent
                          width="48px"
                          height="48px"
                          type="noStatus"
                          image={mentor?.image}
                          firstLetter={mentor?.name?.slice(0, 1)}
                        />
                        <Box flex={1} overflow={'hidden'}>
                          <Typography variant="h5" noWrap>
                            {mentor.name}
                          </Typography>
                          <Typography variant="body1" noWrap>
                            {mentor?.bio.workHistory?.role || 'Mentor'}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="body1" noWrap ml={6}>
                        I can help you with...
                      </Typography>
                    </Box>
                    {/* Action Icons */}
                    <Box
                      gap={1}
                      display={'flex'}
                      alignItems={'center'}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <ChatIcon
                        size={21}
                        onClick={() => {
                          handleChatIconClick(mentor);
                        }}
                      />
                      <VideoCallIcon
                        size={21}
                        onClick={() => {
                          handleCalendarOpen(mentor);
                        }}
                      />
                    </Box>
                  </Box>
                </AccordionSummary>
                {/* Mentor Details */}
                <AccordionDetails
                  sx={{
                    padding: '0',
                    paddingBottom: index === mentorsList.length - 1 ? '0' : '20px'
                  }}
                >
                  <MentorDetails data={mentor} />
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          ))}
        </DialogContent>
      </Dialog>

      {selectedMentor && (
        <ChatWithMeDialog
          openChatWithMe={openChatDialog}
          handleClickChatWithMe={(flag) => setOpenChatDialog(flag)}
          onSendMessage={handleSendMessage}
          data={selectedMentor ? [selectedMentor] : []}
        />
      )}

      <CallBookingModal
        title={title}
        handleCloseModal={handleCalendarClose}
        openFlag={openCalendarModal}
        chatProfileName={displayName}
        threadParticipantsUsers={participantUser}
        chatType="Chats"
        pageName="Mentor"
        bookedCallDetails={bookedCallDetails}
      />
    </>
  );
};

export default RecruitNewMentor;
