import React, { useState } from 'react';
import _ from 'lodash';
import { API } from '../../../../api';
import { Box, Link, Typography } from '@mui/material';
import MySkeleton from '../../../../components/Skeleton';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { navigateToChat } from '../NavigateToChat';
import CallBookingModal from '../CallBookingModal';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import { getDisplayName, GroupBasedRoleFormatter } from '../../components/common';
import { ChatIcon, VideoCallIcon } from '../../../../utils/Icons';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';

interface MyMentorCardProps {
  mentor: any;
  loading?: boolean;
  myRequestedMentorList?: any;
  doReload?: () => void;
}

const MentorCard: React.FC<MyMentorCardProps> = ({
  mentor,
  loading,
  myRequestedMentorList,
  doReload
}: MyMentorCardProps) => {
  const bookedCallDetails: any = '';
  const [openCalendarModalVal, setCalendarOpen] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [participantUser, setParticipantUser] = useState([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  const communityMembers = useAppSelector((state) => state.acsCommunityUsers.data);
  const commList = useAppSelector((state) => state.getCommunityMembers.data);
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const groupId = selectedGroupId.id || '';

  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor'); // Mentor
  const mentorRoleTextLowerCase = mentorRoleText.toLowerCase(); // mentor
  const { state } = useLocation();

  const moveToUsercommunityProfile = (userId: string) => {
    const mem = communityMembers.find((item: { userId: string }) => item.userId === userId);
    const modifiedMem = { ...mem, id: _.get(mem, 'id.coId', '') };
    const allCommunityMembersList = communityMembers;
    if (mem) {
      navigate('/app/communitymember', {
        state: { mem: modifiedMem, allCommunityMembersList: commList }
      });
    }
  };

  const handleNavigateToChat = (member: any) => {
    const listedInCommunity = communityMembers?.find((each) => each?.userId === _.get(member, 'userId', ''));
    let isInCommunity = false;
    if (listedInCommunity) {
      isInCommunity = true;
    }
    navigateToChat(member?.communicationId, isInCommunity, personalThreadsArr, navigate, dispatch);
  };

  /** Calendar modal */
  const handleCalendarOpen = (member: any) => {
    setDisplayName(member.displayName);
    setParticipantUser(member);
    setCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setDisplayName('');
    setParticipantUser([]);
    setCalendarOpen(false);
  };

  const imgSize = '56px'; //forMobile ? '56px' : '90px'

  return (
    <>
      <Box
        display="flex"
        flexDirection={forMobile ? 'row' : 'column'}
        gap="8px"
        width={forMobile ? '100%' : '320px'}
        minWidth={forMobile ? '100%' : '320px'}
      >
        <Box
          flex={forMobile ? 1 : ''}
          display="flex"
          gap={1.5}
          alignItems='flex-start'
        // width={forMobile ? 'calc(100% - 56px)' : '100%'}
        >
          <Box onClick={() => moveToUsercommunityProfile(mentor?.userId)}>
            <ChatAvatarComponent
              image={mentor?.headshot}
              type="noStatus"
              firstLetter={getDisplayName(mentor).charAt(0)}
              width={imgSize}
              height={imgSize}
              cursor="pointer"
              loading={loading}
            />
          </Box>
          <Box flex={1}>
            {/* Mobile else Desktop */}
            <Box className="cursor-pointer" width="100%">
              <Box display="flex" gap="8px" alignItems="center" justifyContent="space-between">
                <Typography variant="h5" noWrap onClick={() => moveToUsercommunityProfile(mentor?.userId)}>
                  {loading ? <MySkeleton width={forMobile ? 100 : 150} /> : getDisplayName(mentor)}
                </Typography>
              </Box>
              <Typography className='lineClamp-2'>
                {loading ? (
                  <MySkeleton width={150} />
                ) : (
                  <>
                    {mentor?.bio?.education?.major?.concat(',') || mentor?.bio?.role?.concat(', ')}
                    {mentor?.bio?.education?.university || mentor?.bio?.companyName}
                    {/* Lorem ipsum dolor sit amet consectetur adipisicing elit orem ipsum dolor sit amet
                    consectetur adipisicing elitorem ipsum dolor sit amet consectetur adipisicing elit. */}
                  </>
                )}
              </Typography>

              {/* <Typography mt={1} fontStyle={'italic'} className='lineClamp-2'>
                Litttle bio we will give here to cover space may be from her/his profile first 1 or 2 line then Litttle bio we will give here to cover space may be from her/his profile firs
              </Typography> */}
            </Box>
          </Box>
        </Box>

        {/* Only For Mobile else dasktop */}
        {forMobile ? (
          <Box display="flex" alignItems="center" gap="8px">
            {loading ? (
              <>
                <MySkeleton variant="circular" height={25} width={25} />
                <MySkeleton variant="rounded" height={25} width={25} />
              </>
            ) : (
              <>
                <ChatIcon
                  size={21}
                  onClick={() => {
                    handleNavigateToChat(mentor);
                  }}
                />
                <VideoCallIcon
                  size={21}
                  onClick={(event) => {
                    handleCalendarOpen(mentor);
                  }}
                />
              </>
            )}
          </Box>
        ) : (
          <Box ml={imgSize}>
            <Box display="flex" gap="16px" alignItems="center" mt={1}>
              {loading ? (
                <>
                  <MySkeleton variant="rounded" height={30} width={120} />
                  <MySkeleton variant="rounded" height={30} width={120} />
                </>
              ) : (
                <>
                  <Box className="myMentorNewBtn" onClick={() => handleNavigateToChat(mentor)}>
                    <ChatIcon size={21} />
                    <Link fontWeight={'600'}>Message</Link>
                  </Box>
                  <Box className="myMentorNewBtn" onClick={() => handleCalendarOpen(mentor)}>
                    <VideoCallIcon size={21} />
                    <Link fontWeight={'600'}>Schedule</Link>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>

      <CallBookingModal
        handleCloseModal={handleCalendarClose}
        openFlag={openCalendarModalVal}
        chatProfileName={displayName}
        threadParticipantsUsers={[participantUser]}
        chatType="Chats"
        pageName="Mentor"
        bookedCallDetails={bookedCallDetails}
      />
    </>
  );
};

export default MentorCard;
