import { useEffect, useState } from 'react';
import {
  Grid,
  Radio,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  DialogContent,
  DialogActions,
  FormHelperText,
  FormControlLabel
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { sortedCountries } from './common';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUserDetails } from '../../../utils/orgName';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import { userActions } from '../../../redux/slices/user-profile/userProfieSlice';

const CompanyInformation = () => {
  const { ftue, settings } = useProfileDetails();
  const dispatch = useAppDispatch();
  const { userId, orgId } = getUserDetails();
  const [ftueLoading, setFtueLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getLocalStorageKey = (key: string) => `companyInfo_${userId}_${key}`;
  const getInitialState = (key: string, defaultValue: any) => {
    const localStorageKey = getLocalStorageKey(key);
    const savedValue = localStorage.getItem(localStorageKey);
    return savedValue ? JSON.parse(savedValue) : defaultValue;
  };

  const [missionStatement, setMissionStatement] = useState(getInitialState('missionStatement', ''));
  const [fundingStage, setFundingStage] = useState(getInitialState('fundingStage', ''));
  const [markets, setMarkets] = useState<string[]>(getInitialState('markets', []));
  const [hiring, setHiring] = useState(getInitialState('hiring', ''));
  const [companySize, setCompanySize] = useState(getInitialState('companySize', ''));
  const [businessModel, setBusinessModel] = useState<string[]>(getInitialState('businessModel', []));
  const [customers, setCustomers] = useState(getInitialState('customers', ''));

  useEffect(() => {
    localStorage.setItem(getLocalStorageKey('missionStatement'), JSON.stringify(missionStatement));
  }, [missionStatement, userId]);

  useEffect(() => {
    localStorage.setItem(getLocalStorageKey('fundingStage'), JSON.stringify(fundingStage));
  }, [fundingStage, userId]);

  useEffect(() => {
    localStorage.setItem(getLocalStorageKey('markets'), JSON.stringify(markets));
  }, [markets, userId]);

  useEffect(() => {
    localStorage.setItem(getLocalStorageKey('hiring'), JSON.stringify(hiring));
  }, [hiring, userId]);

  useEffect(() => {
    localStorage.setItem(getLocalStorageKey('companySize'), JSON.stringify(companySize));
  }, [companySize, userId]);

  useEffect(() => {
    localStorage.setItem(getLocalStorageKey('businessModel'), JSON.stringify(businessModel));
  }, [businessModel, userId]);

  useEffect(() => {
    localStorage.setItem(getLocalStorageKey('customers'), JSON.stringify(customers));
  }, [customers, userId]);

  const fundingStages = ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Series C'];
  const market = ['EMEA', 'North America', 'LATAM', 'Asia-Pacific'];
  const companySizes = ['0-5', '6-10', '11-20', '21-50'];
  const businessModels = [
    'Solutions Provider',
    'Manufacturing',
    'Hardware',
    'SaaS',
    'Service Provider',
    'E-Commerce',
    'Social Network',
    'Other'
  ];
  const customerList = ['B2C', 'B2B', 'B2B2C'];

  const createValidationSchema = () => {
    const baseSchema = {
      missionStatement:yup.string(),
      fundingStage: yup.string(),
      markets:yup.array(),
      hiring: settings.companyInfo ? yup.string().required('Hiring Status is required') : yup.string(),
      companySize: settings.companyInfo ? yup.string().required('Company Size is required') : yup.string(),
      businessModel: settings.companyInfo
        ? yup.array().min(1, 'At least one Business Model is required').required('Business Model is required')
        : yup.array(),
      customers: settings.companyInfo ? yup.string().required('Customer type is required') : yup.string()
    };
    return yup.object().shape(baseSchema);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(createValidationSchema()),
    mode: 'onBlur',
    defaultValues: {
      missionStatement,
      fundingStage,
      markets,
      hiring,
      companySize,
      businessModel,
      customers
    }
  });
  useEffect(() => {
    reset({
      missionStatement,
      fundingStage,
      markets,
      hiring,
      companySize,
      businessModel,
      customers
    });
  }, [missionStatement, fundingStage, markets, hiring, companySize, businessModel, customers, reset]);
  // Popup count logic
  const popuplength = useAppSelector((state) => state.popupsCount.popuplength);
  const count = useAppSelector((state) => state.popupsCount.count);
  const nextPopUpOrNot = popuplength - count;

  const updateFtue = async () => {
    setFtueLoading(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      if (response.status === 200 && response.statusText === 'OK') {
        const keys = [
          'missionStatement',
          'fundingStage',
          'markets',
          'hiring',
          'companySize',
          'businessModel',
          'customers'
        ];

        keys.forEach((key) => {
          localStorage.removeItem(getLocalStorageKey(key));
        });

        dispatch(userActions.userFtueUpdate(0));
        setFtueLoading(false);
      }
    } catch (e) {
      toast.error(`Company details failed to update`);
      setFtueLoading(false);
    }
  };

  const onSubmit = async () => {
    if (settings.companyInfo) {
      const requiredFields = [
        hiring,
        companySize,
        businessModel.length > 0,
        customers
      ];

      if (requiredFields.some((field) => !field)) {
        toast.error('Please fill in all required fields');
        return;
      }
    }

    setIsSubmitting(true);

    try {
      const companyInfo = {
        missionStatement,
        fundingStage,
        markets,
        hiring,
        companySize,
        businessModel,
        customers
      };

      const response = await API.saveCompanyInfo({
        orgId,
        data: companyInfo
      });

      if (response.status === 200) {
        if (ftue && nextPopUpOrNot !== 1) {
          dispatch(popupCountActions.increment(0));
        }
        if (nextPopUpOrNot === 1) {
          await updateFtue();
        }
      } else {
        toast.error('Failed to save company information');
      }
    } catch (error) {
      console.error('Error saving company information:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Dialog actions
  const getDialogActions = () => {
    return (
      <DialogActions>
        {ftue && (
          <LoadingButton
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => dispatch(popupCountActions.decrement(0))}
          >
            Back
          </LoadingButton>
        )}

        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          loading={isSubmitting || ftueLoading}
          onClick={handleSubmit(onSubmit)}
          id="companySubmit"
        >
          {nextPopUpOrNot === 1 ? 'Finish' : nextPopUpOrNot > 1 ? 'Next' : 'Save'}
        </LoadingButton>
      </DialogActions>
    );
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Company Mission */}
          <Grid item xs={12}>
            <Controller
              name="missionStatement"
              control={control}
              render={({ field }) => (
                <>
                  <InputLabel>Company Mission</InputLabel>
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Company Mission"
                    value={missionStatement}
                    onChange={(e) => {
                      field.onChange(e);
                      setMissionStatement(e.target.value);
                    }}
                    error={!!errors.missionStatement}
                    helperText={errors.missionStatement?.message as string}
                  />
                </>
              )}
            />
          </Grid>

          {/* Funding Stage */}
          <Grid item xs={12}>
            <Controller
              name="fundingStage"
              control={control}
              render={({ field }) => (
                <>
                  <InputLabel>Funding Stage</InputLabel>
                  <FormControl fullWidth size="small" error={!!errors.fundingStage}>
                    <Select
                      {...field}
                      value={fundingStage}
                      onChange={(e) => {
                        field.onChange(e);
                        setFundingStage(e.target.value as string);
                      }}
                      fullWidth
                      displayEmpty
                      renderValue={(selected) =>
                        selected ? selected : <Typography color="#ABB5BE">Select</Typography>
                      }
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {fundingStages.map((stage) => (
                        <MenuItem key={stage} value={stage}>
                          {stage}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.fundingStage && <FormHelperText>{errors.fundingStage.message as string}</FormHelperText>}
                  </FormControl>
                </>
              )}
            />
          </Grid>

          {/* Markets */}
          <Grid item xs={12}>
            <Controller
              name="markets"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Markets</InputLabel>
                  <Autocomplete
                    multiple
                    options={market}
                    value={value?.length ? value : markets} 
                    onChange={(event, newValue) => {
                      onChange(newValue);
                      setMarkets(newValue);
                    }}
                    
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="You can select one or more options"
                        error={!!errors.markets}
                        helperText={errors.markets?.message as string}
                      />
                    )}
                  />
                </>
              )}
            />
          </Grid>

          {/* Hiring */}
          <Grid item xs={12}>
            <Controller
              name="hiring"
              control={control}
              render={({ field }) => (
                <>
                  <InputLabel>Hiring Status {settings.companyInfo ? '*' : ''}</InputLabel>
                  <RadioGroup
                    {...field}
                    row
                    value={hiring}
                    onChange={(e) => {
                      field.onChange(e);
                      setHiring(e.target.value);
                    }}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                  {errors.hiring && <FormHelperText error>{errors.hiring.message as string}</FormHelperText>}
                </>
              )}
            />
          </Grid>

          {/* Company Size */}
          <Grid item xs={12}>
            <Controller
              name="companySize"
              control={control}
              render={({ field }) => (
                <>
                  <InputLabel>Company Size {settings.companyInfo ? '*' : ''}</InputLabel>
                  <FormControl fullWidth error={!!errors.companySize}>
                    <Select
                      {...field}
                      value={companySize}
                      onChange={(e) => {
                        field.onChange(e);
                        setCompanySize(e.target.value as string);
                      }}
                      size="small"
                      fullWidth
                      displayEmpty
                      renderValue={(selected) =>
                        selected ? selected : <Typography color="#ABB5BE">Select</Typography>
                      }
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {companySizes.map((size) => (
                        <MenuItem key={size} value={size}>
                          {size}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.companySize && <FormHelperText>{errors.companySize.message as string}</FormHelperText>}
                  </FormControl>
                </>
              )}
            />
          </Grid>

          {/* Business Model */}
          <Grid item xs={12}>
            <Controller
              name="businessModel"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Business Model {settings.companyInfo ? '*' : ''}</InputLabel>
                  <Autocomplete
                    multiple
                    options={businessModels}
                    value={businessModel}
                    onChange={(event, newValue) => {
                      onChange(newValue);
                      setBusinessModel(newValue);
                    }}
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="You can select one or more options"
                        error={!!errors.businessModel}
                        helperText={errors.businessModel?.message as string}
                      />
                    )}
                  />
                </>
              )}
            />
          </Grid>

          {/* Customers */}
          <Grid item xs={12}>
            <Controller
              name="customers"
              control={control}
              render={({ field }) => (
                <>
                  <InputLabel>Customers {settings.companyInfo ? '*' : ''}</InputLabel>
                  <FormControl fullWidth size="small" error={!!errors.customers}>
                    <Select
                      {...field}
                      value={customers}
                      onChange={(e) => {
                        field.onChange(e);
                        setCustomers(e.target.value as string);
                      }}
                      fullWidth
                      displayEmpty
                      renderValue={(selected) =>
                        selected ? selected : <Typography color="#ABB5BE">Select</Typography>
                      }
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {customerList.map((customer) => (
                        <MenuItem key={customer} value={customer}>
                          {customer}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.customers && <FormHelperText>{errors.customers.message as string}</FormHelperText>}
                  </FormControl>
                </>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {/* Action */}
      {getDialogActions()}
    </>
  );
};

export default CompanyInformation;
