import {
  Box,
  Dialog,
  TextField,
  InputLabel,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState, useEffect } from 'react';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';
import { inputCharacterCount, showCharacterCountWarning } from '../../components/common';
import { toast } from 'react-toastify'; 
import { API } from '../../../../api';
import { useAppSelector } from '../../../../redux/hooks';
import { getUserDetails } from '../../../../utils/orgName';

interface MatchCancelModalProps {
  openMatchCancel: boolean;
  handleMatchCancel: (flag: boolean) => void;
  confirmMatchId: string;
}

const MatchCancelModal: React.FC<MatchCancelModalProps> = ({
  openMatchCancel,
  handleMatchCancel,
  confirmMatchId
}) => {
  const maxCharacterCount = 200;
  const [loading, setLoading] = useState(false);
  const [cancelFieldValue, setCancelFieldValue] = useState<string>('');
  const [textValueLength, setTextValueLength] = useState<number>(0);
  const totalCharacters = inputCharacterCount(cancelFieldValue, maxCharacterCount);
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const groupId = selectedGroupId.id || '';
  const { orgId } = getUserDetails();
  useEffect(() => {
    if (openMatchCancel) {
      setCancelFieldValue('');
      setTextValueLength(0);
    }
  }, [openMatchCancel]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    showCharacterCountWarning(value, maxCharacterCount);
    setCancelFieldValue(value);
    setTextValueLength(value.length);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setLoading(false);
     try {
          const response = await API.removeMatch({
            orgId: orgId,
            grpId: groupId,
            confirmMatchId: confirmMatchId
          });
          if (response?.status === 200) {
            toast.success('Match ended successfully.');
          }
        } catch (error) {
          toast.error('Match failed to end');
        }
      ;
    console.log('Match canceled successfully!', cancelFieldValue);  
    handleMatchCancel(false);
  };

  return (
    <Dialog
      open={openMatchCancel}
      onClose={() => handleMatchCancel(false)}
      scroll="body"
    >
      <CommonModalHeader
        title="Match cancel"
        handleCloseModal={() => handleMatchCancel(false)}
      />
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <InputLabel>Reason for cancellation</InputLabel>
          <InputLabel>{`${totalCharacters?.textValueLength}/${totalCharacters?.maxCharacterCount}`}</InputLabel>
        </Box>
        <TextField
          multiline
          InputProps={{
            inputProps: {
              maxLength: totalCharacters?.maxCharacterCount
            },
          }}
          value={cancelFieldValue}
          minRows={8}
          onChange={handleInputChange}
          placeholder="Please provide a reason for your Cancelation"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default MatchCancelModal;
