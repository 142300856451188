import React from 'react';
import CardSlider from './CardSlider';
import { Box, Typography } from '@mui/material';

interface TaskLevel {
  levels: Task[];
}

interface TaskCardSectionProps {
  taskData?: TaskLevel[];
  loading: boolean;
  forMobile?: boolean;
  objData: any;
}

const TaskCardSection: React.FC<TaskCardSectionProps> = ({ taskData, loading, forMobile = false, objData }) => {
  /** Flatten the taskData */
  const flattenedTasks = taskData?.flatMap((eachLevel) => eachLevel.levels) || [];
  const hasTasks = flattenedTasks?.length > 0;
  const hasObjs = objData?.data?.length > 0;

  return (
    <Box>
      {loading || hasTasks || objData.loading || hasObjs ? (
        <CardSlider
          taskData={loading ? [] : flattenedTasks}
          forMobile={forMobile}
          loading={loading}
          objData={objData?.data}
        />
      ) : (
        <Box p={2}>
          <Typography textAlign="center">No lessons assigned</Typography>
        </Box>
      )}
    </Box>
  );
};

export default TaskCardSection;
