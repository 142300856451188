import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ROLES from '../../../../../utils/roles';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { checkSession } from '../../../../../utils/checkSeesion';
import useProfileDetails from '../../../../../hooks/useProfileDetails';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { lockIcon } from '../../../components/common';
import { useAppSelector } from '../../../../../redux/hooks';
import { CommonStyle } from '../../../../../utils/muiStyle';
interface Props {
  activeTab: string;
  setActiveTab: any;
  locked?: boolean;
}

export default function ProgramSettingsSideBar({ activeTab, setActiveTab, locked }: Props) {
  const role = localStorage.getItem('role') || '';
  const { admins } = useProfileDetails();
  const disabled = false;
  const groupData = useAppSelector((state) => state.groupDetails);
  const styles = CommonStyle({});
  const lockOpacity = locked ? styles.boxDisabledOpacity : '';
  const sideBarArray = [
    { name: 'Basic Configurations', id: 'basicSettings' },
    { name: 'Masterclass Design', id: 'goalSettings' },
    { name: 'Library Resources', id: 'contentSettings' },
    { name: 'Help Center Settings', id: 'helpSettings' }
    // { name: "Chat / Directory Settings", id: "chatSettings" },
    // { name: "Communication Settings", id: "communicationSettings" },
  ];

  useEffect(() => {
    if (checkSession()) {
      window.location.replace('/');
    }
  }, [activeTab]);
  const isMasterclassLocked = groupData.data?.model === 'mentorship';
  return (
    <Box height={'100%'} bgcolor={'#FFFFFF'} borderRadius={'8px'} border={'1px solid #EFF0F4'}>
      <Box aria-label="secondary mailbox folders">
        <Box className="listGroup">
          <Box className="list-group">
            {sideBarArray.map((item) => {
              const isAdmin = [ROLES.platAdmin, ROLES.orgAdmin].includes(role) || admins.length > 0;
              const isGoalSettings = item.id === 'goalSettings';
              const isMasterclassDisabled = isGoalSettings && isMasterclassLocked;
              return (
                <Box
                  p={1}
                  gap={2}
                  border={0}
                  key={item.id}
                  display="flex"
                  alignItems="center"
                  borderRadius={'8px'}
                  className={'cursor-pointer'}
                  justifyContent={'space-between'}
                  onClick={() => !isMasterclassDisabled && setActiveTab(item.id)}
                  bgcolor={activeTab === item.id ? '#0071A9' : '#FFFFFF'}
                >
                  <Typography
                    variant={'h5'}
                    fontWeight={activeTab === item.id ? '600' : '400'}
                    color={activeTab === item.id ? '#FFFFFF' : 'primary'}
                    className={isMasterclassDisabled ? 'disabled-box-opacity-25' : ''}
                  >
                    {item.name}
                  </Typography>

                  {/*Check icon */}
                  {/* {disabled
                    ? <CheckCircleOutlineIcon fontSize="small" sx={{color:'#D9D9D9'}}/> 
                    : <CheckCircleIcon fontSize="small" sx={{ color: activeTab === item.id ? '#F9FAFC' : '#11895E'}} />
                  } */}
                  {isMasterclassDisabled && lockIcon({ title: 'This is not needed for the Mentorship model' })}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
