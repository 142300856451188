import React from 'react';
import { Box, Typography } from '@mui/material';

interface MentorViewBadgeProps {
    userName: string;
    place?: string;
}

const MentorViewBadge: React.FC<MentorViewBadgeProps> = ({ userName, place }) => {

    return (
        <Box
            bgcolor={'#262738'}
            m={place === 'Popup' ? 2 : 0}
            mb={place === 'Popup' ? 0 : 2}
            className={'br-8 p-20'}
        >
            <Typography variant="subtitle2" color={'#FFFFFF'} textAlign={'center'} lineHeight={'normal'}>
                You are now viewing the progress of “{userName}”.
            </Typography>
        </Box>
    );
};
export default MentorViewBadge;