import React from 'react';
import { Box, Grid, Stack, Divider, Typography } from '@mui/material';
import { dotDesign } from '../../components/common';

interface ICompanyItem {
  company_name?: string;
  location?: string;
  funding_stage?: string;
  company_size?: string;
  mission_statement?: string;
  current_challenges?: string;
  markets?: string[];
  business_models?: string[];
  customers?: string;
}

interface ICompanyDetailsProps {
  data?: any;
}

const CompanyDetails: React.FC<ICompanyDetailsProps> = ({ data = [] }) => {
  /** Render empty space for logo */
  const renderLogo = () => <Box width="49px" height="49px" minWidth="49px" />;

  /** Render items with ' | ' separator */
  const renderItemsWithPipe = (items: (string | undefined)[]) => {
    const filteredItems = items.filter((item) => item);
    return filteredItems.length > 0 ? filteredItems.join(' | ') : '';
  };

  /** Render items with dots */
  const renderItemsWithDots = (items?: string[]) =>
    items && items.length > 0
      ? items.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && dotDesign()} {item}
          </React.Fragment>
        ))
      : '';

  return (
    <Box py={2}>
      <Typography variant="h5" pb={2}>
        Company
      </Typography>

      {data?.missionStatement ? (
        <React.Fragment>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing="8px">
            <Box width="100%">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing="10px">
                    {/* Logo */}
                    {renderLogo()}

                    {/* Right Side */}
                    <Box width="calc(100% - 59px)">
                      {/* Company Name */}
                      {/* <Typography variant="h5">{data?.missionStatemnet}</Typography> */}
                      {/* Company Info (Location | Funding Stage | Company Size) */}
                      <Typography mt="4px">{renderItemsWithPipe([data?.fundingStage, data?.companySize])}</Typography>
                      {/* Mission Statement */}
                      <Typography mt="4px">{data?.missionStatement}</Typography>
                      {/* Current Challenges*/}
                      {/* <Typography mt="4px">{data.current_challenges}</Typography> */}
                      {/* Markets*/}
                      <Typography color="primary" mt="4px">
                        {renderItemsWithDots(data?.markets)}
                      </Typography>
                      {/* Business Models*/}
                      <Typography color="primary" mt="4px">
                        {renderItemsWithDots(data?.businessModel)}
                      </Typography>
                      {/* Customers */}
                      <Typography color="primary" mt="4px">
                        {data?.customers}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>

          {/* {index !== data.length - 1 && <Divider className="my-16 border-dashed" />} */}
        </React.Fragment>
      ) : (
        <Typography>No Company Found</Typography>
      )}
    </Box>
  );
};

export default CompanyDetails;
