import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import TagBox from '../../components/TagBox';
import ObjectiveModal from './ObjectiveModal';
import { useNavigate } from 'react-router-dom';
import { useModule } from '../../../../utils/useModule';
import { levelTypes } from '../../../../utils/constants';
import { getStatusColor, lockIcon } from '../../components/common';
import { Box, Link, Button, Typography } from '@mui/material';
import DescriptionModal from '../../components/DescriptionModal';
import { DisabledVideoCall, ToDoIcon } from '../../../../utils/Icons';
import CheckableTickIcon from '../../../../components/CheckableTickIcon';
import { useParams } from 'react-router-dom';

interface Task {
  id: string;
  _id: string;
  title: string;
  noteCount?: number;
  taskDescription: string;
  videoCallCount?: number;
  objectiveNumber?: number;
  totalObjectives?: number;
  status: 'todo' | 'inprogress' | 'completed';
  taskName: string;
  startDate: number;
  endDate: number;
}

interface IProps {
  task?: Task;
  levelName: string;
  levelId: string;
  borderColor?: string;
  trophyColor?: string;
  isLocked?: boolean;
  levelNumber?: number;
  levelDescription?: string;
  level: any;
}

const NewTaskCard: React.FC<IProps> = ({
  task,
  levelName,
  levelId,
  borderColor,
  trophyColor,
  isLocked,
  levelNumber,
  levelDescription,
  level
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const { isMentorship } = useModule();
  const status = task?.status || 'todo';
  const statusColor = borderColor === 'green' ? '#11895E' : '#0071A9';
  const noteCount = task?.noteCount ?? 0;
  const videoCount = task?.videoCallCount ?? 0;
  // const [isModalOpen, setModalOpen] = useState(false);
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
 const { taskId } = useParams() || '';

  /** Get Availability Date */
  function getAvailabilityDate(date?: string) {
    if (!date) return null; // Handle empty cases
    return (
      <Typography fontSize="10px" fontStyle="italic" color="red" whiteSpace="nowrap">
        {date}
      </Typography>
    );
  }

  const extractTextFromHTML = (html: any) => {
    // Create a temporary DOM element
    const doc = new DOMParser().parseFromString(html, 'text/html');

    // Remove all script and style elements
    const scripts = doc.getElementsByTagName('script');
    const styles = doc.getElementsByTagName('style');

    Array.from(scripts).forEach((script: any) => script.remove());
    Array.from(styles).forEach((style: any) => style.remove());

    // Remove all images
    const images = doc.getElementsByTagName('img');
    Array.from(images).forEach((img: any) => img.remove());

    // Get text content
    let text = doc.body.textContent || '';

    // Clean up whitespace
    text = text.replace(/\s+/g, ' ').trim();

    return text;
  };

  const textOnly = extractTextFromHTML(task?.taskDescription || '');

  const currentDate = Date.now() / 1000;
  const getLevelText = (level: any) => {
    if (level?.startDate?.type === levelTypes.availableSoon) {
      return 'soon';
    }
    return `on ${moment.unix(level?.startDate.value).format('DD/MM/YYYY')}`;
  };
  const isTaskAvailable = !task?.startDate || currentDate >= task.startDate;
  const handleNavigate = () => {
    if (isTaskAvailable) {
      navigate(`/app/home/mentee/level/${levelId}/task/${task?._id}`);
    }
  };

  function getAvailabilityMessage(startDate: any, endDate: any, type: string, task?: any) {
    let color = '';
    let text = '';
    if (!startDate && !endDate) {
      text = '';
    } else if (startDate && !endDate) {
      if (currentDate >= startDate) {
        text = '';
      } else {
        text = `${moment.unix(startDate).format('DD/MM/YYYY')} `;
      }
    } else if (currentDate < startDate) {
      text = `${moment.unix(startDate).format('DD/MM/YYYY')} `;
    } else if (currentDate >= startDate && currentDate <= endDate) {
      const timeLeft = endDate - currentDate;
      if (timeLeft <= 7 * 24 * 3600) {
        // 7 days in seconds
        color = 'orange';
      } else {
        color = '';
      }
      text = `${moment.unix(endDate).format('DD/MM/YYYY')}`;
    } else if (currentDate > endDate) {
      color = 'red';
      text = `${moment.unix(endDate).format('DD/MM/YYYY')}`;
    } else {
      text = '';
    }

    if (type === 'level') {
      return (
        <Typography fontSize={'12px'} fontStyle={'italic'} color={color}>
          {text}
        </Typography>
      );
    }

    return (
      <Typography
        color={taskId === task?._id ? '#FFFFFF' : color}
        fontSize={'12px'}
        lineHeight={'14px'}
        fontStyle={'italic'}
      >
        {text}
      </Typography>
    );
  }

  return (
    <>
      <Box key={task?.id} display="flex" className="taskCard" flexDirection="column" justifyContent="space-between">
        <Box display={'flex'} flexDirection={'column'} height="100%">
          <Box borderRadius="8px 8px 0 0" borderTop={`10px solid ${statusColor}`} />
          <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={'8px'}>
              <Typography noWrap color="secondary" variant="h6" flex={1}>
                {isMentorship ? `Objective ${task?.objectiveNumber} out of ${task?.totalObjectives}` : levelName}
              </Typography>
              {/* Level end date */}
              {getAvailabilityMessage(level?.startDate?.value, level?.endDate?.value, 'level')}
              {/* Show the check icon only for mentorship */}
              {!isMentorship && <CheckableTickIcon checked={trophyColor === 'green'} />}
            </Box>
            {/* Task name */}
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'} gap={'8px'}>
              <Typography
                noWrap
                variant="h5"
                flexShrink={1}
                className="inline-fill"
                fontStyle={task?.title ? '' : 'italic'}
                color={task?.title ? statusColor : 'secondary.light'}
              >
                {isMentorship ? task?.title || 'Untitled' : task?.taskName || <pre> </pre>}
              </Typography>
              {/* Lesson end date */}
              {getAvailabilityMessage(task?.startDate, task?.endDate, 'task', task)}
            </Box>
          </Box>

          {/* Render TagBox and other elements only for mentorship */}
          {isMentorship && (
            <>
              <TagBox
                mb={2}
                mt={1}
                mx={'auto'}
                fontSize="14px"
                color={statusColor}
                text={status === 'todo' ? 'To Do' : status === 'inprogress' ? 'In Progress' : 'Completed'}
              />

              <Box gap={2} display="flex" justifyContent="center" alignItems="center">
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="subtitle2" color="secondary">
                    {noteCount}
                  </Typography>
                  <ToDoIcon />
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="subtitle2" color="secondary">
                    {videoCount}
                  </Typography>
                  <DisabledVideoCall />
                </Box>
              </Box>
            </>
          )}

          {/* Description section */}
          <Box px={2}>
            {!isMentorship && isLocked ? (
              <Box textAlign={'center'} py={isLocked ? 4 : 0}>
                {lockIcon({ size: '40px', color: '#68717A' })}
                <Typography mt={1}>This level will be available {getLevelText(level)}</Typography>
                <Link
                  color={'info.main'}
                  fontStyle={'italic'}
                  onClick={() => {
                    setDescriptionModalOpen(true);
                  }}
                >
                  Read description of Level {levelNumber}
                </Link>
              </Box>
            ) : (
              <Typography
                // mx={2}
                pt={!isMentorship ? 0 : 2}
                height={'100%'}
                className={!isMentorship ? 'lineClamp-7' : 'lineClamp-3'}
              >
                {textOnly}
              </Typography>
            )}

            {!isMentorship && !isLocked && (
              <Link variant="h6" onClick={handleNavigate}>
                View more
              </Link>
            )}
          </Box>
        </Box>

        {/* Action Button */}
        {(isMentorship || !isLocked) && (
          <Box alignItems="flex-end" justifyContent="center" display="flex" p={2}>
            <Button
              variant="contained"
              className="w-100 fs-14"
              // onClick={handleOpenModal}
              onClick={handleNavigate}
              aria-label={`View ${task?.status === 'completed' ? 'Details' : 'Progress'}`}
              disabled={task?.startDate ? currentDate < task.startDate : false}
              sx={{
                backgroundColor: trophyColor === 'green' ? '#11895E' : 'info.main'
              }}
            >
              {isMentorship
                ? task?.status === 'completed'
                  ? 'View'
                  : 'Progress'
                : trophyColor === 'green'
                ? 'Lesson Complete'
                : 'Complete Lesson'}
            </Button>
          </Box>
        )}
      </Box>

      {/* Objective Modal */}
      {/* <ObjectiveModal
        open={isModalOpen}
        title="Objective Details"
        onClose={handleCloseModal}
        onSubmit={(data: { name: string; progress: number }) => {
          return Promise.resolve();
        }}
        status="todo"
        objId={''}
        description={task?.taskDescription}
        n
      /> */}

      {/* Description Modal */}
      <DescriptionModal
        open={descriptionModalOpen}
        onClose={() => setDescriptionModalOpen(false)}
        // title={`Level ${levelNumber} Description`}
        title={`${levelName}`}
        message={levelDescription}
      />
    </>
  );
};

export default NewTaskCard;
