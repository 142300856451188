/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, IOptionalFields } from '../../../api';

export interface IGRoupDetails {
  [x: string]: any;
  orgId: string;
  name: string;
  orgParentGroup: boolean;
  members: number;
  childGroups: string[];
  admins: number;
  mentors: number;
  hierarchy: string[];
  createdAt: number;
  createdBy: string;
  updatedBy: string;
  chatChannel: boolean;
  chatChannelId: string;
  grpId: string;
  logo: string;
  endDate: number;
  currency: string;
  topics: any;
  industries: any;
  includeTask: boolean;
  goalPublished: boolean;
  allowEditing: boolean;
  optionalFields?: IOptionalFields;
  matchesSettings: {
    matchRequest: boolean;
    maxMatchesPerMentee: number;
    maxMatchesPerMentor: number;
    sessionsPerMatch:number;
  };
  goals: {
    goalName: string;
    programKPIS: string;
    programType: string;
    templateId: string;
  };
  displayRoles: {
    mentee: string;
    mentor: string;
    admin: string;
  };
  community: {
    mentors: boolean;
    mentees: boolean;
    admins: boolean;
  };
  settingsUpdate: boolean;
  type: string;
}

const grpInitialData = {
  orgId: '',
  name: '',
  orgParentGroup: false,
  members: 0,
  childGroups: [],
  admins: 0,
  mentors: 0,
  hierarchy: [],
  createdAt: 0,
  createdBy: '',
  updatedBy: '',
  chatChannel: false,
  chatChannelId: '',
  grpId: '',
  logo: '',
  endDate: 0,
  currency: '$',
  topics: [],
  industries: [],
  includeTask: true,
  allowEditing: true,
  goalPublished: false,
  optionalFields: {
    gender: false,
    ethnicity: false,
    firstGenerationStudent: false,
    disabilityType: false,
    dob: false,
    country: false,
    workHistory: false,
    educationHistory: false,
    hobbiesInterests: false,
    headshot:false,
  },
  matchesSettings: {
    matchRequest: false,
    maxMatchesPerMentee: 1,
    maxMatchesPerMentor: 1,
    sessionsPerMatch:1,
  },
  goals: {
    goalName: '',
    programKPIS: '',
    programType: '',
    templateId: ''
  },
  displayRoles: {
    mentee: 'client',
    mentor: 'coach',
    admin: 'admin'
  },
  community: {
    mentors: true,
    mentees: true,
    admins: true
  },
  settingsUpdate: true,
  type: ''
};

interface IInitialState {
  loading: boolean;
  data: IGRoupDetails | undefined;
  error: boolean;
  errorText: string;
}

const initialState: IInitialState = {
  data: undefined,
  loading: false,
  error: false,
  errorText: ''
};

interface FetchGroupDetailsArgs {
  grpId: string;
  loc?: string;
  useCache?: boolean;
}

export const fetchGroupDetails = createAsyncThunk(
  'getGroupDetails',
  async ({ grpId, loc, useCache }: FetchGroupDetailsArgs) => {
    return API.getGroupDetails(grpId, loc, useCache).then((response) => response.data);
  }
);

const groupDetailsSlice = createSlice({
  name: 'group details',
  initialState,
  reducers: {
    updateGroupInfo: (state, action) => {
      const grpData = state.data ? state.data : grpInitialData;
      return { ...state, data: { ...grpData, ...action.payload } };
    },
    updateGroupData: (state, action) => {
      return { ...state, data: action.payload };
    },

    updateGroupGoalInfo: (state, action) => {
      const newGoalData = state.data ? state.data : grpInitialData;
      return { ...state, data: { ...newGoalData, goals: { ...action.payload } } };
    },
    updatePublishKey: (state, action) => {
      const newGoalData = state.data ? state.data : grpInitialData;
      return { ...state, data: { ...newGoalData, goalPublished: action.payload } };
    },
    updateLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
    updateError: (state, action) => {
      return { ...state, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupDetails.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        // to prevent data being reset when loading beacuse tabs will be diabled with the loader
        // if (!state.data) {
        state.data = undefined;
        // }
        state.errorText = '';
      })
      .addCase(fetchGroupDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.data = action.payload;
        state.errorText = '';
      })
      .addCase(fetchGroupDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.data = undefined;
        state.errorText = action.error.message || 'Something went wrong';
      });
  }
});

export default groupDetailsSlice.reducer;
export const grpDetailsActions = groupDetailsSlice.actions;
