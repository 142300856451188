import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Grid, TextField, InputLabel, Typography } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { Controller } from 'react-hook-form';
import { lockIcon } from '../../../../../components/common';

interface Option {
  value: string;
  isNew?: boolean;
}
interface SubjectSettingProps {
  locked?: boolean;
  control: any;
  topics: Array<{ value: string }>;
  industries: Array<{ value: string }>;
  setSelectedTopicsValue: any;
  setSelectedIndustriesValue: any;
  selectedTopicsValue: any;
  selectedIndustriesValue: any;
  setTopicsUpdateFlag: any;
  setIndustryUpdateFlag: any;
  setTopics: any;
  setIndustries: any;
}

const SubjectSetting: React.FC<SubjectSettingProps> = ({
  locked = false,
  control,
  topics,
  industries,
  setSelectedTopicsValue,
  setSelectedIndustriesValue,
  selectedTopicsValue,
  selectedIndustriesValue,
  setTopicsUpdateFlag,
  setIndustryUpdateFlag,
  setTopics,
  setIndustries
}) => {
  // const [topics, setTopics] = useState(initialTopics);
  // const [industries, setIndustries] = useState(initialIndustries);
  const lockOpacity = locked ? 'disabled-box-opacity-25' : '';

  const filterOptions = (options: Option[], { inputValue }: { inputValue: string }) => {
    const filtered = options.filter((option) => option.value.toLowerCase().includes(inputValue.toLowerCase()));

    if (inputValue !== '' && !options.some((option) => inputValue.toLowerCase() === option.value.toLowerCase())) {
      filtered.push({
        value: `Add "${inputValue}"`,
        isNew: true
      });
    }

    return filtered;
  };

  const handleChange = (field: any, newValue: any, isTopics: boolean = true) => {
    const newItem = newValue.find((item: any) => typeof item === 'object' && item.isNew);

    if (newItem) {
      const newItemLabel = newItem.value.replace('Add "', '').replace('"', '');
      const newOption = { value: newItemLabel };

      if (isTopics) {
        topics.push(newOption);
        //const updatedTopics = [...selectedTopicsValue, newOption.value];
        //setSelectedTopicsValue(updatedTopics);
        setTopicsUpdateFlag(true);
      } else {
        industries.push(newOption);
        //const updatedIndustries = [...selectedIndustriesValue, newOption.value];
        // setSelectedIndustriesValue(updatedIndustries);
        setIndustryUpdateFlag(true);
      }
    }

    const processedValue = newValue.map((item: any) => {
      if (typeof item === 'string') return item;
      if (item.isNew) return item.value.replace('Add "', '').replace('"', '');
      return item.value;
    });

    if (isTopics) {
      setSelectedTopicsValue(processedValue);

      setTopicsUpdateFlag(true);
    } else {
      setSelectedIndustriesValue(processedValue);
      setIndustryUpdateFlag(true);
    }

    field.onChange(processedValue);
  };

  return (
    <Box>
      <Typography variant="h5" pb={2}>
        <span className={lockOpacity}>Subjects</span>{' '}
        {locked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
      </Typography>
      <Box className={lockOpacity}>
        <Grid container spacing={2}>
          {/* Topics Dropdown */}
          <Grid item xs={12}>
            <InputLabel>Topics *</InputLabel>
            <Controller
              control={control}
              name="topics"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  disabled={locked}
                  options={_.uniqBy(topics, 'value')}
                  getOptionLabel={(option: Option | string) => {
                    if (typeof option === 'string') return option;
                    return option.value;
                  }}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Topics" error={!!error} helperText={error?.message} />
                  )}
                  value={Array.isArray(selectedTopicsValue) ? selectedTopicsValue : field.value || []}
                  onChange={(_, newValue) => handleChange(field, newValue, true)}
                  multiple
                  disableCloseOnSelect
                />
              )}
            />
          </Grid>

          {/* Industries Dropdown */}
          <Grid item xs={12}>
            <InputLabel>Industries *</InputLabel>
            <Controller
              control={control}
              name="industries"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  disabled={locked}
                  options={_.uniqBy(industries, 'value')}
                  getOptionLabel={(option: Option | string) => {
                    if (typeof option === 'string') return option;
                    return option.value;
                  }}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Industries"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  value={Array.isArray(selectedIndustriesValue) ? selectedIndustriesValue : field.value || []}
                  onChange={(_, newValue) => handleChange(field, newValue, false)}
                  multiple
                  disableCloseOnSelect
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SubjectSetting;
