/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Box,
  Grid,
  Switch,
  Dialog,
  Divider,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  DialogActions,
  DialogContent
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { LoadingButton } from '@mui/lab';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';
import { GroupBasedRoleFormatter } from '../../../components/common';
// assign a menter popup
// import { featchMatches } from "../../../../redux/slices/getAllMatches/getAllMatchesSlice";
// import { useParams } from "react-router-dom";
// import AppLoader from "../../../../components/AppLoader";
// import { useSelector } from "react-redux";

interface MatchingFields {
  gender: boolean;
  firstGenStudent: boolean;
}

// interface Recommendation {
//   role: string;
//   searchQuery: { field: string; value: string; match: string }[];
//   matchedFieldsCount: number;
//   mentorId: string;
//   menteeId: string;
//   headshot: string;
//   displayName: string;
//   bio: string;
// }

// interface DataItem {
//   message: string;
//   data: {
//     grpId: string;
//     isConfirmed: boolean;
//     id: string;
//     searchQuery: {
//       field: string;
//       value: string;
//       isMatched: boolean;
//     }[];
//     mentee: {
//       bio: {
//         education: {
//           university: string;
//           major: string;
//           graduationDate: string;
//         };
//         workHistory: {
//           companyName: string;
//           role: string;
//         };
//       };
//       displayName: string;
//       userId: string;
//       headshot: string;
//     };
//     mentor: {
//       bio: {
//         workHistory: {
//           companyName: string;
//           role: string;
//         };
//         education: {
//           university: string;
//           major: string;
//           graduationDate: string;
//         };
//       };
//       displayName: string;
//       userId: string;
//       headshot: string;
//     };
//   };
// }

interface AssignMatchPopup1Props {
  openAssignMentor: any;
  handleCloseAssignMentor: any;
  action: string;
  handleOpenFindmentor: any;
  matchingFields: MatchingFields;
  setMatchingFields: any;
  handleSubmitAutoMatch: any;
  maxMatchCountMentee: any;
  maxMatchCountMentor: any;
  setMatchCountMentee: any;
  setMatchCountMentor: any;
  loaderFirstPopup: any;
  selectMentors: React.ReactNode;
  selectValueMentor: boolean;
}

function AssignMatchPopup1({
  openAssignMentor,
  handleCloseAssignMentor,
  action,
  handleOpenFindmentor,
  matchingFields,
  setMatchingFields,
  handleSubmitAutoMatch,
  maxMatchCountMentee,
  maxMatchCountMentor,
  setMatchCountMentee,
  setMatchCountMentor,
  loaderFirstPopup,
  selectMentors,
  selectValueMentor
}: AssignMatchPopup1Props) {
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor'); // Mentor
  const mentorRoleTextLowerCase = mentorRoleText.toLowerCase(); // mentor
  const menteeRoleText = GroupBasedRoleFormatter('mentee', 'Mentee'); // Mentee
  const menteeRoleTextLowerCase = menteeRoleText.toLowerCase(); // mentee
  function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <Dialog open={openAssignMentor} onClose={handleCloseAssignMentor} fullWidth>
      <CommonModalHeader
        handleCloseModal={handleCloseAssignMentor}
        title={
          (action === 'Auto Match'
            ? ''
            : action === 'Assign Mentor'
            ? 'Assign a ' + mentorRoleTextLowerCase
            : 'Assign a ' + menteeRoleTextLowerCase) + (action === 'Auto Match' ? 'Auto Match' : '')
        }
      />

      <DialogContent>
        {/* Matching criteria */}
        {action === 'Auto Match' && (
          <>
            <Box>
              <Typography variant="h5" mb={1}>
                Matching criteria
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel>Maximum matches per {menteeRoleTextLowerCase} *</InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      required
                      type="number"
                      variant="outlined"
                      value={maxMatchCountMentee} // Set the value from the state
                      id="maxMatchCountMentee"
                      onChange={(e) => setMatchCountMentee(Number(e.target.value))} // Update the state when the value changes
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel>Maximum matches per {mentorRoleTextLowerCase} *</InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      required
                      type="number"
                      variant="outlined"
                      value={maxMatchCountMentor}
                      id="maxMatchCountMentor" // Set the value from the state
                      onChange={(e) => setMatchCountMentor(Number(e.target.value))} // Update the state when the value changes
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Divider className='my-20' />
          </>
        )}

        <Box>
          <Box>
            <InputLabel>Select {action === 'Assign Mentor' ? mentorRoleText : menteeRoleText}</InputLabel>
            {selectMentors}
          </Box>
          {/* For Or line */}
          <Box py={2} position="relative">
            <Divider />
            <Typography
              py={1}
              px={2}
              top={'10px'}
              lineHeight={"0"}
              bgcolor={'#FFFFFF'}
              position={"absolute"}
              left={"calc(50% - 29px)"}
            >
              Or
            </Typography>
          </Box>

          {/* Matching Fields */}
          <Box>
            <Typography variant="h5" mb={1}>
              Matching Fields
            </Typography>
            <Typography variant="body2" mb="20px">
              Toggle on the fields that you would like your members to be matched on. You can drag and drop the line
              criteria in order of importance.
            </Typography>
            <Box display="flex" flexDirection="column" gap="20px">
              {Object.entries(matchingFields).map(([key, value]) => {
                const isTitle = key === 'title';
                const firtgendration = key === 'firstGenStudent';
                const isWorkHistory = key === 'industry';
                const isObjectives = key === 'objectives';
                const displayText = isTitle
                  ? 'Role'
                  : firtgendration
                  ? '1st Generation'
                  : isWorkHistory
                  ? 'Work History'
                  : isObjectives
                  ? 'Objectives'
                  : capitalizeFirstLetter(key);
                const isDisabled = selectValueMentor;

                return (
                  <Box display="flex" alignItems="center" key={key}>
                    <DragHandleIcon className='CED4DA' />
                    <Switch
                      checked={value}
                      disabled={isDisabled}
                      onChange={() =>
                        setMatchingFields((prevState: any) => ({
                          ...prevState,
                          [key]: !value
                        }))
                      }
                      color="primary"
                      className='ml-10'
                    />
                    <Typography color="primary" pl={1}>
                      {displayText}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        {action !== 'Auto Match' && (
          <LoadingButton
            fullWidth
            color="primary"
            variant="contained"
            loading={loaderFirstPopup}
            onClick={handleOpenFindmentor}
          >
            {!selectValueMentor && action === 'Assign Mentor' && 'Find  ' + mentorRoleText}
            {!selectValueMentor && action === 'Assign Mentee' && 'Find ' + menteeRoleText}
            {selectValueMentor && action === 'Assign Mentor' && 'Assign ' + mentorRoleText}
            {selectValueMentor && action === 'Assign Mentee' && 'Assign ' + menteeRoleText}
          </LoadingButton>
        )}

        {action === 'Auto Match' && (
          <LoadingButton
            fullWidth
            color="primary"
            variant="contained"
            loading={loaderFirstPopup}
            onClick={handleSubmitAutoMatch}
          >
            Search Auto Matches
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AssignMatchPopup1;
