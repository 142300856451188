import { makeStyles } from '@mui/styles';
import { appColors, theme } from '../utils/theme';

/** Common style */
export const CommonStyle = makeStyles({
  /**Community Page */
  allNubWrapper: {
    color: '#6C757D !important',
    fontSize: '16px !important',
    fontFamily: 'Open Sans !important',
    fontWeigth: '400 !important',
    marginRight: '10px !important'
  },
  filterAccordionDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px'
  },
  filterDetailsText: {
    FontFamily: 'Open Sans',
    fontWeight: '400!important',
    fontSize: '14px !important',
    color: theme.palette.secondary.main,
  },
  programBottomText: {
    position: 'absolute',
    bottom: '0px !important',
    width: '100%',
    height: '86px',
  },
  gap: {
    gap: '8px'
  },
  displayFlex: {
    display: 'flex',
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  cursorPointer: {
    cursor: 'pointer'
  },

  /** Sidebar */
  dosenBtn: {
    bottom: '20px',
    left: '15px',
    position: 'absolute',
    cursor: 'pointer',
    backgroundColor: appColors.white,
    borderRadius: '50%',
    border: `4px solid #152536`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (max-width: 600px)': {
      left: 'auto',
      bottom: '74px',
      right: '10px',
    }
  },

  /** Community member card */
  cardBgImg: {
    position: 'relative',
    borderRadius: 8,
    // width: '195px',
    // height: '195px',
    // aspectRatio: '0.7634',
    aspectRatio: 1 / 1,
    cursor: 'pointer',
    '& img.card-image': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: 8,
      zIndex: 0,
      objectFit: 'cover',
    }
  },
  userCardBio: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  icon: {
    fontSize: '30px',
    marginRight: '8px',
    cursor: 'pointer',
    color: 'blue'
  },
  LikeViewShareButton: {
    display: 'flex',
    gap: '8px',
    border: '1px solid #CED4DA !important',
    borderRadius: '29px !important',
    padding: '6px 16px !important',
    lineHeight: '14px !important',
    fontSize: '14px !important',
    color: '#68717A !important',
    cursor: 'pointer'
  },
  boxDisabledOpacity: {
    pointerEvents: 'none',
    opacity: 0.25
  },
  boxDisabledOpacity50: {
    pointerEvents: 'none',
    opacity: 0.50
  },
  boxDisabledOpacity75: {
    pointerEvents: 'none',
    opacity: 0.75
  },
  boxDisabled: {
    pointerEvents: 'none'
  }
});

/** Admin panel */
export const adminPanel = makeStyles({
  mainBody: {
    overflowY: 'auto',
    // height: 'calc(100% - 180px)',
    height: 'calc(100% - 99px)',
    '@media (max-width: 600px)': {
      height: 'calc(100% - 120px)'
    }
  },
  mainGroupBody: {
    overflowY: 'auto',
    height: 'calc(100% - 65px)',
    '@media (max-width: 600px)': {
      height: 'calc(100% - 100px)'
    }
  },
  mainGroup: {
    overflowY: 'auto',
    height: '100%',
  }
});

/** Chat avatar component styles */
interface IChatAvatarStyles {
  variant: string;
  loading?: boolean;
  border: string;
}

export const ChatAvatarStyles = makeStyles({
  avatarImg: (props: IChatAvatarStyles) => ({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: props.variant === 'circle' ? '50%' : '8px',
    border: props.loading ? 'none' : props.border
  }),

  noStatus: (props: IChatAvatarStyles) => ({
    position: 'absolute',
    maxHeight: '10px',
    minHeight: '10px',
    maxWidth: '10px',
    minWidth: '10px',
    bottom: props.variant === 'circle' ? '0' : '-2px',
    right: props.variant === 'circle' ? '0' : '-3px',
  }),
});

/** Admin task preview styles */
interface IAdminTaskPreview {
  editing?: boolean;
}

export const AdminTaskPreview = makeStyles({
  taskDescriptionInput: (props: IAdminTaskPreview) => ({
    '& .MuiOutlinedInput-root': {
      background: '#FFFFFF !important',
      '& .Mui-disabled': {
        background: '#FFFFFF !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${props.editing ? '' : '#FFFFFF'} !important`,
      },
    },
  }),
});

/** Pagination style */
export const MyPaginationStyles = makeStyles({
  activeColor: {
    '& .MuiPaginationItem-page.Mui-selected': {
      color: '#2196f3', // Blue color for active page number
      backgroundColor: 'transparent', // Transparent background for active page number
    },
    '& .MuiPaginationItem-root.Mui-selected': {
      borderColor: '#2196f3', // Blue color for active page border
    },
  },
})
