import React from 'react';
import { Box, Grid, Button, Rating, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import TagBox from '../../components/TagBox';
import Copy from '../../../../assets/images/copy.svg';
import { checkCallType } from '../../components/common';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Calender from '../../../../assets/images/calendar.svg';
import VideoIcon from '../../../../assets/images/videoCall.svg';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import blankstar from '../../../../assets/images/blankratingsrtar.svg';
import filledstar from '../../../../assets/images/filledratingstar.svg';
import { LikeDislikeAction } from '../../components/LikeDislikeComponent';
import { timeZoneWiseTime, timeZoneAbbreviation, formattedTimeSlot } from '../../components/common';

interface MeetingDetailsProps {
  meeting: any;
  index: number;
  menteeName: string;
  myMenteesList?: any;
  mentorsList?: any;
}

const MeetingDetails: React.FC<MeetingDetailsProps> = ({ meeting, index, menteeName, myMenteesList, mentorsList }) => {
  const callDetails = meeting?.callDetails || meeting;
  const callReview = meeting?.callReview || {};
  const { firstName, lastName, timezone } = useProfileDetails();
  const callStatusData = checkCallType(
    callDetails?.callStatus,
    callDetails?.callDate,
    callDetails?.callTime,
    callDetails?.callTimeInterval,
    callDetails?.rejectStatus,
    callDetails?.joinedParticipantsCount,
    callDetails?.isBookerJoined
  );

  return (
    <Box
      p={2}
      bgcolor="#FFFFFF"
      borderRadius="8px"
      border="1px solid #EFF0F4"
      className={callDetails?.callStatus === 'Canceled' ? 'disabled-box-opacity-50' : ''}
    >
      {/* Meeting Id */}
      <Box display="flex" alignItems="center" gap={1} mb={1} justifyContent={'space-between'} flexWrap={'wrap'}>
        <Box display="flex" alignItems="center" gap={1} flexWrap={'wrap'}>
          <Typography variant="body1">Meeting #{index}</Typography>
          <TagBox color={callStatusData.color_code} text={callStatusData.title} />
        </Box>
        {/* Reject & Join */}
        {/* <Box display="flex" alignItems="center" gap={1} flexWrap={'wrap'}>
          <Button variant='outlined' color='error' className='w-85px' disabled={callDetails?.callStatus === 'Canceled' ? true : false}>Reject</Button>
          <Button variant='contained' className='w-85px' disabled={callDetails?.callStatus === 'Canceled' ? true : false}>Join</Button>
        </Box> */}
      </Box>

      <Grid container spacing={2}>
        {/* Date */}
        <Grid item xs={6} md={4} display="flex" gap="8px" alignItems="center">
          <img src={Calender} alt="" width="28" height="28" />
          <Typography variant="h6">{moment(callDetails?.callDate).format('MM/DD/YYYY')}</Typography>
        </Grid>

        {/* Time */}
        <Grid item xs={6} md={4} display="flex" gap="8px" alignItems="center">
          <AccessTimeIcon className="secondary-light" />
          <Typography variant="h6">
            {callDetails?.callDate && callDetails?.callTime
              ? timeZoneWiseTime(callDetails?.callDate, callDetails?.callTime, timezone)
              : ''}{' '}
            ({timeZoneAbbreviation(timezone)})
          </Typography>
        </Grid>

        {/* Duration */}
        <Grid item xs={6} md={4} display="flex" gap="8px" alignItems="center">
          <img src={VideoIcon} alt="" width="26" height="26" />
          <Typography variant="h6">{formattedTimeSlot(callDetails?.callTimeInterval)}</Typography>
        </Grid>

        {/* Description */}
        <Grid item xs={12}>
          <Box display="flex" gap="8px">
            <img src={Copy} alt="" width="27" height="27" />
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Box display={'flex'} flexDirection={'column'} key={0}>
                <Typography variant="subtitle2" color="#E99940">
                {myMenteesList?.displayName || `${firstName} ${lastName}`.trim()}
                </Typography>
                <Typography variant="body1" color="primary">
                  {callReview.review ? callReview.review : 'No review added'}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'column'} key={1}>
                <Typography variant="subtitle2" color="#0071A9">
                  { }
                </Typography>
                <Typography variant="body1" color="primary">
                  {/* {description[1]?.message} */}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Rating */}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent={'space-between'}>
            <Typography variant="body1">How satisfied are you with the advice you got?</Typography>

            <Rating
              value={callReview?.rating || null}
              readOnly={true}
              className="opacity-50 gap-8"
              icon={<img src={filledstar} alt="" height={25} width={25} />}
              emptyIcon={<img src={blankstar} alt="" height={25} width={25} />}
            />
          </Box>
        </Grid>

        {/* Like/Dislike Section */}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent={'space-between'}>
            <Typography variant="body1">Did you find this conversation helpful?</Typography>
            <LikeDislikeAction
              disabled
              status={callDetails?.participantsUser?.find((user: any) => user.userId === callDetails?.callBookedBy)?.voteValue === 1
                ? 'like'
                : callDetails?.participantsUser?.find((user: any) => user.userId === callDetails?.callBookedBy)?.voteValue === 2
                  ? 'dislike'
                  : ''}
              className="opacity-25"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MeetingDetails;
