import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import '../../../../assets/style/sass/swiper.scss';
import { Box, Skeleton } from '@mui/material';
import { Swiper as SwiperType } from 'swiper';
import {
  A11y,
  Navigation,
  EffectCoverflow
} from 'swiper/modules';
import NewTaskCard from './NewTaskCard';
import ObjectiveCard from './ObjectiveCard';
import { swiperSettings } from './Swiper/swiperSetting';
import { useModule } from '../../../../utils/useModule';
import { levelTypes } from '../../../../utils/constants';
import SwiperNavigation from './Swiper/SwiperNavigation';
import { useAppSelector } from '../../../../redux/hooks';
import { getUserDetails } from '../../../../utils/orgName';
import { API } from '../../../../api';

const CardSlider: React.FC<CardSliderProps> = ({ taskData, forMobile = false, loading, objData }) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [totalObjectivesCount, setTotalObjectivesCount] = useState(3); // Default to 3
  const [objInitialCount, setObjInitialCount] = useState(0);
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const { orgId } = getUserDetails();
  const grpId = selectedGroupId?.id;


  let count = 0;
  let actualCount = 0;
  const currentDate = Date.now() / 1000;
  const learningObjectives = objData || [];
  const getLevelActiveDate = (level: any) => {
    if (level?.startDate?.type === levelTypes.activeNow || !level?.startDate) return true;
    if (level?.startDate?.type === levelTypes.availableSoon) return false;
    if (level?.startDate?.type === levelTypes.calendarDate && currentDate >= level?.startDate?.value) return true;
    if (level?.startDate?.type === levelTypes.daysAfterSignup && currentDate >= level?.startDate?.value) return true;
    return false;
  };

  taskData.forEach((level: any) => {
    if (getLevelActiveDate(level)) {
      level.tasks.forEach((task: any) => {
        if (count === 0 && task.taskColor !== 'green') count = actualCount;
        actualCount++;
      });
    } else {
      if (count === 0) count = actualCount;
      actualCount++;
    }
  });
  
  useEffect(() => {
    const fetchTotalObjectives = async () => {
      try {
        const response = await API.getLearningObjectives({ grpId, orgId });
        const count = response.data?.data?.learningObjectives?.number || 3;
        setTotalObjectivesCount(count);
      } catch (error) {
        console.error('Error fetching total learning objectives:', error); 
      }
    };

    if (grpId && orgId) {
      fetchTotalObjectives();
    }
  }, [grpId, orgId]);

  const module = useModule();
  const isMentorship = module.isMentorship;

  const getColor = (status: string) => {
    if (status === 'to-do') return 'grey';
    if (status === 'inprogress') return 'blue';
    return 'green';
  };

  const objCOunt = learningObjectives.length;
  let tasksCount = 0;
  taskData?.forEach((level: any) => {
    tasksCount += level.tasks.length;
  });
  const isLooping = isMentorship ? (totalObjectivesCount >= 3 ? true : false) : tasksCount >= 3 ? true : false;

  /** Objective card */
  const filledObjectives = learningObjectives || [];
  const renderMentorshipCards = () => {
    const cards = [];
    cards.push(
      ...filledObjectives.map((objective: any, index: number) => (
        <SwiperSlide key={objective.id}>
          <ObjectiveCard
            key={objective.id}
            objId={objective.id}
            borderColor={getColor(objective.status)}
            title={objective.objective}
            description={objective.description}
            status={objective.status}
            current={index + 1}
            total={totalObjectivesCount}
            noteCount={objective.notes?.length || 0}
            videoCount={objective.mentors?.reduce((acc: number, mentor: any) => acc + mentor.meetings.length, 0) || 0}
            notes={objective.notes || []}
            mentors={objective.mentors || []}
          />
        </SwiperSlide>
      ))
    );
    // Fill remaining slots with empty objective cards
    while (cards.length < totalObjectivesCount) {
      cards.push(
        <SwiperSlide key={`empty-${cards.length}`}>
          <ObjectiveCard
            key={`empty-${cards.length}`}
            objId=""
            borderColor="grey"
            title=""
            description=""
            status="" // I've removed the 'to-do' bcz i need a default card color
            current={cards.length + 1}
            total={totalObjectivesCount}
            noteCount={0}
            videoCount={0}
            notes={[]}
            mentors={[]}
          />
        </SwiperSlide>
      );
    }
    return cards;
  };
  
  /** Task card */
  const renderTaskCards = () =>
    taskData.flatMap((level: any, levelIndex: number) => {
      if (!getLevelActiveDate(level)) {
        return (
          <SwiperSlide key={level._id}>
            <NewTaskCard
              levelName={level.levelName}
              levelId={level._id}
              borderColor="grey"
              trophyColor="grey"
              isLocked={true}
              levelDescription={level?.levelDescription || 'This level has no description'}
              levelNumber={levelIndex + 1}
              level={level}
            />
          </SwiperSlide>
        );
      }

      return level.tasks.map((task: any) => (
        <SwiperSlide key={task.id}>
          <NewTaskCard
            task={task}
            levelName={level.levelName}
            levelId={level._id}
            borderColor={task.taskColor}
            trophyColor={task.taskColor}
            level={level}
          />
        </SwiperSlide>
      ));
    });

  const getCards = () => (isMentorship ? renderMentorshipCards() : renderTaskCards());

  // let objInitialCount = 0;
  useEffect(() => {
    if (isMentorship && learningObjectives.length > 0) {
      const uniqueObjectives = learningObjectives.filter(
        (objective:any, index:any, self:any) =>
          index === self.findIndex((obj:any) => obj.id === objective.id) 
      );
      const nonCompletedObjectives = uniqueObjectives.filter(
        (objective:any) => objective.status !== 'completed'
      );
  
      if (nonCompletedObjectives.length > 0) {
        const firstNonCompletedIndex = learningObjectives.findIndex(
          (obj:any) => obj.id === nonCompletedObjectives[0].id
        );
        setObjInitialCount(firstNonCompletedIndex);
      }
    }
  }, [learningObjectives, isMentorship]);
  
  return (
    <Box position="relative">
      {loading ? (
        <Swiper
         key={objInitialCount}
          {...swiperSettings}
          modules={[Navigation, A11y, EffectCoverflow]}
          navigation={{
            nextEl: '.custom-swiper-button-next',
            prevEl: '.custom-swiper-button-prev'
          }}
          loop={false}
          onSwiper={(swiper: any) => (swiperRef.current = swiper)}
          initialSlide={isMentorship ? objInitialCount : count}
        >
          {[...Array(3)].map((_, index) => (
            <SwiperSlide key={index}>
              <Skeleton variant="rounded" width="100%" height="332px" />
            </SwiperSlide>
          ))}

          {/* ✅ Pass swiperRef correctly */}
          <SwiperNavigation
            loading={loading}
            swiper={swiperRef.current}
          />
        </Swiper>
      ) : (
        <Swiper
         key={objInitialCount}
          {...swiperSettings}
          modules={[Navigation, A11y, EffectCoverflow]}
          navigation={{
            nextEl: '.custom-swiper-button-next',
            prevEl: '.custom-swiper-button-prev'
          }}
          loop={isLooping}
          onSwiper={(swiper: any) => (swiperRef.current = swiper)}
          initialSlide={isMentorship ? objInitialCount : count}
        >
          {getCards()}

          {/* ✅ Pass swiperRef correctly */}
          <SwiperNavigation
            loading={loading}
            swiper={swiperRef.current}
          />
        </Swiper>
      )}
    </Box>
  );
};

export default CardSlider;
