import React, { useEffect, useState } from 'react';
import { Box, Radio, RadioGroup, Typography, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { lockIcon } from '../../../../../components/common';
import MyTooltip from '../../../../../components/MyTooltip';
import { CommonStyle } from '../../../../../../../utils/muiStyle';
import { Controller } from 'react-hook-form';

interface MasterclassModelSettingProps {
  locked?: boolean;
  value?: string;
  control: any;
  getError: any;
  setBasicUpdateFlag?: any;
  onModelChange: (newModel: string) => void;
  disableModelChange?: boolean;
}

const options = [
  {
    value: 'masterclass',
    label: 'Masterclass only',
    tooltip:
      "Your Masterclass is the course you are running on Dosen and is the sum of Lessons you will build independently or with our Masterclass Design team. Your Masterclass is presented to your members on their Home page where they can begin to complete the relevant Lessons. We recommend selecting 'Masterclass only' if you do not wish to match your members with one another for the purpose of Mentorship, Coaching, or Consulting."
  },
  {
    value: 'masterclass&mentorship',
    label: 'Masterclass & Mentorship',
    tooltip:
      "We recommend selecting 'Masterclass & Mentorship' if you wish to run a Masterclass and match your members with one another for the purpose of Mentorship, Coaching, or Consulting."
  },
  {
    value: 'mentorship',
    label: 'Mentorship only',
    tooltip:
      'If you do not wish to run a Masterclass course on Dosen and instead simply want your members to have a Community so that you can match them with one another for the purpose of Mentorship, Coaching, or Consulting.'
  }
];

const MasterclassModelSetting: React.FC<MasterclassModelSettingProps> = ({
  locked = false,
  value,
  control,
  getError,
  setBasicUpdateFlag,
  onModelChange,
  disableModelChange = false
}) => {
  const styles = CommonStyle({});
  const lockOpacity = locked ? styles.boxDisabledOpacity : '';
  const [selectedValue, setSelectedValue] = useState<string>(value || options[0]?.value || '');

  useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
    }
  }, [value]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newModel = e.target.value;
    setBasicUpdateFlag(true);
    onModelChange(newModel);
  };

  const isOptionDisabled = (optionValue: string) => {
    if (disableModelChange && optionValue !== selectedValue) {
      return true;
    }
    return false;
  };

  return (
    <Box>
      <Typography variant="h5" mb={1.5}>
        <span className={lockOpacity}>Masterclass Model</span>{' '}
        {locked &&
          lockIcon({
            title: 'This is not needed for the Masterclass model'
          })}
        <FormHelperText error>{getError('model')?.toString()}</FormHelperText>
      </Typography>
      <Box className={lockOpacity}>
        <Controller
          name="model"
          control={control}
          render={({ field }) => (
            <FormControl disabled={locked}>
              <RadioGroup
                row
                aria-labelledby="masterclassModal-label"
                name="model"
                id="model"
                value={field.value}
                defaultValue={value}
                onChange={(e) => {
                  handleChange(e);
                  field.onChange(e);
                }}
              >
                {options.map((option) => (
                  <Box key={option.value} pr={2}>
                    <FormControlLabel
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      className="mr-5"
                      disabled={isOptionDisabled(option.value)}
                      componentsProps={{
                        typography: {
                          color: 'primary.main'
                        }
                      }}
                    />
                    <MyTooltip title={option.tooltip} placement="right" />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          )}
        />
      </Box>
    </Box>
  );
};

export default MasterclassModelSetting;
