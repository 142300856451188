import React from 'react';
import { Box, Typography, BoxProps } from '@mui/material';

interface TagBoxProps extends BoxProps {
    text: string;
    bgColor?: string;
    fontSize?: string;
    fontWeight?: number | string;
    borderRadius?: string;
    color?: string;
}

const TagBox: React.FC<TagBoxProps> = ({
    text,
    bgColor = 'transparent',
    fontSize = '12px',
    fontWeight = '600',
    borderRadius = '5px',
    color = '#E99940',
    ...boxProps // Collect additional Box props like `my`, `ml`, etc.
}) => {
    return (
        <Box
            bgcolor={bgColor}
            width="fit-content"
            borderRadius={borderRadius}
            border={`1px solid ${color}`}
            {...boxProps} // Apply additional Box props here
        >
            <Typography
                px="9px"
                py="2px"
                color={color}
                fontSize={fontSize}
                fontWeight={fontWeight}
                textTransform="capitalize"
            >
                {text}
            </Typography>
        </Box>
    );
};

export default TagBox;
