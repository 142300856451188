import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { API } from '../api';

const useLearningObjs = ({ orgId, grpId }: { orgId: string; grpId: string }) => {
  return useQuery({
    queryKey: [queryKeys.GET_LEARNING_OBJS, grpId],
    queryFn: () => {
      return API.UserLearningObjectives({ orgId });
    },
    staleTime: 10 * 10 * 1000
  });
};

export default useLearningObjs;
