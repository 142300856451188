// SeeBookingDetailsModal

import React, { useState,useEffect } from 'react';
import { Box, Grid, Stack, Dialog, Divider, Typography, IconButton, DialogContent } from '@mui/material';
import directory from '../../../../assets/images/directory.svg';
import Calender from '../../../../assets/images/calendar.svg';
import VideoIcon from '../../../../assets/images/videoCall.svg';
// import Question from '../../../../assets/images/question.svg';
import Copy from '../../../../assets/images/copy.svg';
import RoleTypeTag from '../../components/RoleTypeTag';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  bioFormatter,
  formatMMDDYYYY,
  formattedTimeSlot,
  timeZoneWiseTime,
  timeZoneAbbreviation,
  checkCallType
} from '../../components/common';
import CallBookingModal from '../CallBookingModal';
import { useAppSelector } from '../../../../redux/hooks';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { getUserDetails } from '../../../../utils/orgName';
import { callEditableFlag } from './CalendarFunctions';
import UserinfoCard from '../../components/UserinfoCard';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import { API } from '../../../../api';

interface SeeBookingDetailsModalProps {
  openCallbookingDetailsModal: boolean;
  closeSeeBookingDetailsModal: () => void;
  bookedCallDetails: CallRowData;
  allattendeeDetails: any[];
  timezone: string | '';
  ModalDialogActions: React.ReactNode;
  userCallsDataRefetch: () => void;
  isCallRejectedByAuthUser: boolean;
  setIsCallEditing: any;
  isCallEditing: boolean;
}

interface CallRowData {
  meetingId: string;
  _id: string;
  callDate: string;
  callTime: string;
  callTitle: string;
  callTimeInterval: number;
  chatType?: string;
  callBookedWith?: {
    id: string;
    displayName: string;
    headshot: string;
  };
  callBookedBy?: {
    id: string;
    displayName: string;
    headshot: string;
  };
  //participantsUser: { mid: string }[];
  description?: string;
  callStatus: string;
  participantsUser: { callRejectStatus: boolean; mid: string; isCallJoined: boolean }[];
  objective?: string;
}

const SeeBookingDetailsModal: React.FC<SeeBookingDetailsModalProps> = ({
  openCallbookingDetailsModal,
  closeSeeBookingDetailsModal,
  bookedCallDetails,
  allattendeeDetails,
  timezone,
  ModalDialogActions,
  userCallsDataRefetch,
  setIsCallEditing,
  isCallEditing
}) => {
  const acsChannelParticipants = useAppSelector((state) => state.acsChannelParticipants.data);
  const chatType = bookedCallDetails?.chatType || '';
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const handleCalendar = (flag: boolean) => setCalendarOpen(flag);
  const closeCalendarModal = () => {
    userCallsDataRefetch();
    setCalendarOpen(false);
    closeSeeBookingDetailsModal();
    setIsCallEditing(false);
  };
  let channel = bookedCallDetails?.callBookedWith?.displayName || '';
  const editFlag = callEditableFlag(bookedCallDetails, getUserDetails()?.userId);
  const [objectiveDetails, setObjectiveDetails] = useState<any>(null);
  useEffect(() => {
    if (bookedCallDetails?.objective) {
      const orgId = getUserDetails()?.orgId || '';
      const fetchObjectiveDetails = async () => {
        try {
          const response = await API.UserLearningObjectives({ orgId });
          if (Array.isArray(response.data?.data)) {
            const matchedObjective = response.data.data.find(
              (obj: any) => obj.id === bookedCallDetails.objective
            );
            if (matchedObjective) {
              setObjectiveDetails(matchedObjective);
            }
          }
        } catch (error) {
          console.error("Error fetching objective details:", error);
        }
      };
      
      fetchObjectiveDetails();
    }
  }, [bookedCallDetails?.objective]);
  const bioOfBookedWithPersonDiv = (bookedWithPerson: any) => {
    return (
      <Box display="flex" gap="8px">
        <Typography noWrap>
          {bookedWithPerson?.category === 'Student'
            ? bioFormatter(
                bookedWithPerson?.bio?.education?.major,
                bookedWithPerson?.bio?.education?.university,
                bookedCallDetails?.callTitle
              )
            : bioFormatter(
                bookedWithPerson?.bio?.workHistory?.role,
                bookedWithPerson?.bio?.workHistory?.companyName,
                bookedCallDetails?.callTitle
              )}
        </Typography>
      </Box>
    );
  };
  const bookedPersonDiv = (chatType: string, callRow: any) => {
    if (chatType === 'Groups') {
      channel = callRow?.callBookedWith?.name;
      return (
        <Box display="flex" gap="8px" alignItems="center" width={'100%'}>
          <Box>
            <ChatAvatarComponent
              //image={callRow?.callBookedWith?.headshot}
              type="noStatus"
              firstLetter={callRow?.callBookedWith?.name.charAt(0)}
              width="48px"
              height="48px"
            />
          </Box>
          <Box width="calc(100% - 56px)">
            <Box display="flex" gap="8px" alignItems="center" width={'100%'}>
              <Typography variant="h5" noWrap>
                {callRow?.callBookedWith?.name}
              </Typography>
              {/* <RoleTypeTag category={callRow?.callBookedWith?.category} /> */}
            </Box>
            {bookedCallDetails?.callTitle ? <Typography noWrap>{bookedCallDetails.callTitle}</Typography> : null}
          </Box>
        </Box>
      );
    } else {
      const userWithId =
        getUserDetails().userId === callRow?.callBookedBy?.id ? callRow?.callBookedWith?.id : callRow?.callBookedBy?.id;
      const bookedWithPerson = callRow?.participantsUser?.find((user: { mid: any }) => user.mid === userWithId);
      channel = bookedWithPerson?.displayName;
      return (
        <Box display="flex" gap="8px" alignItems="center" width={'100%'}>
          <Box>
            <ChatAvatarComponent
              image={bookedWithPerson?.headshot}
              type="noStatus"
              firstLetter={bookedWithPerson?.displayName.charAt(0)}
              width="48px"
              height="48px"
            />
          </Box>
          <Box width="calc(100% - 56px)" gap="8px">
            <Box display="flex" gap="8px" alignItems="center" width={'100%'}>
              <Typography variant="h5" noWrap>
                {bookedWithPerson?.displayName}
              </Typography>
              <RoleTypeTag category={bookedWithPerson?.category} />
            </Box>
            {bioOfBookedWithPersonDiv(bookedWithPerson)}
          </Box>
        </Box>
      );
    }
  };
  const editButton = (callRow: any, editFlag: number) => {
    if (editFlag === 1) {
      return (
        <Box>
          <IconButton onClick={() => handleOpenBookingModal(callRow)}>
            <CreateOutlinedIcon />
          </IconButton>
        </Box>
      );
    } else {
      return '';
    }
  };
  const handleOpenBookingModal = (callRow: any) => {
    if (callEditableFlag(callRow, getUserDetails()?.userId) === 1) {
      setIsCallEditing(true);
      setCalendarOpen(true);
    }
  };

  const userId = getUserDetails()?.userId || '';

  const matchingParticipant = bookedCallDetails?.participantsUser?.find((user) => user.mid === userId);
  const isCallRejectedByAuthUser = matchingParticipant?.callRejectStatus;

  const getJoinedParticipantsCount = (
    participants: { callRejectStatus: boolean; mid: string; isCallJoined: boolean }[]
  ) => {
    return participants?.filter((participant) => participant.isCallJoined === true).length;
  };
  const joinedParticipantsCount = getJoinedParticipantsCount(bookedCallDetails?.participantsUser);
  const isBookerJoined = matchingParticipant?.isCallJoined || false;
  const callTypeData = checkCallType(
    bookedCallDetails.callStatus,
    bookedCallDetails.callDate,
    bookedCallDetails.callTime,
    bookedCallDetails.callTimeInterval,
    isCallRejectedByAuthUser,
    joinedParticipantsCount,
    isBookerJoined
  );

  const callFlag = callTypeData.call_flag;
  return (
    <Dialog open={openCallbookingDetailsModal} onClose={closeSeeBookingDetailsModal} scroll="body">
      {/* Dialog Header */}
      <CommonModalHeader title={callFlag} handleCloseModal={closeSeeBookingDetailsModal} />
      <DialogContent>
        <Stack>
          <Box display="flex" gap="8px" alignItems="center" mb={2}>
            <Box>
              <img src={directory} alt="" width="34" height="34" />
            </Box>
            <Box display="flex" gap="8px" alignItems="center" width={'calc(100% - 42px)'}>
              <Box width={'calc(100% - 40px)'}>{bookedPersonDiv(chatType, bookedCallDetails)}</Box>
              <Box>{editButton(bookedCallDetails, editFlag)}</Box>
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6} md={4} display="flex" gap="8px" alignItems="center">
              <img src={Calender} alt="" width="28" height="28" />
              <Typography variant="h6">
                {formatMMDDYYYY(bookedCallDetails.callDate.toString(), bookedCallDetails.callTime.toString(), timezone)}
              </Typography>
            </Grid>

            <Grid item xs={6} md={4} display="flex" gap="8px" alignItems="center">
              <AccessTimeIcon sx={{ color: '#ABB5BE' }} />
              <Typography variant="h6">
                {timeZoneWiseTime(
                  bookedCallDetails.callDate.toString(),
                  bookedCallDetails.callTime.toString(),
                  timezone
                )}{' '}
                ({timeZoneAbbreviation(timezone)})
              </Typography>
            </Grid>

            <Grid item xs={6} md={4} display="flex" gap="8px" alignItems="center">
              <img src={VideoIcon} alt="" width="26" height="26" />
              <Typography variant="h6">{formattedTimeSlot(bookedCallDetails.callTimeInterval)}</Typography>
            </Grid>

            {/* About Career */}
            {/* <Grid item xs={12} display="flex" gap="8px" alignItems="center">
              <img src={Question} width="26" height="26" />
              <Typography color="primary">About Career</Typography>
            </Grid> */}

            {/* Description */}
            {bookedCallDetails.description && (
              <Grid item xs={12} display="flex" gap="8px" alignItems="center">
                <img src={Copy} alt="" width="26" height="26" />
                <Typography color="primary">{bookedCallDetails.description}</Typography>
              </Grid>
            )}
          </Grid>
        </Stack>

        {/* Attendees */}
        {allattendeeDetails && allattendeeDetails.length > 0 && (
          <>
            <Divider sx={{ my: 2 }} />
            <Box mb={2}>
              <Typography color="#0071A9" variant="h6">
                All Attendees
              </Typography>
            </Box>
          </>
        )}

        {allattendeeDetails?.map((attendees, index) => {
          const userBioData =
            attendees.category === 'Student'
              ? bioFormatter(attendees.bio?.education?.major, attendees.bio?.education?.university, '')
              : bioFormatter(attendees.bio?.workHistory?.role, attendees.bio?.workHistory?.companyName, '');

          const UserinfoCardDetails = {
            headshot: attendees.headshot,
            displayName: attendees.displayName,
            UserType: attendees.category,
            bio: userBioData
          };

          return (
            <Box key={attendees._id}>
              <UserinfoCard UserinfoCardDetails={UserinfoCardDetails} />
              {index !== allattendeeDetails.length - 1 && <Divider sx={{ my: 2 }} />}
            </Box>
          );
        })}
      </DialogContent>

      <CallBookingModal
        handleCloseModal={() => closeCalendarModal()}
        openFlag={isCalendarOpen}
        chatProfileName={channel}
        threadParticipantsUsers={acsChannelParticipants}
        chatType={chatType}
        pageName="Chat"
        bookedCallDetails={bookedCallDetails}
        isCallEditing={isCallEditing}
        objectiveDetails={objectiveDetails}
      />
      {ModalDialogActions}
    </Dialog>
  );
};

export default SeeBookingDetailsModal;
