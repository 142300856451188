import { Box, Divider, Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material';
import React, { useState, useEffect } from 'react';
import MeetingDetails from './MeetingDetails';
import { ExpandMore } from '@mui/icons-material';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import { ChatIcon, VideoCallIcon } from '../../../../utils/Icons';
import useProfileDetails from '../../../../hooks/useProfileDetails';

interface ObjectiveMentorsProps {
  mentorsList: any[];
  onChatClick: (each: any) => void;
  onCalendarClick: (each: any) => void;
  myMenteesList?: any;
}

const ObjectiveMentors: React.FC<ObjectiveMentorsProps> = ({ mentorsList, onChatClick, onCalendarClick, myMenteesList }) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { firstName, lastName, timezone } = useProfileDetails();

  useEffect(() => {
    if (mentorsList && mentorsList.length > 0) {
      setIsLoading(false);
    }
  }, [mentorsList]);

  const handleAccordionChange = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedIndex(isExpanded ? index : null);
  };

  return (
    <>
      {mentorsList.map((each, index) => {
        const fullName = `${firstName} ${lastName}`.trim(); 
        const isCurrentUser = each.name.trim() === fullName; 
        return (
          <React.Fragment key={each.mid}>
            {index > 0 && <Divider />}
            <Accordion
              expanded={expandedIndex === index}
              onChange={handleAccordionChange(index)}
              sx={{
                '&.Mui-expanded': {
                  margin: '0'
                }
              }}
            >
              <AccordionSummary
                sx={{ padding: 0, margin: 0 }}
                id={`mentor-header-${each.mid}`}
                aria-controls={`mentor-details-${each.mid}`}
                expandIcon={<ExpandMore sx={{ color: '#0071A9' }} />}
              >
                <Box gap={'8px'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  {/* Mentor Info */}
                  <Box gap={1} flex={1} display={'flex'} overflow={'hidden'} alignItems={'flex-start'}>
                    <ChatAvatarComponent
                      width="48px"
                      height="48px"
                      type="noStatus"
                      image={each?.image}
                      firstLetter={each?.name?.slice(0, 1)}
                    />
                    <Box flex={1} overflow={'hidden'}>
                      <Typography variant="h5" noWrap>
                        {each.name}
                      </Typography>
                      <Typography variant="body1" noWrap>
                        {each?.bio?.workHistory?.role || 'Mentor'}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Action Icons */}
                  <Box
                    gap={1}
                    display={'flex'}
                    alignItems={'center'}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <ChatIcon size={21} onClick={() => onChatClick(each)} />

                    <Box
                      sx={{
                        pointerEvents: isCurrentUser ? 'none' : 'auto', 
                        opacity: isCurrentUser ? 0.5 : 1, 
                      }}
                    >
                      <VideoCallIcon size={21} onClick={!isCurrentUser ? () => onCalendarClick(each) : undefined} />
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary>

              {/* Details Section */}
              <AccordionDetails sx={{ padding: 0 }}>
                {each.meetings?.length ? (
                  each.meetings.map((meeting: any, index: number) => (
                    <Box mb={1.6} key={index}>
                      <MeetingDetails meeting={meeting} index={index + 1} menteeName={each.name} myMenteesList={myMenteesList} mentorsList={mentorsList}/>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1" textAlign={'center'} mb={2}>
                    No meetings scheduled.
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ObjectiveMentors;
