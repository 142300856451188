function useObjectiveProgress(objectives: any) {
  if (!Array.isArray(objectives) || objectives.length === 0) {
    return 0; // Return 0% if there are no objectives
  }

  const completedObjectives = objectives.filter((obj) => obj.status === 'completed').length;
  const totalObjectives = objectives.length;

  return ((completedObjectives / totalObjectives) * 100).toFixed(2);
}

export default useObjectiveProgress;
