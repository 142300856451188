import React, {
    useState,
    useEffect
} from 'react';
import {
    Box,
    Button,
    Divider,
    Typography,
    IconButton,
} from '@mui/material';
import { API } from '../../../../api';
import MeetingCard from './cards/MeetingCard';
import CallBookingModal from '../CallBookingModal';
import MySkeleton from '../../../../components/Skeleton';
import { PlusCircleIcon } from '../../../../utils/Icons';
import { useAppSelector } from '../../../../redux/hooks';
import { getUserDetails } from '../../../../utils/orgName';
import { CardStyle } from '../../style-components/CardStyling';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';

const YourNextMeeting: React.FC<YourNextMeetingProps> = ({ loading }) => {
    const classess = CardStyle();
    const { forMobile } = useResponsiveScreenSwitch();
    const [isCalendarOpen, setCalendarOpen] = useState(false);
    const [meeting, setMeeting] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const handleCalendar = () => setCalendarOpen(true);
    const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
    const isMentor = selectedGroupId?.role === 'mentor';
    const { orgId, location } = getUserDetails();

    /** Card title as per user */
    const cardTitle = isMentor ? 'Your Next Mentorship Meetings' : 'Your Next Mentorship Meetings';

    const fetchMeeting = async () => {
        try {
            setIsLoading(true);
            const response = await API.getUpcomingMeeting(orgId, location);
            if (response?.data?.data && Object.keys(response.data.data).length > 0) {
                setMeeting(response.data.data);
            } else {
                setMeeting(null);
            }
        } catch (error) {
            console.error("Error fetching meeting:", error);
            setMeeting(null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleMeetingUpdate = () => {
        fetchMeeting();
    };

    useEffect(() => { fetchMeeting(); }, []);

    const isDataLoading = loading || isLoading;
    const shouldShowButton = (isMentor && !meeting && !isDataLoading) || (!isMentor && !isDataLoading);

    return (
        <>
            <Box className={`${classess.cardBox} ${classess.flexColumn}`} minHeight={forMobile ? 'auto' : '240px'}>
                <Box px={2} gap={'16px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant={isMentor ? "h6" : "h5"} py={2}>
                        {isDataLoading ? <MySkeleton width={150} /> : `${cardTitle}`}
                    </Typography>
                    {shouldShowButton && (
                        <IconButton size="small" onClick={handleCalendar}>
                            <PlusCircleIcon size={30} />
                        </IconButton>
                    )}
                </Box>

                {!isMentor && <Divider />}

                {isDataLoading || meeting ? (
                    <Box p={2} pt={isMentor ? 0 : ''} display="flex" flexDirection="column" justifyContent="center" flex={1}>
                        <MeetingCard key={meeting?._id} meeting={meeting} loading={isDataLoading} onMeetingCanceled={handleMeetingUpdate} />
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent={'center'} flex={1}>
                        <Button color='info' variant='contained' onClick={() => setCalendarOpen(true)} className='maxWidth-min-content'>
                            Schedule Meeting
                        </Button>
                    </Box>
                )}
            </Box>

            {/* Call Booking Modal */}
            <CallBookingModal
                handleCloseModal={() => setCalendarOpen(false)}
                openFlag={isCalendarOpen}
                chatProfileName=""
                threadParticipantsUsers={[]}
                chatType="Chats"
                pageName="Calendar"
                bookedCallDetails=""
                onMeetingScheduled={fetchMeeting}
            />
        </>
    );
};

export default YourNextMeeting;
