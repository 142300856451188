import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  // Radio,
  // Select,
  // MenuItem,
  TextField,
  InputLabel,
  Typography,
  // RadioGroup,
  FormControl,
  Autocomplete,
  DialogContent,
  DialogActions,
  FormHelperText
  // FormControlLabel
} from '@mui/material';
import _ from 'lodash';
// import {
//   useForm,
//   Controller,
//   FieldValues,
//   SubmitHandler
// } from 'react-hook-form';
// import * as yup from 'yup';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { sortedCountries } from './common';
// import { yupResolver } from '@hookform/resolvers/yup';
import { getUserDetails } from '../../../utils/orgName';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import { userActions } from '../../../redux/slices/user-profile/userProfieSlice';
import { Controller, SubmitHandler, FieldValues, useForm } from 'react-hook-form';

const Company = () => {
  const { ftue } = useProfileDetails();
  const dispatch = useAppDispatch();
  const { userId } = getUserDetails();
  const { settings } = useProfileDetails();
  const [ftueLoading, setFtueLoading] = useState(false);
  const [companyName, setCompanyName] = useState<any>(null);
  const [companyLocation, setCompanyLocation] = useState<any>(null);
  const [errors, setErrors] = useState({ company: '', location: '' });
  const { location } = getUserDetails();
  // const fundingStages = ['Series A', 'Series B', 'Pre-IPO'];
  // const companySizes = ['1-10', '11-50', '51-200', '201+'];
  // const businessModels = ['B2B', 'B2C', 'C2C', 'SaaS'];
  // const customerList = ['Customer A', 'Customer B', 'Customer C'];
  // const schema = yup.object({
  //   company: yup.string().nullable().required('Company name is required'),
  //   location: yup.string().nullable().required('Location is required')
  // });

  // const {
  //   // handleSubmit,
  //   control,
  //   // formState: { errors },
  //   // setValue,
  //   // getValues
  // } = useForm({
  //   // resolver: yupResolver(schema)
  // });

  const storedValues = localStorage.getItem('company');
  const popuplength = useAppSelector((state) => state.popupsCount.popuplength);
  const count = useAppSelector((state) => state.popupsCount.count);
  const nextPopUpOrNot = popuplength - count;
  const checkErrors = () => {
    let isError = false;
    if ((settings.companyName || companyLocation) && !companyName?.trim()) {
      setErrors((prev) => ({ ...prev, company: 'Company name is required' }));
      isError = true;
    }
    if ((settings.companyName || companyName) && !companyLocation) {
      isError = true;
      setErrors((prev) => ({ ...prev, location: 'Company location is required' }));
    }
    return isError;
  };

  const updateFtue = async () => {
    setFtueLoading(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      const data = localStorage.getItem('education');
      let response2 = 200;
      if (data) {
        const formData = JSON.parse(data);
        const response = await API.addEducationAndSkills({ location, userId, formData });
        response2 = response.status;
      }
      if (response.status === 200 && response2 === 200) {
        dispatch(userActions.userFtueUpdate(0));
        setFtueLoading(false);
      }
    } catch (e) {
      toast.error(`Company details failed to update`);
      setFtueLoading(false);
    }
  };

  const onSubmit = async () => {
    if (checkErrors()) {
      return;
    }
    localStorage.setItem('company', JSON.stringify({ name: companyName || '', location: companyLocation || '' }));
    const nextPopUpOrNot = popuplength - count;
    if (ftue && nextPopUpOrNot !== 1) {
      dispatch(popupCountActions.increment(0));
    }
    if (nextPopUpOrNot === 1) {
      await updateFtue();
    }
  };

  const [data, setData] = useState<{ name: string; location: string }[]>([]);
  const value = data?.find((each) => each?.name?.toLowerCase() === companyName?.toLowerCase());

  const getCompanyList = async () => {
    try {
      const response = await API.getCompanyList(location);
      if (response.status === 200) {
        setData(response.data.data);
        localStorage.setItem('companyData', JSON.stringify(response.data.data));
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Company list failed to update`));
    }
  };

  useEffect(() => {
    if (storedValues) {
      setCompanyName(JSON.parse(storedValues).name);
      setCompanyLocation(JSON.parse(storedValues).location);
    }
  }, []);
  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    const data = localStorage.getItem('companyData');
    if (data) {
      setData(JSON.parse(data));
    }
  }, []);

  /** Dialog actions */
  const getDialogActions = () => {
    return (
      <DialogActions>
        {ftue && (
          <LoadingButton
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => dispatch(popupCountActions.decrement(0))}
          >
            Back
          </LoadingButton>
        )}

        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          loading={ftueLoading}
          onClick={onSubmit}
          id="companySubmit"
        >
          {nextPopUpOrNot === 1 ? 'Finish' : nextPopUpOrNot > 1 ? 'Next' : 'Save'}
        </LoadingButton>
      </DialogActions>
    );
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Company Name */}
          <Grid item xs={12}>
            <InputLabel>Company Name {settings?.companyName && '*'}</InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                size="small"
                id="company"
                value={data?.find((each) => each.name.toLowerCase() === companyName?.toLowerCase()) || companyName}
                options={data}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                autoHighlight
                onChange={(_, e) => {
                  setCompanyName(e?.name ? e.name : e);
                  if ((settings.companyName || companyLocation) && !e?.name) {
                    setErrors({ ...errors, company: 'Company name is required' });
                  } else {
                    setCompanyLocation(e?.location);
                    setErrors({ location: '', company: '' });
                  }
                }}
                freeSolo
                defaultValue={storedValues ? JSON.parse(storedValues).location : null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Choose Company Name"
                    autoComplete="false"
                    inputProps={{
                      ...params.inputProps
                    }}
                  />
                )}
                onInputChange={(_, e) => {
                  setCompanyName(e);
                  if (e) {
                    setErrors({ ...errors, company: '' });
                  }
                }}
              />
            </FormControl>
            <FormHelperText error>{errors.company}</FormHelperText>
          </Grid>

          {/* Company Location */}
          <Grid item xs={12}>
            <InputLabel>Company Location {settings?.companyName && '*'}</InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                size="small"
                id="location"
                value={sortedCountries.find((each) => each.label === companyLocation)}
                key={companyLocation}
                options={sortedCountries}
                getOptionLabel={(option) => option.label}
                onChange={(_, e) => {
                  setCompanyLocation(e?.label ? e.label : e);
                  if ((settings.companyName || companyName) && !e) {
                    setErrors({ ...errors, location: 'Location is required' });
                  } else {
                    setErrors({ ...errors, location: '' });
                  }
                }}
                defaultValue={storedValues ? JSON.parse(storedValues).company : null}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Choose Company Location"
                    autoComplete="false"
                    inputProps={{
                      ...params.inputProps
                    }}
                  />
                )}
                disabled={value ? true : false}
              />
            </FormControl>
            <FormHelperText error>{errors.location}</FormHelperText>
          </Grid>

          {/* Mission Statement */}
          {/* <Grid item xs={12}>
            <InputLabel>Mission Statement *</InputLabel>
            <Controller
              name="missionStatement"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder="Mission Statement"
                  // error={!!errors.missionStatement}
                />
              )}
            />
          </Grid> */}

          {/* Current Challenges */}
          {/* <Grid item xs={12}>
            <InputLabel>Current Challenges *</InputLabel>
            <Controller
              name="currentChallenges"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  freeSolo
                  options={["Challenge 1", "Challenge 2", "Challenge 3"]} // Replace with actual challenges
                  onChange={(_, value) => field.onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder="Select or write challenges"
                      // error={!!errors.currentChallenges}
                    />
                  )}
                />
              )}
            />
          </Grid> */}

          {/* Funding Stage */}
          {/* <Grid item xs={12}>
            <InputLabel>Funding Stage *</InputLabel>
            <FormControl fullWidth size="small">
              <Controller
                name="fundingStage"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    displayEmpty
                    renderValue={(selected) =>
                      selected ? selected : <Typography color="#ABB5BE">Select</Typography>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {fundingStages.map((stage) => (
                      <MenuItem key={stage} value={stage}>
                        {stage}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid> */}

          {/* Markets */}
          {/* <Grid item xs={12}>
            <InputLabel>Markets *</InputLabel>
            <Controller
              control={control}
              name="markets"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple
                  key={companyLocation}
                  options={sortedCountries}
                  value={value || []}
                  onChange={(event, newValue) => onChange(newValue)}
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" placeholder="You can select one or more options" />
                  )}
                />
              )}
            />
          </Grid> */}

          {/* Hiring */}
          {/* <Grid item xs={12}>
            <InputLabel>Hiring Status *</InputLabel>
            <Controller
              name="hiringStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  onChange={(e) => field.onChange(e.target.value)} // Update the value in the form state
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              )}
            />
          </Grid> */}

          {/* Company Size */}
          {/* <Grid item xs={12}>
            <InputLabel>Company Size *</InputLabel>
            <Controller
              control={control}
              name="companySize"
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <Select
                    value={value || ''}
                    onChange={onChange}
                    size="small"
                    fullWidth
                    displayEmpty
                    renderValue={(selected) =>
                      selected ? selected : <Typography color="#ABB5BE">Select</Typography>
                    }
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {companySizes.map((size) => (
                      <MenuItem key={size} value={size}>
                        {size}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid> */}

          {/* Business Model */}
          {/* <Grid item xs={12}>
            <InputLabel>Business Model *</InputLabel>
            <Controller
              control={control}
              name="businessModel"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple
                  options={businessModels}
                  value={value || []}
                  onChange={(event, newValue) => onChange(newValue)}
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" placeholder="You can select one or more options" />
                  )}
                />
              )}
            />
          </Grid> */}

          {/* Customers */}
          {/* <Grid item xs={12}>
            <InputLabel>Customers *</InputLabel>
            <Controller
              control={control}
              name="customers"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple
                  options={customerList}
                  value={value || []}
                  onChange={(event, newValue) => onChange(newValue)}
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" placeholder="Select" />
                  )}
                />
              )}
            />
          </Grid> */}
        </Grid>
      </DialogContent>
      {/* <Box pl={2} pb={1}>
        <Typography variant="body1" fontStyle="italic">
          Note: If you do not see your company name, you are the first person from your company to join the platform so please enter your company name now.
        </Typography> 
      </Box> */}
      {/* Action */}
      {getDialogActions()}
    </>
  );
};

export default Company;
