import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import {
  ToDoIcon,
  PlusCircleIcon,
  DisabledVideoCall
} from '../../../../utils/Icons';
import TagBox from '../../components/TagBox';
import ObjectiveModal from './ObjectiveModal';
import { getStatusColor } from '../../components/common';
import { useAppSelector } from '../../../../redux/hooks';
import { getUserDetails } from '../../../../utils/orgName';
import useLearningObjs from '../../../../queries/useLearningObjs';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../../queries/queryKeys';

interface IProps {
  objId: string;
  borderColor: string;
  title: string;
  description: string;
  key: string;
  status: string;
  total: number;
  current: number;
  noteCount: number;
  videoCount: number;
  notes: any;
  mentors: any;
}

const ObjectiveCard: React.FC<IProps> = ({
  objId,
  borderColor,
  title,
  description,
  status,
  total,
  current,
  noteCount,
  videoCount,
  notes,
  mentors
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentObjId, setCurrentObjId] = useState(objId);
  const [currentObjData, setCurrentObjData] = useState({
    title,
    description,
    status,
    notes,
    mentors
  });
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const { orgId } = getUserDetails();
  const grpId = selectedGroupId?.id;
  const { data: objectives } = useLearningObjs({ orgId, grpId });
  const learningObjectives = objectives?.data?.data || [];
  const queryClient = useQueryClient();

  useEffect(() => {
    const latestObjectiveData = learningObjectives.find((obj: any) => obj.id === objId);
    if (latestObjectiveData) {
      setCurrentObjData({
        title: latestObjectiveData.objective || '',
        description: latestObjectiveData.description || '',
        status: latestObjectiveData.status || 'to-do',
        notes: latestObjectiveData.notes || [],
        mentors: latestObjectiveData.mentors || []
      });
    }
  }, [learningObjectives, objId]);

  const handleOpenModal = () => {
    if (!title) {
      setCurrentObjData({
        title: '',
        description: '',
        status: 'to-do',
        notes: [],
        mentors: []
      });
    }
    setModalOpen(true);
  };

  const handleCloseModal = () => setModalOpen(false);
  const handleModalSubmit = async (data: any) => {
    if (data && data.action === 'navigate') {
      const targetObjectiveId = data.objectiveId;
      const targetObjective = learningObjectives.find((obj: any) => obj.id === targetObjectiveId);
      if (targetObjective) {
        setCurrentObjId(targetObjectiveId);
        setCurrentObjData({
          title: targetObjective.objective,
          description: targetObjective.description,
          status: targetObjective.status,
          notes: targetObjective.notes || [],
          mentors: targetObjective.mentors || []
        });
        setModalOpen(true);
      }
    }
    return Promise.resolve();
  };


  const handleSave = async (updatedData: any) => {
    if (!title) {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.GET_LEARNING_OBJS, grpId]
      });
      return;
    }

    setCurrentObjData((prevData) => ({
      ...prevData,
      description: updatedData.description,
      status: updatedData.status,
      notes: updatedData.notes,
      mentors: updatedData.mentors
    }));
  };

  /** Get Availability Date */
  function getAvailabilityDate(date?: string) {
    if (!date) return null; // Handle empty cases
    return (
      <Typography fontSize="10px" fontStyle="italic" color="red" whiteSpace="nowrap">
        {date}
      </Typography>
    );
  }

  /** Add Objective Button */
  const renderAddObjectiveButton = () => (
    <Box display="flex" flex={1} justifyContent="center" alignItems="center" height="100%">
      <IconButton size="small" onClick={handleOpenModal}>
        <PlusCircleIcon size={30} />
      </IconButton>
    </Box>
  );

  return (
    <>
      <Box key={objId} display="flex" className="taskCard" flexDirection="column" justifyContent="space-between">
        <Box display={'flex'} flexDirection={'column'} height="100%" flex={1}>
          <Box borderRadius="8px 8px 0 0" borderTop={`10px solid ${getStatusColor(status)}`} />
          <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={'8px'}>
              <Typography noWrap color="secondary" variant="h6" flex={1}>
                {`Objective ${current} out of ${total}`}
              </Typography>
              {/* Objective end date */}
              {/* {getAvailabilityDate('20th Jun 24')} */}
            </Box>
            {/* Objective Title */}
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'} gap={'8px'}>
              <Typography
                noWrap
                variant="h5"
                fontWeight={title ? 700 : 400}
                flexShrink={1}
                className="inline-fill"
                fontStyle={title ? '' : 'italic'}
                color={title ? 'secondary' : 'secondary.light'}
                // color={title ? getStatusColor(status) : 'secondary.light'}
              >
                {title ? title : 'Add your Objective'}
              </Typography>
              {/* Objective end date */}
              {/* {getAvailabilityDate('1st Jun 24')} */}
            </Box>
          </Box>

          {title ? (
            <>
              <TagBox
                mb={2}
                mt={1}
                mx={'auto'}
                fontSize="14px"
                color={getStatusColor(status)}
                text={status === 'to-do' ? 'To Do' : status === 'inprogress' ? 'In Progress' : 'Completed'}
              />

              <Box gap={2} display="flex" justifyContent="center" alignItems="center">
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="subtitle2" color="secondary">
                    {noteCount}
                  </Typography>
                  <ToDoIcon />
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="subtitle2" color="secondary">
                    {videoCount}
                  </Typography>
                  <DisabledVideoCall />
                </Box>
              </Box>

              {/* Description section */}
              <Typography px={2} pt={2} className="lineClamp-3">
                {description}
              </Typography>
            </>
          ) : (
            renderAddObjectiveButton()
          )}
        </Box>

        {/* Action Button */}
        {title && (
          <Box alignItems="flex-end" justifyContent="center" display="flex" p={2}>
            <Button
              variant="contained"
              className="w-100 fs-14"
              onClick={handleOpenModal}
              // onClick={handleNavigate}
              aria-label={`View ${status === 'completed' ? 'Details' : 'Proceed '}`}
              sx={{
                backgroundColor:
                  status === "completed" ? "#11895E" :
                    status === "inprogress" ? "#E18C2D" :
                      "info.main",
              }}
            >
              PROCEED
            </Button>
          </Box>
        )}
      </Box>

      {/* Objective Modal */}
      <ObjectiveModal
        open={isModalOpen}
        title={currentObjData.title}
        onClose={handleCloseModal}
        onSubmit={handleModalSubmit}
        description={currentObjData.description}
        status={currentObjData.status}
        objId={currentObjId}
        notes={currentObjData.notes}
        mentorsList={currentObjData.mentors}
        allObjectives={learningObjectives}
        onSave={handleSave}
        isNewObjective={!currentObjData.title}
      />
    </>
  );
};

export default ObjectiveCard;
