import React from 'react';
import RoleTypeTag from './RoleTypeTag';
import MySkeleton from '../../../components/Skeleton';
import { Box, Tooltip, Typography } from '@mui/material';
import ChatAvatarComponent from '../tabs/chat/ChatAvatarComponent';

type CardType = {
  headshot?: string;
  displayName?: string;
  UserType?: string;
  bio?: string | JSX.Element;
};

type UserinfoCardProps = {
  className?: string;
  UserinfoCardDetails: CardType;
  loading?: boolean;
  actionButton?: React.ReactNode;
};

const UserinfoCard: React.FC<UserinfoCardProps> = ({ UserinfoCardDetails, loading, actionButton, className }) => {
  const { headshot, displayName, UserType, bio } = UserinfoCardDetails;

  return (
    <Box display="flex" alignItems="center" width="100%" gap="8px" className={className}>
      {/* Left - Image */}
      <Box flexShrink={0}>
        <ChatAvatarComponent
          image={headshot}
          type="noStatus"
          firstLetter={displayName ? displayName.charAt(0) : ''}
          width="48px"
          height="48px"
          loading={loading}
        />
      </Box>

      {/* Middle - Expanding Content */}
      <Box flex="1" minWidth={0}>
        <Box display="flex" gap="8px" alignItems="center">
          <Typography variant="h5" noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {loading ? <MySkeleton variant='text' height={16} width={100} /> : displayName}
          </Typography>
          {UserType && <RoleTypeTag category={UserType} />}
        </Box>
        <Box>
          <Tooltip title={bio} arrow>
            <Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {loading ? <MySkeleton variant='text' height={16} width={130} /> : bio}
            </Typography>
          </Tooltip>
        </Box>
      </Box>

      {/* Right - Dynamic Action Button */}
      {actionButton && <Box flexShrink={0}>{actionButton}</Box>}
    </Box>
  );
};

export default UserinfoCard;
