/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-duplicate-imports */
import { FC, useState } from 'react';
import {
  Box,
  Table,
  Switch,
  Divider,
  Popover,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  Typography,
  TableContainer
} from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API } from '../../../../../api';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppDispatch } from '../../../../../redux/hooks';
import { articleActions } from '../../../../../redux/slices/article/getAllArticleSlice';
import { DeleteIcon, EditIcon, PreviewIcon } from '../../../../../utils/Icons';
import { HelpSettingsLoader } from '../../../../../Loader/ContentSettingsLoader';

interface Props {
  allData: any;
  loading: any;
  error: any;
  handleClickOnArticleName: any;
  setSelectedActiveId: any;
  handleClickPreiew: any;
  setAnchorElPopover: any;
  anchorElPopover: any;
  handleOpendelete: any;
  handleClickEdit: any;
  setAllData: any;
}
const ContentList: FC<Props> = ({
  allData,
  loading,
  error,
  handleClickOnArticleName,
  setSelectedActiveId,
  handleClickPreiew,
  setAnchorElPopover,
  anchorElPopover,
  handleOpendelete,
  handleClickEdit,
  setAllData
}: Props) => {
  const dispatch = useAppDispatch();
  console.log(allData);
  const params = useParams();
  const grpId = params.id || '';
  const orgId = params.orgId || '';
  const openPopover = Boolean(anchorElPopover);
  const idPopover = openPopover ? 'simple-popover' : undefined;
  const [articleAccessFor, setArticleAccessFor] = useState<boolean>(false);
  const [userLocation, setUserLocation] = useState<any>();

  const handleClosePopover = () => {
    setAnchorElPopover(null);
    setUserLocation('');
  };

  const handleOpenPopover = (
    event: React.MouseEvent<HTMLElement>,
    articleId: string,
    accessFor: boolean,
    location: any
  ) => {
    setAnchorElPopover(event.currentTarget);
    setSelectedActiveId(articleId);
    setArticleAccessFor(accessFor);
    setUserLocation(location);
  };

  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    articleId: string,
    location: string,
    accessArticle: boolean
  ) => {
    let coverImg = '';
    let file = '';
    let type = '';
    const updatedArticleList = allData.map((ele: any) => {
      if (ele.articleId === articleId) {
        coverImg = ele.coverImageUrl;
        file = ele.fileUrl;
        type = ele.type;
        return {
          ...ele,
          allowedGroup: checked
        };
      }
      return ele;
    });
    setAllData(updatedArticleList);

    const formData = new FormData();
    formData.append('toggleChange', 'true');
    formData.append('allowedGroup', checked.toString());
    if (accessArticle) {
      // isPublished will have the same value of allowedGroup in case of own article
      formData.append('isPublished', checked.toString());
    } else {
      formData.append('isPublished', 'true');
    }
    formData.append('coverImage', coverImg);
    if (file) {
      formData.append('type', type);
      formData.append('file', file);
    }
    try {
      const response = await API.editArticle({
        articleId: articleId,
        data: formData,
        orgId: orgId,
        groupId: grpId,
        helpSettings: true,
        location
      });

      if (response.status === 200) {
        dispatch(articleActions.updateArticle(response.data.updatedArticle));
        toast.success('Article published successfully');
      } else {
        toast.error('Article failed to publish');
      }
    } catch (error) {
      toast.error('Something went wrong, please try again');
    }
  };

  if (loading) {
    return <HelpSettingsLoader />;
  }

  if (error) {
    return <Box>Error occurred while fetching matches.</Box>;
  }

  return (
    <>
      <Divider />
      <TableContainer className="b-none">
        <Table className="w-100p">
          <TableHead>
            <TableRow>
              <TableCell>CONTENT</TableCell>
              <TableCell>TOPICS</TableCell>
              <TableCell>CONTENT TYPE</TableCell>
              <TableCell>READ TIME</TableCell>
              <TableCell>ACTION</TableCell>
            </TableRow>
          </TableHead>

          {allData &&
            allData.length > 0 &&
            allData.map((ele: any) => (
              <TableRow key={ele?.articleId}>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Switch
                      checked={ele.allowedGroup}
                      // disabled={typeof ele?.accessArticle === 'boolean' ?  !ele?.accessArticle : true}
                      onChange={(event) =>
                        handleSwitchChange(event, !ele.allowedGroup, ele.articleId, ele.location, ele.accessArticle)
                      }
                    />
                    <img alt="contentimg" className="contentimg" src={ele.coverImageUrl} />
                    <Box className="lineClamp-2">
                      <Typography
                        variant="subtitle1"
                        className="cursor-pointer color-info"
                        onClick={() => handleClickOnArticleName(ele.articleId, ele.location)}
                      >
                        {ele.articleName}
                        {/* {ele.articleName.split(' ').length > 3
                        ? ele.articleName.split(' ').slice(0, 3).join(' ')
                        : ele.articleName} */}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" color="primary">
                    {/* Changing here copy 'Helpful Tip' to 'Getting Started' */}
                    {ele?.topic === 'Helpful Tip' ? 'Getting Started' : ele?.topic}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" color="primary">
                    {ele.type}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" color="primary">
                    {ele.readTime}
                  </Typography>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={(event) =>
                      handleOpenPopover(
                        event,
                        _.get(ele, 'articleId'),
                        typeof ele?.accessArticle === 'boolean' ? ele?.accessArticle : false,
                        _.get(ele, 'location')
                      )
                    }
                  >
                    <MoreVertIcon className="cursor-pointer" color="primary" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          <Popover
            id={idPopover}
            open={openPopover}
            anchorEl={anchorElPopover}
            onClose={handleClosePopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Box className="PopoverListStyle" onClick={() => handleClickPreiew(userLocation)}>
              <PreviewIcon />
              <Typography color="primary">Preview</Typography>
            </Box>
            {articleAccessFor ? (
              <>
                <Box className="PopoverListStyle" onClick={() => handleClickEdit(userLocation)}>
                  <EditIcon />
                  <Typography color="primary">Edit</Typography>
                </Box>
                <Box className="PopoverListStyle" onClick={handleOpendelete}>
                  <DeleteIcon />
                  <Typography color="primary">Delete</Typography>
                </Box>
              </>
            ) : null}
          </Popover>
        </Table>

        {/* No Content section */}
        {allData.length === 0 && (
          <Box textAlign="center" p={2}>
            <Typography>No Content</Typography>
          </Box>
        )}
      </TableContainer>
    </>
  );
};

export default ContentList;
