import React, { ReactNode } from 'react';
import { Close } from '@mui/icons-material';
import { Box, Divider, IconButton, DialogTitle } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

interface CommonModalHeaderProps {
  handleCloseModal?: () => void;
  title?: string | ReactNode;
  onClickBack?: () => void;
  backBtn?: boolean;
  nextPrev?: {
    onPrevClick?: () => void;
    isPrevDisabled?: boolean;
    onNextClick?: () => void;
    isNextDisabled?: boolean;
  };
}

const CommonModalHeader: React.FC<CommonModalHeaderProps> = ({
  title,
  backBtn,
  nextPrev,
  onClickBack,
  handleCloseModal,
}) => {
  const iconButtonStyles = (disabled: boolean) => ({
    color: '#ABB5BE',
    border: "1px solid #ABB5BE",
    height: "23px", width: "23px",
    opacity: disabled ? .25 : 1,
  });

  const arrowSize = { height: "15px", width: "19px" }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        {/* Back Button */}
        <Box width={'40px'}>
          {backBtn && (
            <IconButton onClick={onClickBack} aria-label="back">
              <ArrowBackIosNewOutlinedIcon />
            </IconButton>
          )}
        </Box>

        {/* Title and Navigation Buttons */}
        <Box display="flex" alignItems="center" flexGrow={1} justifyContent="center" width={'calc(100% - 80px)'}>
          {nextPrev?.onPrevClick && (
            <IconButton
              onClick={nextPrev.onPrevClick}
              disabled={nextPrev.isPrevDisabled}
              sx={iconButtonStyles(nextPrev.isPrevDisabled ?? false)}
              aria-label="previous"
              className='ml-10'
            >
              <ArrowBackIosNewOutlinedIcon sx={arrowSize} />
            </IconButton>
          )}

          <DialogTitle className='flex-1' noWrap={nextPrev ? true : false}>
            {title}
          </DialogTitle>

          {nextPrev?.onNextClick && (
            <IconButton
              onClick={nextPrev.onNextClick}
              disabled={nextPrev.isNextDisabled}
              sx={iconButtonStyles(nextPrev.isNextDisabled ?? false)}
              aria-label="next"
              className='mr-10'
            >
              <ArrowForwardIosOutlinedIcon sx={arrowSize} />
            </IconButton>
          )}
        </Box>

        {/* Close Button */}
        <Box width={'40px'}>
          {handleCloseModal && (
            <IconButton onClick={handleCloseModal} aria-label="close">
              <Close />
            </IconButton>
          )}
        </Box>
      </Box>

      {title && <Divider />}
    </>
  );
};

export default CommonModalHeader;
