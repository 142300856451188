import { useRef } from "react";
import _ from "lodash";
import { Box, Typography } from "@mui/material";
import ChatAvatarComponent from "../../../../chat/ChatAvatarComponent";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

interface LogoSettingProps {
    locked?: boolean;
    groupData: any;
    imageUploaded: boolean;
    tempImage: any;
    imageValue: any;
    handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const LogoSetting: React.FC<LogoSettingProps> = ({
    locked = false,
    groupData,
    tempImage,
    imageUploaded,
    imageValue,
    handleImageChange
}) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const lockOpacity = locked ? 'disabled-box-opacity-25' : '';

    return (
        <Box position="relative" height="100%">
            <Box
                display="flex"
                flexDirection="column"
                className={lockOpacity}
                height="calc(100% - 66px)"
                justifyContent="space-between"
            >
                <Box>
                    <Box display="flex" alignItems="center" columnGap={2}>
                        <Box
                            className="profileImageBox cursor-pointer"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <Box className={!imageUploaded ? '' : 'ProfileCameraBgOverlay'}>
                                {tempImage ? (
                                    <CameraAltOutlinedIcon
                                        className={`IfNoIcon color-${imageValue && _.head(imageValue) ? 'secondary' : 'white'}`}
                                    />
                                ) : (
                                    <Box position="relative">
                                        <ChatAvatarComponent
                                            width="80px"
                                            height="80px"
                                            image={_.get(groupData, 'data.logo', '')}
                                            type="noStatus"
                                            fontSize="18x"
                                        />
                                        {groupData.data?.logo ? <Box top="0px" className="ProfileCameraBgOverlay" /> : null}
                                        <CameraAltOutlinedIcon
                                            className={`IfNoIcon color-${groupData.data?.logo ? 'white' : 'secondary'}`}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {typeof tempImage !== 'string' && imageUploaded && (
                                <img
                                    className="profileImage"
                                    src={tempImage ? URL.createObjectURL(tempImage) : ''}
                                    alt="Image preview"
                                />
                            )}
                            <label htmlFor="selectedImage" className="d-none">
                                Upload Image
                            </label>
                            <input
                                type="file"
                                name="logo"
                                id="selectedImage"
                                ref={fileInputRef}
                                className="d-none"
                                onChange={handleImageChange}
                                accept=".png,.jpeg,.jpg,.svg,.gif"
                                title="Choose an image file to upload"
                            />
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={.5}>
                            <Typography variant="h6">Upload Logo</Typography>
                            <Typography fontSize="12px" fontWeight="600" color='secondary'>
                                JPG or PNG (max. 800x800 px)
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default LogoSetting;
