import { Grid, TextField, InputLabel, Typography, Autocomplete, FormHelperText } from '@mui/material';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import { lockIcon } from '../../../../../components/common';
import { useAppSelector } from '../../../../../../../redux/hooks';
import MyTooltip from '../../../../../components/MyTooltip';
import { CommonStyle } from '../../../../../../../utils/muiStyle';

interface MembersProps {
  control: any;
  updateMemberValue?: any;
  setBasicUpdateFlag?: any;
  place: string;
  // getError: (fieldName: string) => string | undefined;
  getError?: any;
  checkError?: (fieldName: string) => boolean;
  errors?: any;
  locked?: boolean;
  model?: any;
}

export const Members = ({
  control,
  setBasicUpdateFlag,
  updateMemberValue,
  place,
  checkError,
  getError,
  locked = false,
  model = ''
}: MembersProps) => {
  const styles = CommonStyle({});
  const adminOptions = [
    'Student',
    'Alumni',
    'Faculty',
    'Staff',
    'Entrepreneur',
    'Advisor',
    'Employee',
    'Program Friend'
  ];
  const menteeOptions = ['mentee', 'client', 'employee', 'student']; //Mentor, Coach, Advisor, Consultant, Manager, Admin
  const mentorOptions = ['mentor', 'coach', 'consultant', 'student', 'faculty', 'manager', 'buddy', 'employee'];
  const orgData = useAppSelector((state) => state.orgDetails.data?.userTypes);
  const isOrg = place === 'OrganizationInformation';
  const lockOpacity = locked ? styles.boxDisabledOpacity : '';
  const isMentorFieldLocked = model === 'masterclass';

  return (
    <Grid container spacing={2}>
      <Grid gap="5px" item xs={12} display="flex" direction="row" alignItems="center">
        <Typography variant="h5">
          <span className={lockOpacity}>Members</span>{' '}
          {locked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
        </Typography>
      </Grid>

      {isOrg && (
        <Grid item xs={12} className={lockOpacity}>
          <InputLabel>What member types will you have in your organization? *</InputLabel>
          <Controller
            control={control}
            name="userTypes"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disabled={locked}
                options={adminOptions}
                getOptionLabel={(option) => (option ? _.startCase(option) : '')}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
                value={isOrg ? value || [] : orgData || []}
                onChange={(_event, newValue) => onChange(newValue)}
                multiple
              />
            )}
          />
          {/* <FormHelperText error>{checkError('mentee') && getError('mentee')?.toString()}</FormHelperText> */}
          <FormHelperText error>
            {checkError && checkError('userTypes') && getError && getError('userTypes')?.toString()}
          </FormHelperText>
        </Grid>
      )}

      <Grid item xs={12} sm={isOrg ? 12 : 6} className={lockOpacity}>
        <InputLabel>
          {isOrg
            ? 'What name do you want to give to the person receiving advice? *'
            : 'Label your program participant '}
          {!isOrg && (
            <MyTooltip title="This is the member who will complete the Masterclass and/or receive advice from program advisors" />
          )}
        </InputLabel>
        <Controller
          control={control}
          name="mentee"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disabled={locked}
              options={menteeOptions.sort((a, b) => (a > b ? 1 : -1))}
              getOptionLabel={(option) => (option ? _.startCase(option) : '')}
              renderInput={(params) => <TextField {...params} placeholder="Select" />}
              value={value || []}
              onChange={(_event, newValue) => {
                onChange(newValue);
                setBasicUpdateFlag(true);
                updateMemberValue();
              }}
            />
          )}
        />
        {/* <FormHelperText>{checkError('mentee') && getError('mentee')?.string()}</FormHelperText> */}
        <FormHelperText error>
          {checkError && checkError('mentee') && getError && getError('mentee')?.toString()}
        </FormHelperText>
      </Grid>

      <Grid item xs={12} sm={isOrg ? 12 : 6} className={lockOpacity}>
        <InputLabel>
          {isOrg ? 'What name do you want to give to the person providing advice? *' : 'Label your program advisor '}
          {!isOrg && (
            <MyTooltip title="This is the member who will advise program participants through Mentorship, Coaching, or Consulting." />
          )}
          {isMentorFieldLocked && lockIcon({ title: 'This is not needed for the Masterclass model' })}
        </InputLabel>
        <Controller
          control={control}
          name="mentor"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disabled={isMentorFieldLocked}
              options={mentorOptions.sort((a, b) => (a > b ? 1 : -1))}
              getOptionLabel={(option) => (option ? _.startCase(option) : '')}
              renderInput={(params) => <TextField {...params} placeholder="Select" />}
              value={value || []}
              onChange={(_event, newValue) => {
                onChange(newValue);
                setBasicUpdateFlag(true);
                updateMemberValue();
              }}
            />
          )}
        />
        <FormHelperText error>
          {checkError && checkError('mentor') && getError && getError('mentor')?.toString()}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};
