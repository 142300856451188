import React, {
  useState,
  useEffect
} from 'react';
import {
  Box,
  Stack,
  Button,
  Divider,
  Skeleton,
  Typography
} from '@mui/material';
import {
  formatMMDDYYYY,
  timeZoneWiseTime,
  timeZoneAbbreviation,
  createZoomMeetingUrl,
} from '../../../components/common';
import { toast } from 'react-toastify';
import { API } from '../../../../../api';
import { CallRowData } from '../../calendar/Calendar';
// import CalendarIcon from '../../calendar/CalendarIcon';
import { BooleanLiteralTypeAnnotation } from '@babel/types';
import { useAppSelector } from '../../../../../redux/hooks';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getUserDetails } from '../../../../../utils/orgName';
import ChatAvatarComponent from '../../chat/ChatAvatarComponent';
import { callActionFlag } from '../../calendar/CalendarFunctions';
import useCallDetails from '../../../../../queries/useCallDetails';
import CancelationCallModal from '../../calendar/CancelationCallModal';
// import SeeBookingDetailsModal from '../../calendar/SeeBookingDetailsModal';
import { CalendarSvgIcon, DisabledVideoCall } from '../../../../../utils/Icons';
// import useResponsiveScreenSwitch from '../../../../../utils/useResponsiveScreenSwitch';

const MeetingCard: React.FC<MeetingCardProps> = ({ meeting, loading, onMeetingCanceled }) => {
  // const { forMobile } = useResponsiveScreenSwitch();
  const [addedToCalendarEvents, setAddedToCalendarEvents] = useState<string[]>([]);
  const { userId } = getUserDetails();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const userCallsData = useCallDetails(page, pageSize);
  const [bookedCallDetails, setBookedCallDetails] = useState<CallRowData | null>(null);
  const [allattendeeDetails, setallAttendeeDetails] = useState([]);
  const [openCallbookingDetailsModal, setopenCallbookingDetailsModal] = useState(false);
  const callRows: any[] = userCallsData?.data || [];
  const userData = useAppSelector((state) => state.userProfile.data);
  const timezone = userData?.personal_details?.timezone || '';
  const [callMeetingId, setMetingId] = useState();
  const [cancelCallMeetingId, setcancelCallMeetingId] = useState();
  const [rejectCallMeetingId, setrejectCallMeetingId] = useState();

  const getCallBookedWithDetails = (meetingId: string) => {
    if (!callRows || callRows.length === 0) return null;

    const filteredCall = callRows.find((call: any) => call.meetingId === meetingId);

    if (!filteredCall) {
      // console.log("No matching call found for meetingId:", meetingId);
      return null;
    }
    return filteredCall.callBookedWith;
  };

  const meetingId = meeting?.meetingId
  const bookedWithPerson = getCallBookedWithDetails(meetingId);
  const callDate = meeting?.callDate ? meeting.callDate.toString() : null;
  const callTime = meeting?.callTime ? meeting.callTime.toString() : null;

  const meetingDetails = [
    {
      icon: <CalendarSvgIcon />,
      text: callDate && callTime ? formatMMDDYYYY(callDate, callTime, timezone) : "N/A"
    },
    {
      icon: <AccessTimeIcon className="size-20 secondary-light" />,
      text: callDate && callTime
        ? `${timeZoneWiseTime(callDate, callTime, timezone)} (${timeZoneAbbreviation(timezone)})`
        : "N/A"
    },
    {
      icon: <DisabledVideoCall />,
      text: meeting?.callTimeInterval ? `${meeting.callTimeInterval} Min` : "N/A"
    }
  ];

  // const handleAddToCalendar = async (callItem: any) => {
  //   try {
  //     // Destructure call details
  //     const {
  //       callDate,
  //       callTime,
  //       callTitle = '',
  //       description = '',
  //       meetingId = '',
  //       callTimeInterval,
  //       callBookedWith,
  //       callBookedBy
  //     } = callItem;

  //     await API.addCallToCalender({
  //       data: { addCallToCalender: true },
  //       meetingId
  //     });

  //     // Validate date and time format
  //     if (!/^\d{4}-\d{2}-\d{2}$/.test(callDate) || !/^\d{2}:\d{2}$/.test(callTime)) {
  //       toast.error('Invalid date or time format');
  //       return;
  //     }

  //     // Construct the start and end datetime strings
  //     const startDateTime = new Date(`${callDate}T${callTime}:00Z`);
  //     if (isNaN(startDateTime.getTime())) {
  //       toast.error('Invalid start date');
  //       return;
  //     }

  //     const endDateTime = new Date(startDateTime.getTime() + callTimeInterval * 60000); // Add interval in minutes
  //     if (isNaN(endDateTime.getTime())) {
  //       toast.error('Invalid end date');
  //       return;
  //     }

  //     // Format dates for Google Calendar (remove hyphens and colons, remove milliseconds)
  //     const start = startDateTime.toISOString().replace(/-|:|\.\d+/g, '');
  //     const end = endDateTime.toISOString().replace(/-|:|\.\d+/g, '');

  //     // Prepare additional details
  //     const bookedWith = callBookedWith?.displayName || callBookedBy?.displayName || 'Meeting Participant';
  //     const meetingDetails = description || 'No additional description';
  //     const location = 'Dosen.io';

  //     // Include meeting link if provided
  //     const meetingLink = `http://localhost:3000/#/video-call/${meetingId}/1/20/1734084000/${callTimeInterval}?org=dev&prompt=true`;
  //     const details = `You can join the meeting by clicking on this link: ${meetingLink}`;

  //     // Construct the Google Calendar URL
  //     const baseUrl = 'https://calendar.google.com/calendar/u/0/r/eventedit';
  //     const calendarUrl = `${baseUrl}?dates=${start}/${end}&details=${encodeURIComponent(
  //       details
  //     )}&location=${encodeURIComponent(location)}&text=${encodeURIComponent(callTitle)}`;

  //     // Open the link in a new tab
  //     window.open(calendarUrl, '_blank', 'noopener,noreferrer');

  //     setAddedToCalendarEvents((prev) => {
  //       const updatedMeetings = [...prev, meetingId];
  //       localStorage.setItem('addedToCalendarEvents', JSON.stringify(updatedMeetings));
  //       return updatedMeetings;
  //     });

  //     // Optional: Show a success toast
  //     toast.success('Google Calendar invite created successfully!');
  //   } catch (error) {
  //     console.error('Error adding to calendar:', error);
  //     toast.error('Failed to create calendar invite');
  //   }
  // };

  useEffect(() => {
    const savedMeetings = JSON.parse(localStorage.getItem('addedToCalendarEvents') || '[]');
    setAddedToCalendarEvents(savedMeetings);
  }, []);

  const [openCancelationCall, setCancelationCallOpen] = useState(false);
  const [openRejectionCall, setRejectionCallOpen] = useState(false);
  const handleRejectionCall = (meetingId: any, type: string) => {
    setMetingId(meetingId);
    setRejectionCallOpen(true);
    if (type === 'modal') {
      setopenCallbookingDetailsModal(false);
    }
  };

  const handleCancelationCall = (meetingId: any, type: string) => {
    setMetingId(meetingId);
    setCancelationCallOpen(true);
    if (type === 'modal') {
      setopenCallbookingDetailsModal(false);
    }
  };

  // const handleSeeBookingDetails = (attendeeDetails: any, callId: any) => {
  //   const filteredData: CallRowData[] = callRows?.filter((item: { _id: string }) => item._id === callId);
  //   setBookedCallDetails(filteredData[0]); // Assuming filteredData will have only one matching call
  //   setallAttendeeDetails(attendeeDetails); // Directly set the attendees' details
  //   setopenCallbookingDetailsModal(true);
  // };

  // const closeSeeBookingDetailsModal = () => {
  //   userCallsData.refetch();
  //   setopenCallbookingDetailsModal(false);
  // };

  const isJoinCall = async (meetingId: string) => {
    const { orgId } = getUserDetails();
    try {
      const response = await API.rejoinCallUpdateFlag(
        getUserDetails().userId,
        meetingId,
        orgId,
        getUserDetails().location
      );
    } catch (e) { }
  };

  const closeCancelModal = () => {
    // Refetch updated call data
    userCallsData.refetch();
    setCancelationCallOpen(false);

    // Notify parent component about cancellation
    if (onMeetingCanceled) {
      onMeetingCanceled();
    }
  };
  const isRejoin = (callsData: any) => {
    const data = callsData.participantsUser?.find((user: { mid: string }) => user.mid === getUserDetails().userId);
    return data ? data.isCallJoined : false;
    // true => go to call
    // false => rejoin
  };

  const handleCallCancellation = () => {
    setcancelCallMeetingId(callMeetingId);

    // Notify parent component about cancellation
    if (onMeetingCanceled) {
      onMeetingCanceled();
    }
  };

  const handleGoToCallIconClick = (
    callTimeInterval: number,
    callDate: string,
    callTime: string,
    meetingId: string,
    isRejoin: BooleanLiteralTypeAnnotation,
    callBookedBy: any,
    meetingType: string,
    meetingLink: string
  ) => {
    const baseUrl = window.location.origin;
    // call start time
    const callStartTimestamp = new Date(callDate + 'T' + callTime + 'Z').getTime();
    console.log(callDate, 'dateee');
    const callStartUnixTime = Math.floor(callStartTimestamp / 1000);
    const currentTimestamp = new Date().getTime();
    const currentcallStartUnixTime = Math.floor(currentTimestamp / 1000);
    const currentUnixTime = Math.floor(currentTimestamp / 1000);
    const callEndUnixTime = currentUnixTime + callTimeInterval * 60;

    if (currentUnixTime + 300 >= callStartUnixTime) {
      // if current time is greater than call time
      if (currentUnixTime > callEndUnixTime) {
        toast.error('This meeting has already taken place');
      } else {
        if (!isRejoin) {
          isJoinCall(meetingId);
        }
        if (meetingType === 'Dosen') {
          const url = createZoomMeetingUrl(meetingId, callDate, callTime, callTimeInterval, callBookedBy);
          const newTab = window.open(url, '_blank');
          newTab?.focus();
        } else {
          const newTab = window.open(meetingLink, '_blank');
          newTab?.focus();
        }
      }
    } else {
      toast.warning('You can join the meeting from 5 minutes before the meeting time');
    }
  };

  const isUserInParticipantsArray = (callsData: any) => {
    const userId = getUserDetails().userId;
    return callsData.participantsUser?.some((user: { mid: string }) => user.mid === userId);
  };

  const joinCallButton = (callsData: any, type: string) => {
    const meetingId = callsData?.meetingId;
    const isUserInParticipants = isUserInParticipantsArray(callsData);

    return (
      <Button
        className={'h-35 w-85 fs-14'}
        variant="contained"
        color="primary"
        fullWidth={type !== 'page'}
        key={callsData?._id}
        onClick={() =>
          handleGoToCallIconClick(
            callsData?.callTimeInterval,
            callsData?.callDate,
            callsData?.callTime,
            callsData?.meetingId,
            isRejoin(callsData),
            callsData?.callBookedBy,
            callsData?.meetingType,
            callsData?.meetingLink
          )
        }
      >
        {isRejoin(callsData) ? 'Rejoin' : 'Join'}
      </Button>
    );
  };

  const rejectCancelCallButton = (callsData: any, type: string) => {
    const isUserInParticipants = callsData.participantsUser?.some(
      (participant: { userId: string }) => participant.userId === userId
    );

    if (!isUserInParticipants) {
      return null; // Do not render anything if the member is not in participantsUser
    }

    let flag = 0;
    if (callsData?.callBookedBy === userId) {
      flag = 1;
    }
    const meetingId = callsData?.meetingId;

    return (
      <>
        {flag === 1 && meetingId !== cancelCallMeetingId && (
          <Button
            variant="outlined"
            className={'h-35 w-85 fs-14'}
            color={type === 'page' ? 'error' : 'secondary'}
            fullWidth={type !== 'page'}
            onClick={() => handleCancelationCall(meetingId, type)}
          >
            Cancel
          </Button>
        )}

        {flag !== 1 && meetingId !== rejectCallMeetingId && (
          <Button
            variant="outlined"
            className={'h-35 w-85 fs-14'}
            color={type === 'page' ? 'error' : 'secondary'}
            fullWidth={type !== 'page'}
            onClick={() => handleRejectionCall(meetingId, type)}
          >
            Reject
          </Button>
        )}
      </>
    );
  };

  const hasCallDetails = callDate && callTime;
  const formattedDate = hasCallDetails ? formatMMDDYYYY(callDate, callTime, timezone) : "N/A";
  const formattedTime = hasCallDetails ? timeZoneWiseTime(callDate, callTime, timezone) : "N/A";

  return (
    <>
      <Box
        gap={1.5}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        {/* Headshot Name */}
        <Box
          gap={1}
          width="100%"
          display="flex"
          // textAlign="center"
          alignItems="flex-start"
          justifyContent="center"
        >
          <ChatAvatarComponent
            image={bookedWithPerson?.headshot}
            firstLetter={bookedWithPerson?.displayName?.slice(0, 1)}
            loading={loading}
            width="62px"
            height="57px"
            cursor="pointer"
            type="noStatus"
          />
          <Box minWidth={0}>
            <Typography variant="h5" noWrap>{loading ? <Skeleton width={'100%'} height={28} /> : bookedWithPerson?.displayName || "N/A"}</Typography>
            <Typography mt={.5}>{loading ? <Skeleton width={'100%'} height={24} /> : `${formattedDate}, ${formattedTime}`}</Typography>
            <Typography>{loading ? <Skeleton width={'60%'} height={24} /> : `${meeting?.callTimeInterval ?? "N/A"} Min`}</Typography>

            {/* Button */}
            {loading ? (
              <>
                <Skeleton variant="rounded" className='mt-10' width={85} height={30} />
              </>
            ) : (
              meeting.meetingType !== 'InPerson' && callActionFlag(meeting, getUserDetails()?.userId) === 1 ? (
                <Box display="flex" gap="8px" alignItems="center" mt={1}>
                  {rejectCancelCallButton(meeting, 'page')}
                  {joinCallButton(meeting, 'page')}
                </Box>
              ) : null
            )}
          </Box>
        </Box>

        {/* Date Time Duration */}
        {/* <Stack
          gap={{ xs: 1, md: 1.5 }}
          direction={"row"}
          alignItems="center"
          flexWrap="wrap"
          divider={<Divider orientation="vertical" flexItem />}
        >
          {meetingDetails?.map((item, index) => (
            <Box
              key={index}
              gap={0.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection={{ sx: "row", md: "column" }}
              flexGrow={1} // Makes all boxes take equal available space
              minWidth={0} // Prevents unwanted shrinking issues
            >
              {loading ? (
                <Skeleton variant="rounded" width={20} height={20} />
              ) : (
                item.icon
              )}
              {loading ? (
                <Skeleton width={70} height={20} />
              ) : (
                <Typography>{item.text}</Typography>
              )}
            </Box>
          ))}
        </Stack> */}
      </Box>

      {/* Call Booking Details Modal */}
      {/* {openCallbookingDetailsModal && (
        <SeeBookingDetailsModal
          openCallbookingDetailsModal={openCallbookingDetailsModal}
          closeSeeBookingDetailsModal={closeSeeBookingDetailsModal}
          bookedCallDetails={meeting}
          allattendeeDetails={meeting.participantsUser}
          timezone={timezone}
          ModalDialogActions={null}
          userCallsDataRefetch={userCallsData.refetch}
          isCallRejectedByAuthUser={false}
          setIsCallEditing={() => { }}
          isCallEditing={false}
        />
      )} */}

      {/* Call Cancelation Modal */}
      {openCancelationCall && (
        <CancelationCallModal
          openCancelationCall={openCancelationCall}
          closeCancelModal={closeCancelModal}
          callMeetingId={callMeetingId}
          onCancelSuccess={handleCallCancellation}
        />
      )}
    </>
  );
};

export default MeetingCard;